import axios from "axios";
import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";
import _ from "lodash";

/**
 * GET API call using Axios
 * @param cancelToken - Token to cancel multiple requests
 * @returns - Array of Terminal List of type IManifestPro
 */

export const manifestProDetailsApi = async (manifestNumber: string) => {
  try {
    const response = await axios.get(`${getUrl(ApiType.MANIFEST_PRO_DETAILS)}/${manifestNumber}`, {
      headers: {
        "Content-Type": "application/json",
      }
    });
    return response.data;
  } catch (error: any) {
    let message = ConstantMessage.manifestProDetails;
    if (_.get(error, "response.status") === 500) {
      message = ConstantMessage.serverError;
    }
    throw message;
  }
};
