import * as R from "ramda";
import { Action } from "redux";
import { AppState, DEFAULT_APP_STATE } from "./appState";
import ACTION_TYPE from "./ActionTypes";
import NOTIFICATION_TYPE from "./NotificationType";
import { APP } from "../constants/app";

export type ResetType = "SOFT_RESET" | "HARD_RESET" | "TERMINAL_RESET" | "OSD_FILTER_RESET" | "MY_ASSIGN_FILTER_RESET" | "SHORTAGES_FILTER_RESET";
export type ResetStateDispatch = {
  type: ACTION_TYPE.RESET_APP_STATE;
  resetType: ResetType;
};

/**
 * Redux action to reset the app state.
 * @param resetType - Will determine if a full reset of app state is needed or partial if user is to
 * arrive from the ArrivalAPI.
 */
export const resetState = (resetType: ResetType): ResetStateDispatch => ({
  type: ACTION_TYPE.RESET_APP_STATE,
  resetType: resetType,
});

/// declare properties for SOFT reset
type PartialState = {

  activeTerminal?: AppState["activeTerminal"];
  tabPosition?: AppState["tabPosition"];
  message?: AppState["message"];
  notificationType?: AppState["notificationType"];
  notificationTime?: AppState["notificationTime"];
  notificationChange?: AppState["notificationChange"];
  overageListPageObject?: AppState["overageListPageObject"];
  myAssignmentPageObject?: AppState["myAssignmentPageObject"];
  overageSortObjectData?: AppState["overageSortObjectData"]
  myAssignmentSortObjectData?: AppState["myAssignmentSortObjectData"]
  overagesFilterForm?: AppState["overagesFilterForm"]
  myAssignmentsFilterForm?: AppState["myAssignmentsFilterForm"]
  shortagesFilterForm?: AppState["shortagesFilterForm"]
  shortagesSortObject?: AppState["shortagesSortObject"]
  shortagesPageObject?: AppState["shortagesPageObject"]
  terminalMaster?: AppState["terminalMaster"];
  overagesFilterDateData?: AppState["overagesFilterDateData"];
  filterCommodityTypeData?: AppState["filterCommodityTypeData"];
  packageOptionMasterData?: AppState["packageOptionMasterData"];
  filterExceptionCodeData?: AppState["filterExceptionCodeData"];
  filterValueData?: AppState["filterValueData"];
  filterStatusData?: AppState["filterStatusData"];
  _persist: AppState["_persist"];
};

/// declare state of the properties which will apply after SOFT reset
export const SOFT_RESET: PartialState = {
  message: "",
  notificationTime: "",
  notificationChange: "",
  activeTerminal: { value: "", region: null },
  notificationType: NOTIFICATION_TYPE.SUCCESS,
  terminalMaster: [],
  overagesFilterDateData: [],
  filterCommodityTypeData: [],
  packageOptionMasterData: [],
  filterExceptionCodeData: [],
  filterValueData: [],
  filterStatusData: [],
  overageListPageObject: DEFAULT_APP_STATE.overageListPageObject,
  myAssignmentPageObject: DEFAULT_APP_STATE.myAssignmentPageObject,
  overageSortObjectData: DEFAULT_APP_STATE.overageSortObjectData,
  myAssignmentSortObjectData: DEFAULT_APP_STATE.myAssignmentSortObjectData,
  overagesFilterForm: DEFAULT_APP_STATE.overagesFilterForm,
  myAssignmentsFilterForm: DEFAULT_APP_STATE.myAssignmentsFilterForm,
  shortagesFilterForm: DEFAULT_APP_STATE.shortagesFilterForm,
  shortagesSortObject: DEFAULT_APP_STATE.shortagesSortObject,
  shortagesPageObject: DEFAULT_APP_STATE.shortagesPageObject,
  _persist: undefined,
};

/// declare state of the properties which will apply after SOFT reset
export const TERMINAL_RESET: PartialState = {
  overageSortObjectData: APP.overage.sortModel,
  overageListPageObject: DEFAULT_APP_STATE.overageListPageObject,
  myAssignmentSortObjectData: APP.overage.sortModel,
  myAssignmentPageObject: DEFAULT_APP_STATE.myAssignmentPageObject,
  overagesFilterForm: DEFAULT_APP_STATE.overagesFilterForm,
  myAssignmentsFilterForm: DEFAULT_APP_STATE.myAssignmentsFilterForm,
  shortagesFilterForm: DEFAULT_APP_STATE.shortagesFilterForm,
  shortagesSortObject: DEFAULT_APP_STATE.shortagesSortObject,
  shortagesPageObject: DEFAULT_APP_STATE.shortagesPageObject,

  _persist: undefined,
};

/// declare state of the properties which will apply after SOFT reset
export const OSD_FILTER_RESET: PartialState = {
  overagesFilterForm: DEFAULT_APP_STATE.overagesFilterForm,
  _persist: undefined,
};

/// declare state of the properties which will apply after SOFT reset
export const MY_ASSIGN_FILTER_RESET: PartialState = {
  myAssignmentsFilterForm: DEFAULT_APP_STATE.myAssignmentsFilterForm,
  _persist: undefined,
};

/// declare state of the properties which will apply after SOFT reset
export const SHORTAGES_FILTER_RESET: PartialState = {
  shortagesFilterForm: DEFAULT_APP_STATE.shortagesFilterForm,
  _persist: undefined,
};

export const resetStateReducer = (
  state: AppState,
  action: Action
): AppState => {
  if (action.type !== ACTION_TYPE.RESET_APP_STATE) {
    return state;
  }
  if ((action as ResetStateDispatch).resetType === "SOFT_RESET") {
    return R.mergeRight(state, SOFT_RESET);
  }
  if ((action as ResetStateDispatch).resetType === "HARD_RESET") {
    return R.mergeRight(state, DEFAULT_APP_STATE);
  }
  if ((action as ResetStateDispatch).resetType === "TERMINAL_RESET") {
    return R.mergeRight(state, TERMINAL_RESET);
  }
  if ((action as ResetStateDispatch).resetType === "OSD_FILTER_RESET") {
    return R.mergeRight(state, OSD_FILTER_RESET);
  }
  if ((action as ResetStateDispatch).resetType === "MY_ASSIGN_FILTER_RESET") {
    return R.mergeRight(state, MY_ASSIGN_FILTER_RESET);
  }
  if ((action as ResetStateDispatch).resetType === "SHORTAGES_FILTER_RESET") {
    return R.mergeRight(state, SHORTAGES_FILTER_RESET);
  }
  return state;
};
