import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./popupImage.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { IProImage } from "../../models/master";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

interface DialogImageProps {
  isDialogOpened: boolean;
  handleCloseDialog: () => void;
  imageData: IProImage[];
  excNumber: string;
}

const DialogImage: React.FC<DialogImageProps> = ({
  isDialogOpened,
  handleCloseDialog,
  imageData,
  excNumber,
}) => {
  const fullWidth : boolean = true;
  const [images, setImages] = React.useState<string[]>([
    imageData[0]?.imageFilename!,
  ]);

  const handleClose = () => {
    handleCloseDialog();
  };

  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handlePrevClick = () => {
    // Decrease the current index by 1 (considering a circular carousel)
    const newIndex = (currentIndex - 1 + imageData.length) % imageData.length;
    setCurrentIndex(newIndex);
  };

  const handleNextClick = () => {
    // Increase the current index by 1 (considering a circular carousel)
    const newIndex = (currentIndex + 1) % imageData.length;
    setCurrentIndex(newIndex);
  };

  const onChange = (index: number) => {
    setCurrentIndex(index);
    if (images.length < imageData.length) {
      setImages([...images, imageData[index].imageFilename!]);
    }
  };

  return (
    <div>
      <Dialog
        open={isDialogOpened}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        // TransitionComponent={Transition}
        keepMounted
        maxWidth="lg"
        fullWidth={fullWidth}
        // componentsProps={{ backdrop: { style: { backgroundColor: "#00000057" } } }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="modal-header"
        >
          Exception # <span className="exceptionNo">{excNumber}</span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className="Gallery">
          <div className="pre-btn" onClick={handlePrevClick}>
            <ChevronLeftOutlinedIcon />
          </div>
          <div className="next-btn" onClick={handleNextClick}>
            <ChevronRightOutlinedIcon />
          </div>

          <Carousel
            infiniteLoop
            // autoPlay
            stopOnHover={true}
            showArrows={false}
            showThumbs={false}
            selectedItem={currentIndex} // Set the selected item based on the current index
            onChange={(index) => onChange(index)} // Update the current index when changing the item
          >
            {imageData.map((obj: any, index: number) => (
              <div>
                {/* eslint-disable-next-line  jsx-a11y/img-redundant-alt */}
                <img src={images[index]} alt="AS400 image" />
                <p className="legend">{obj.docType}</p>
              </div>
            ))}
          </Carousel>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default DialogImage;
