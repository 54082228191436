export interface IManifest {
    manifestNumber?: number;
    origin?: string;
    goingTo?: string;
    nowAt?: string;
    destination?: string;
    closeDate?: string;
    closeTime?: string;
    dispatchDate?: string;
    dispatchTime?: string;
    dispatchNumber?: number; //Disp
    arrivalDate?: string;
    arrivalTime?: string;
    status?: string;
    weight?: number;
    bills?: number;
    seal?: string;
    manifestType?: string;
    power?: string;
    trailerNumber?: number;
    totalRevenue?: number; //Rev
    currentRoute?: string; //R/C
    temperatureRange?: string; //T/C
    enteredWeight?: number; //M/Wgt
    enteredBill?: number; //M-Bill
    comments?: string;
    lastUpdatedDate?: string;
    lastUpdatedTime?: string;
    driverName?: string;
    cubicFeet?: number;
    handlingUnits?: number;
    gsStandard?: string;
    mFb?: string
  }

  
export interface IManifestPro {
    proNumber?: number;
    handlingUnits?: number;
    hazmat?: string;
    shipper?: string;
    consignee?: string;
    destination?: string;
    totalPieces?: number;
    weight?: number;
    cubicFeet?: number;
  }

export enum EManifestType {
    "Last Manifest #" = "1",
    "Last confirmed manifest #" = "2",
    "Manifest Short Reported On" = "3"
}