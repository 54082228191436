export const ASInvestigationWizardStep = [
  "Day 1",
  "Day 2",
  "Day 3",
  "Day 4",
  "Day 5",
  "Day 10",
  "Day 15",
  "Day 20",
];

export const StepStatusMap =  {
  1: 0,
  2: 0,
  3: 1,
  4: 2,
  5: 3,
  6: 4,
  7: 5,
  8: 6,
  9: 7,
  10: 7,
  11: 7,
}

export enum EShortageInvestigationStatus {
  exceptionEntry = 1,
  day1InProgress = 2,
  day2InProgress = 3,
  day3InProgress = 4,
  day4InProgress = 5,
  day5InProgress = 6,
  day10InProgress = 7,
  day15InProgress = 8,
  day20InProgress = 9,
  pendingSrApproval = 10,
  complete = 11,
}
 

export const Day1Fields = [
  "showAtDestTerm",
  "dateToDestTerm",
  "notInDestTerm",
  "notInDestTermSteps",
];

export const Day2Fields = [
  "noMovement",
  "dateLastMovement",
  "noMovementSteps",
  "suffixBill",
  "suffixBillSteps",
  "bolCheck",
  "bolCheckSteps",
  "drCheck",
  "drCheckSteps",
];
export const Day3Fields = [
  "pumCheck",
  "pickupManifest",
  "pumSteps",
  "shipperLoad",
  "discrepancies",
  "checkLinehaulManifests",
  "linehaulManifests",
  "interviewWorkers",
  "workerSteps",
  "consigneePhone",
  "consigneeName",
  "consigneeShort",
  "itemNumbers",
  "orderReceived",
  "freightLabeled",
  "shipperPhone",
  "shipperName",
  "shippedDetails",
  "shortShipped",
  "productValue",
  "multShipments",
];
export const Day4Fields = [
  "check7818",
  "details7818",
  "manifested",
  "cameraFindings",
  "callSameDays",
  "sameDayPros",
];
export const Day5Fields = ["contactTerminals", "investigationDetails"];
export const Day10Fields = ["followUp", "followUpDetails"];
export const Day15Fields = ["check7818_2", "details7818_2", "highValue"];
export const Day20Fields = ["finalReCheck"];

export enum EASInvestigationFieldsErrorMessage {
  day1 = "Day 1 is completed. Required field cannot be left blank.",
  day2 = "Day 2 is completed. Required field cannot be left blank.",
  day3 = "Day 3 is completed. Required field cannot be left blank.",
  day4 = "Day 4 is completed. Required field cannot be left blank.",
  day5 = "Day 5 is completed. Required field cannot be left blank.",
  day10 = "Day 10 is completed. Required field cannot be left blank.",
  day15 = "Day 15 is completed. Required field cannot be left blank.",
}
