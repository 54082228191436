import axios from "axios";
import * as _ from "lodash";

import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";
import { TMasterSearchParameter } from "../constants/app";
import { IMaterUser } from "../models/user";

/**
 * GET API call using Axios
 * @param cancelToken - Token to cancel multiple requests
 * @returns - Array of Terminal List of type string[]
 */

export const getFilterMasterUser = async (params: TMasterSearchParameter): Promise<IMaterUser[]> => {
  try {
    const response = await axios.get(getUrl(ApiType.OVERAGE_FILTER_MASTER_USER), {
      headers: {
        "Content-Type": "application/json",
      },
      params
    });
    return _.get(response, "data.data", []);
  } catch (error) {
    let message = ConstantMessage.terminalAPIError;
    if (_.get(error, "response.status") === 500) {
      message = ConstantMessage.serverError;
    }
    throw message;
  }
};
