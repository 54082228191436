import * as R from "ramda";
import { Action } from "redux";
import { AppState } from "./appState";
import ACTION_TYPE from "./ActionTypes";

export type DocTypesAction = {
  type: ACTION_TYPE.DOC_TYPE_STATE;
  docTypeMaster:string[];
};

export const saveDocTypesState = (docTypeMaster: string[]): DocTypesAction => ({
  type: ACTION_TYPE.DOC_TYPE_STATE,
  docTypeMaster: docTypeMaster,
});

export const saveDocTypesStateReducer = (
  state: AppState,
  action: Action
): AppState => {
  if (action.type !== ACTION_TYPE.DOC_TYPE_STATE) {
    return state;
  }
  return R.mergeDeepRight(state, {
    docTypeMaster: (action as DocTypesAction).docTypeMaster,
  });
};
