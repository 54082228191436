import "./Header.css";
import React, { useEffect, useCallback } from "react";
import * as _ from "lodash";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { debounce } from "lodash";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useOktaAuth } from "@okta/okta-react";
import { useLocation } from "react-router-dom";
import HeaderSearch from "../headerSearch/HeaderSearch";
import { validateOktaToken } from "../../API/validateOktaToken";
import { AppState, DEFAULT_APP_STATE } from "../../AppState/appState";
import { useDispatch, useSelector } from "react-redux";
import TerminalPicker from "../terminalPicker/TerminalPicker";
import { isPrivilegedUser, logUserIn } from "../../AppState/logUserIn";
import { APP, AppURLs, ETab } from "../../constants/app";

import {
  IFilterForm,
  TTerminal,
  saveOveragesFilterForm,
} from "../../AppState/overagesFilterFormActions";
import { saveMyAssignmentsFilterForm } from "../../AppState/myAssignmentsFilterFormActions";
import { saveShortagesFilterForm } from "../../AppState/shortagesFilterFormAction";
import { IAuthUser } from "../../models/user";

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { oktaAuth } = useOktaAuth();

  const path = location.pathname.toString();
  const paths = [
    "manifest",
    "exception",
    AppURLs.overageEntry,
    AppURLs.shortageEntry,
    "apb/send",
  ];

  const containsPath = paths.some((keyword) => path.includes(keyword));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [maxlength, setMaxlength] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [isOktaLoggedIn, setIsOktaLoggedIn] = React.useState<boolean>(false);
  const currentTerminalValue: TTerminal = useSelector(
    (state: AppState) => state.activeTerminal
  );

  const currentTabPosition = useSelector(
    (state: AppState) => state.tabPosition
  );

  const open = Boolean(anchorEl);
  let userName = "Saia User";
  const [authUser, setAuthUser] = React.useState<IAuthUser>(
    JSON.parse(localStorage.getItem("authUser")!)
  );

  const filterForm: IFilterForm = useSelector((state: AppState) => {
    if (state.tabPosition === ETab.overages) {
      return state.overagesFilterForm;
    } else if (state.tabPosition === ETab.myAssignments) {
      return state.myAssignmentsFilterForm;
    } else if (state.tabPosition === ETab.shortages) {
      return state.shortagesFilterForm;
    } else return DEFAULT_APP_STATE.myAssignmentsFilterForm;
  });
  const user = JSON.parse(localStorage.getItem("okta-token-storage")!);
  const handleClick = (event: {
    currentTarget: React.SetStateAction<HTMLElement | null>;
  }) => {
    setAnchorEl(event.currentTarget);
  };

  const onFilterClick = () => {};

  const handleClose = () => {
    setAnchorEl(null);
  };

  const validateToken = () => {
    if (!localStorage.getItem("authUser")) {
      validateOktaToken()
        .then(async (user: IAuthUser) => {
          setAuthUser(user);
          localStorage.setItem("authUser", JSON.stringify(user));
          dispatch(logUserIn(true));
          dispatch(isPrivilegedUser(_.get(user, "isPrivilegedUser", false)));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  if (_.get(user, "idToken", "") !== "") {
    userName = _.get(user, "idToken.claims.name", "Saia User");
    if (!isOktaLoggedIn) setIsOktaLoggedIn(true);
  }

  useEffect(() => {
    if (isOktaLoggedIn) validateToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOktaLoggedIn]);

  const logout = () => {
    localStorage.clear();
    oktaAuth.signOut();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(
      (newValue, currentTabPosition) =>
        onSearchChange(newValue, currentTabPosition),
      1000
    ),
    []
  );

  const updateValue = (newValue: string) => {
    setSearchValue(newValue);
    debouncedSave(newValue, currentTabPosition);
  };

  const onSearchChange = (value: string, currentTab: number = 1) => {
    const filterFormParam: IFilterForm = JSON.parse(JSON.stringify(filterForm));
    filterFormParam["search"] = value;
    if (currentTab === ETab.overages) {
      dispatch(saveOveragesFilterForm(filterFormParam));
    } else if (currentTab === ETab.myAssignments) {
      dispatch(saveMyAssignmentsFilterForm(filterFormParam));
    } else if (currentTab === ETab.shortages) {
      dispatch(saveShortagesFilterForm(filterFormParam));
    }
  };

  const OnHeaderLoad = () => {
    ///// showing pagewise submited search value on header
    setMaxlength(Number(APP.searchMaxLength));
    setSearchValue(filterForm.search);
  };

  useEffect(() => {
    OnHeaderLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTabPosition]);

  useEffect(() => {
    setSearchValue(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setSearchValue("");
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTerminalValue]);

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Grid container>
            <Grid item md={8} xs={8}>
              <Grid display="flex" justifyContent="flex-start">
                <img src="/Saia_ltl.png" alt="Saia_ltl" />

                {/* Search Filter  */}
                {APP.showHeaderSearchPage.includes(currentTabPosition) &&
                  !containsPath && (
                    <HeaderSearch
                      maxlength={maxlength}
                      onFilterClick={() => onFilterClick()}
                      onSearchChange={(searchText: string) => {
                        if (searchText.trimStart() !== " ") {
                          updateValue(searchText.trimStart());
                        }
                      }}
                      value={searchValue}
                    />
                  )}
              </Grid>
            </Grid>
            {/* Group Filters  */}
            {currentTabPosition !== 10 && (
              <>
                <Grid item md={2} xs={2} className="border-right">
                  {APP.showTerminalPickerPage.includes(currentTabPosition) &&
                    !containsPath && (
                      <div>
                        {authUser && authUser.username ? (
                          <TerminalPicker />
                        ) : (
                          <>..</>
                        )}
                      </div>
                    )}
                </Grid>

                {/* User Name  */}

                <Grid item md={2} xs={2}>
                  <Box
                    display="flex"
                    className="account-user"
                    justifyContent="flex-end"
                  >
                    <Button
                      id="demo-positioned-button"
                      aria-controls={open ? "demo-positioned-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      {userName}
                    </Button>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      className="submenu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          width: "135px",
                          transform: "translateX(11%) translateY(0%)",
                          left: "0",
                          fontSize: "0.5rem",
                        },
                      }}
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      transformOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                      <MenuItem onClick={logout}>Logout</MenuItem>
                    </Menu>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};
export default Header;
