import * as R from "ramda";
import { Action } from "redux";
import { AppState } from "./appState";
import ACTION_TYPE from "./ActionTypes";
import { TTerminal } from "./overagesFilterFormActions";

/**
 * Redux action to dispatch Footer Tab Position
 * @param tabPosition - Type: number - save footer tab position on footer tab chagnes
 */

// define action 
export type TerminalSelectionAction = {
  type: ACTION_TYPE.TERMINAL_SELECTION;
  activeTerminal: TTerminal;
};


// set action
export const TerminalSelection = (
  activeTerminal: TTerminal
): TerminalSelectionAction => ({
  type: ACTION_TYPE.TERMINAL_SELECTION,
  activeTerminal: activeTerminal,
});

// set reducer - update redux store
export const TerminalSelectionReducer = (
  state: AppState,
  action: Action
): AppState => {
  if (action.type !== ACTION_TYPE.TERMINAL_SELECTION) {
    return state;
  }
  return R.mergeDeepRight(state, {
    activeTerminal: (action as TerminalSelectionAction).activeTerminal,
  });
};
