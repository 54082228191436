import * as R from 'ramda';
import { Action } from 'redux';
import { AppState } from './appState';
import ACTION_TYPE from './ActionTypes';

export type LogUserInAction = {
  type: ACTION_TYPE.LOG_USER_IN;
  isLoggedIn: boolean;
};

/**
 * Redux action to dispatch driver details
 * @param isLoggedIn - Type: boolean - if the user is logged in or not
 */
export const logUserIn = (isLoggedIn: boolean): LogUserInAction => ({
  type: ACTION_TYPE.LOG_USER_IN,
  isLoggedIn: isLoggedIn,
});

export const logUserInReducer = (state: AppState, action: Action): AppState => {
  if (action.type !== ACTION_TYPE.LOG_USER_IN) {
    return state;
  }
  return R.mergeDeepRight(state, {
    isLoggedIn: (action as LogUserInAction).isLoggedIn,
  });
};



// user Role Redux configure


export type IsPrivilegedUserAction = {
  type: ACTION_TYPE.IS_PRIVILEGED_USER;
  isPrivilegedUser: boolean;
};

/**
 * 
 * @param isPrivilegedUser - Type: Role - User's Role
 */
export const isPrivilegedUser = (isPrivilegedUser: boolean): IsPrivilegedUserAction => ({
  type: ACTION_TYPE.IS_PRIVILEGED_USER,
  isPrivilegedUser: isPrivilegedUser,
});

export const isPrivilegedUserReducer = (state: AppState, action: Action): AppState => {
  if (action.type !== ACTION_TYPE.IS_PRIVILEGED_USER) {
    return state;
  }
  return R.mergeDeepRight(state, {
    isPrivilegedUser: (action as IsPrivilegedUserAction).isPrivilegedUser,
  });
};

