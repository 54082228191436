import { IAllShortageInvestigation } from "../models/shortage";
import { AlphanumericRegex, CreateDynamicSchema, FieldConfig } from "./formik";

//  AS Investigation form Start 
const asInvestigationFields: FieldConfig[] = [
    {
        name: "showAtDestTerm",
        type: "string",
        required: false,
        message: "Answer is required"
    },

    {
        name: "dateToDestTerm",
        type: "string",
        nullable: true,
        when: {
            key: "showAtDestTerm",
            value: "N",
            required: false,
            message: "Date forced to destination terminal is required",
        }
    },
    {
        name: "notInDestTerm",
        type: "string",
        required: false,
        message: "Answer is required"
    },

    {
        name: "notInDestTermSteps",
        type: "string",
        matches: AlphanumericRegex,
        matchesMessage: "Only alphanumerical characters allowed",
        nullable: true,
        when: {
            key: "notInDestTerm",
            value: "Y",
            required: false,
            message: "Date forced to destination terminal is required",
        }
    },

    {
        name: "noMovement",
        type: "string",
        required: false,
        message: "Answer is required"
    },
    {
        name: "dateLastMovement",
        type: "string",
        nullable: true,
        when: {
            key: "noMovement",
            value: "Y",
            required: false,
            message: "Date forced to destination terminal is required",
        }
    },

    {
        name: "noMovementSteps",
        type: "string",
        nullable: true,
        matches: AlphanumericRegex,
        matchesMessage: "Only alphanumerical characters allowed",
        when: {
            key: "noMovement",
            value: "Y",
            required: false,
            message: "Answer is required",
        }
    },

    {
        name: "suffixBill",
        type: "string",
        required: false,
        message: "Answer is required"
    },

    {
        name: "suffixBillSteps",
        type: "string",
        nullable: true,
        matches: AlphanumericRegex,
        matchesMessage: "Only alphanumerical characters allowed",
        when: {
            key: "suffixBill",
            value: "Y",
            required: false,
            message: "Date forced to destination terminal is required",
        }
    },

    {
        name: "bolCheck",
        type: "string",
        required: false,
        message: "Answer is required"
    },

    {
        name: "bolCheckSteps",
        type: "string",
        nullable: true,
        matches: AlphanumericRegex,
        matchesMessage: "Only alphanumerical characters allowed",
        when: {
            key: "bolCheck",
            value: "Y",
            required: false,
            message: "Answer is required",
        }
    },

    {
        name: "drCheck",
        type: "string",
        required: false,
        message: "Answer is required",

    },
    {
        name: "drCheckSteps",
        type: "string",
        nullable: true,
        matches: AlphanumericRegex,
        matchesMessage: "Only alphanumerical characters allowed",
        when: {
            key: "drCheck",
            value: "Y",
            required: false,
            message: "Answer is required",
        }
    },


    {
        name: "pumCheck",
        type: "string",
        required: false,
        message: "Answer is required"
    },
    {
        name: "pickupManifest",
        type: "number",
        nullable: true,
        when: {
            key: "pumCheck",
            value: "Y",
            required: false,
            message: "Date forced to destination terminal is required",
        }
    },
    {
        name: "pumSteps",
        type: "string",
        nullable: true,
        matches: AlphanumericRegex,
        matchesMessage: "Only alphanumerical characters allowed",
        when: {
            key: "pumCheck",
            value: "Y",
            required: false,
            message: "Answer is required",
        }
    },

    {
        name: "shipperLoad",
        type: "string",
        required: false,
        message: "Answer is required"
    },
    {
        name: "discrepancies",
        type: "string",
        nullable: true,
        when: {
            key: "shipperLoad",
            value: "Y",
            required: false,
            message: "Answer is required",
        }
    },

    {
        name: "checkLinehaulManifests",
        type: "string",
        required: false,
        message: "Answer is required"
    },
    {
        name: "linehaulManifests",
        type: "string",
        nullable: true,
        when: {
            key: "checkLinehaulManifests",
            value: "Y",
            required: false,
            message: "Answer is required",
        }
    },

    {
        name: "interviewWorkers",
        type: "string",
        required: false,
        message: "Answer is required"
    },
    {
        name: "workerSteps",
        type: "string",
        nullable: true,
        when: {
            key: "interviewWorkers",
            value: "Y",
            required: false,
            message: "Answer is required",
        }
    },

    {
        name: "consigneePhone",
        type: "string",
        required: false,
        message: "Consignee Phone Number is required"
    },
    {
        name: "consigneeName",
        type: "string",
        required: false,
        message: "consignee Name is required"
    },

    {
        name: "shipperPhone",
        type: "string",
        required: false,
        message: "Shipper Phone Number is required"
    },
    {
        name: "shipperName",
        type: "string",
        required: false,
        message: "Shipper Name is required"
    },

    {
        name: "shippedDetails",
        type: "string",
        required: false,
        message: "Answer is required"
    },
    {
        name: "shortShipped",
        type: "string",
        required: false,
        message: "Answer is required"
    },
    {
        name: "productValue",
        type: "string",
        required: false,
        message: "Answer is required"
    },
    {
        name: "multShipments",
        type: "string",
        required: false,
        message: "Answer is required"
    },

    {
        name: "check7818",
        type: "string",
        required: false,
        message: "Answer is required"
    },
    {
        name: "details7818",
        type: "string",
        nullable: true,
        when: {
            key: "check7818",
            value: "Y",
            required: false,
            message: "Answer is required",
        }
    },

    {
        name: "manifested",
        type: "string",
        required: false,
        message: "Answer is required"
    },
    {
        name: "cameraFindings",
        type: "string",
        nullable: true,
        when: {
            key: "manifested",
            value: "Y",
            required: false,
            message: "Answer is required",
        }
    },

    {
        name: "callSameDays",
        type: "string",
        required: false,
        message: "Answer is required"
    },
    {
        name: "sameDayPros",
        type: "string",
        nullable: true,
        when: {
            key: "callSameDays",
            value: "Y",
            required: false,
            message: "Answer is required",
        }
    },
    {
        name: "contactTerminals",
        type: "string",
        required: false,
        message: "Answer is required"
    },
    {
        name: "investigationDetails",
        type: "array",
        when: {
            key: "contactTerminals",
            value: "Y",
            required: false,
            message: "Investigation Details Required",
        },
        schema: [
            {
                name: "terminal",
                type: "string",
                nullable: true,
                required: true,
                message: "TID is required",
            },
            {
                name: "manifest",
                type: "string",
                nullable: true,
                required: true,
                message: "Manifest # is required",
            },
            {
                name: "discrepancies",
                type: "string",
                nullable: true,
                required: true,
                message: "List any discrepancies or abnormalities is required",
            },
        ]
    },


    {
        name: "check7818_2",
        type: "string",
        required: false,
        message: "Answer is required"
    },

    {
        name: "details7818_2",
        type: "number",
        nullable: true,
        when: {
            key: "check7818_2",
            value: "Y",
            required: false,
            message: "Date forced to destination terminal is required",
        }
    },




    {
        name: "followUp",
        type: "string",
        required: false,
        message: "Answer is required"
    },

    {
        name: "followUpDetails",
        type: "number",
        nullable: true,
        when: {
            key: "followUp",
            value: "Y",
            required: false,
            message: "Date forced to destination terminal is required",
        }
    },



    {
        name: "finalReCheck",
        type: "string",
        required: false,
        message: "Answer is required"
    },

    {
        name: "highValue",
        type: "string",
        required: false,
        message: "Answer is required"
    },


    {
        name: "finalReCheck",
        type: "string",
        required: false,
        message: "Answer is required"
    },

];

// Exporting the dynamically created schema
export const ASInvestigationSchema = CreateDynamicSchema(asInvestigationFields);

export const ASInvestigationInitValue: IAllShortageInvestigation = {
    // Day 1
    showAtDestTerm: undefined,
    dateToDestTerm: undefined,
    notInDestTerm: undefined,
    notInDestTermSteps: undefined,
    // Day 2
    noMovement: undefined,
    dateLastMovement: undefined,
    noMovementSteps: undefined,
    suffixBill: undefined,
    suffixBillSteps: undefined,
    bolCheck: undefined,
    bolCheckSteps: undefined,
    drCheck: undefined,
    drCheckSteps: undefined,
    // Day 3
    pumCheck: undefined,
    pickupManifest: undefined,
    pumSteps: undefined,
    shipperLoad: undefined,
    discrepancies: undefined,
    checkLinehaulManifests: undefined,
    linehaulManifests: undefined,
    interviewWorkers: undefined,
    workerSteps: undefined,
    consigneePhone: undefined,
    consigneeName: undefined,
    consigneeShort: undefined,
    itemNumbers: undefined,
    orderReceived: undefined,
    freightLabeled: undefined,
    shipperPhone: undefined,
    shipperName: undefined,
    shippedDetails: undefined,
    shortShipped: undefined,
    productValue: undefined,
    multShipments: undefined,
    // Day 4
    check7818: undefined,
    details7818: undefined,
    manifested: undefined,
    cameraFindings: undefined,
    callSameDays: undefined,
    sameDayPros: undefined,
    // Day 5
    contactTerminals: undefined,
    investigationDetails: [{ terminal: undefined, manifest: undefined, discrepancies: undefined }],
    // Day 10
    followUp: undefined,
    followUpDetails: undefined,
    // Day 15
    check7818_2: undefined,
    details7818_2: undefined,
    highValue: undefined,
    // Day 20
    finalReCheck: undefined

};
// AS Investigation form End
