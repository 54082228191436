import { Link, useNavigate, useParams } from 'react-router-dom';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Button } from '@mui/material';
import "./sendapb.css";
import { ImageList, ImageListItem, Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { PopoutNotification } from '../../AppState/popoutNotification';
import NOTIFICATION_TYPE from '../../AppState/NotificationType';
import { getProImages } from '../../API/getProImages';
import { APP } from '../../constants/app';
import { sendAPBMailApi } from '../../API/sendAPBMail';
import { useDispatch } from 'react-redux';
import { IProImage } from '../../models/master';
import Loader from '../../components/loader/loader';
import _ from 'lodash';
import SendAPBImageList from '../../components/sendAPBImageList/sendAPBImageList';


const SendAPB = () => {
    // Implement your component logic here

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const [pageLoading, setPageLoading] = useState<boolean>(false);


    // Send APB mail with selected images
    const sendAPBMail = () => {
        setPageLoading(true);
        sendAPBMailApi(id, { attachmentImageUrls: selectedImages })
            .then((data) => {
                setPageLoading(false);
                dispatch(
                    PopoutNotification(
                        NOTIFICATION_TYPE.SUCCESS,
                        "Email sent successfully",
                        Date.now().toString()
                    )
                );
                navigate(`/exception/${id}`);
            })
            .catch((error: any) => {
                console.log(error);
                setPageLoading(false);
                dispatch(
                    PopoutNotification(
                        NOTIFICATION_TYPE.ERROR,
                        error,
                        Date.now().toString()
                    )
                );
            });
    };

    // Handle image selection
    const handleImageSelect = (imageUrl: string) => {
        setSelectedImages((prevSelectedImages) =>
            prevSelectedImages.includes(imageUrl)
                ? prevSelectedImages.filter((url) => url !== imageUrl)
                : [...prevSelectedImages, imageUrl]
        );
    };




    return (
        <div className="bg-layout">
            {/* header */}
            <div className="header-section">
                <div className="back-section">
                    <div className="back">
                        <Link to={`/exception/${id}`} >
                            <ArrowLeftIcon />
                        </Link>
                    </div>
                    <div className="page-hader-content">
                        <div className="page-header">Send APB</div>
                    </div>
                </div>
                <div className="edit-section">
                    <Button
                        variant="contained"
                        className="assign"
                        onClick={sendAPBMail}
                    >
                        Send
                    </Button>
                </div>
            </div>

            {/* body */}
            {id && 
            <>
            <SendAPBImageList
            osdNumber={parseInt(id)}
            setSelectedImages={setSelectedImages}
            selectedImages={selectedImages}
            handleImageSelect={handleImageSelect}
            />
            </>}
            <Loader pageLoader={pageLoading} />

        </div>
    );
};

export default SendAPB;