import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from '@mui/material/Grid';

type Props = {
    open: boolean;
    onClose?: (flag?: string) => void;
}

const CustomDialog: React.FC<Props> = (props: Props): JSX.Element => {
    return (
        <Dialog open={props.open} onClick={() => {props?.onClose && props?.onClose()}}
            aria-labelledby="customized-dialog-title"
            keepMounted
            maxWidth="sm"
            
            className="assignment">
            <DialogTitle sx={{ m: 0, p: 2 }}
                id="customized-dialog-title"
                className="modal-header">Confirm</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => {props?.onClose && props?.onClose()}}
                sx={{
                    position: "absolute",
                    right: 15,
                    top: 15,
                    color: (theme: any) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers className="assignment-area">
                <Grid item md={3} xs={9} className="hide-no-option font justify-content-popup">
                    {/* Add content for your dialog here */}
                    All data entered would be lost. <br />
                    Are you sure you want to exit this page ?
                    
                </Grid>
                <Grid item md={3} xs={9} className="hide-no-option justify-content-popup">
                    <Button variant="contained" className="assign" onClick={(e) => {props.onClose && props.onClose("yes" )}}>
                        Yes
                    </Button>
                    <Button variant="contained" onClick={() => {props?.onClose && props?.onClose()}}  className="disable">
                        No
                    </Button>
                </Grid>

            </DialogContent>

        </Dialog>
    );
}

export default CustomDialog;
