import * as R from "ramda";
import { Action } from "redux";
import { AppState } from "./appState";
import ACTION_TYPE from "./ActionTypes";
import { TTerminal } from "./overagesFilterFormActions";

export type TerminalMasterAction = {
  type: ACTION_TYPE.TERMINAL_MASTER_STATE;
  terminalMaster:TTerminal[];
};

export const saveTerminalMasterState = (terminalMaster: TTerminal[]): TerminalMasterAction => ({
  type: ACTION_TYPE.TERMINAL_MASTER_STATE,
  terminalMaster: terminalMaster,
});

export const saveTerminalMasterStateReducer = (
  state: AppState,
  action: Action
): AppState => {
  if (action.type !== ACTION_TYPE.TERMINAL_MASTER_STATE) {
    return state;
  }
  return R.mergeDeepRight(state, {
    terminalMaster: (action as TerminalMasterAction).terminalMaster,
  });
};
