import * as R from 'ramda';
import { Action } from 'redux';
import { AppState } from './appState';
import ACTION_TYPE from './ActionTypes';

export type IsFormValueChangedAction = {
  type: ACTION_TYPE.OVERAGE_ENTRY_FORM_CHANGED;
  isFormValueChanged: boolean;
};

/**
 * Redux action to dispatch driver details
 * @param isFormValueChanged - Type: boolean - if the entry form value changes
 */
export const isFormValueChanged = (isFormValueChanged: boolean): IsFormValueChangedAction => ({
  type: ACTION_TYPE.OVERAGE_ENTRY_FORM_CHANGED,
  isFormValueChanged: isFormValueChanged,
});

export const isFormValueChangedReducer = (state: AppState, action: Action): AppState => {
  if (action.type !== ACTION_TYPE.OVERAGE_ENTRY_FORM_CHANGED) {
    return state;
  }
  return R.mergeDeepRight(state, {
    isFormValueChanged: (action as IsFormValueChangedAction).isFormValueChanged,
  });
};
