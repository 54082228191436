import { useDispatch } from "react-redux";
import "./unauthorized.css"
import { FooterTabPosition } from "../../AppState/footerTabPosition";
import Button from "@mui/material/Button";
import { useOktaAuth } from "@okta/okta-react";
import WebAssetOffIcon from '@mui/icons-material/WebAssetOff';

function Unauthorized() {
  const dispatch = useDispatch();
  dispatch(FooterTabPosition(10));
  const { oktaAuth } = useOktaAuth();

  const oktaLogin = () => {
    localStorage.clear();
    oktaAuth.signOut();
  }

  return (
    <div className="bg-layout access">

      <div className="container">
        <WebAssetOffIcon />
        <h1> 401 - Unauthorized </h1>
        <p>You are not authorized to access this application.</p>

        <br /> 
        <Button
          variant="contained"
          onClick={() => oktaLogin()}
          className="assign"
        >Logout from Okta</Button>
      </div>


    </div>
  );
}

export default Unauthorized;
