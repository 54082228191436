import React, { useState } from 'react';
import './deliveredShortException.css';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, SnackbarOrigin, TextField, Typography } from '@mui/material';
import { State } from '../../../components/shared/Notification';

interface DeliveredShortExceptionProps {
    // Define your component props here
}

const DeliveredShortException: React.FC<DeliveredShortExceptionProps> = (props) => {
    // Add your component logic here
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const [selectedType, setSelectedType] = useState(1);

    const handleTypeChange = (event: any) => {
        setSelectedType(event.target.value);
    };


    const [trailerChecked, setTrailerChecked] = useState(false);
    const [bayChecked, setBayChecked] = useState(false);
    const [doorChecked, setDoorChecked] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');


    const [itemChecked, setItemChecked] = useState(false);
    const [cartonChecked, setCartonChecked] = useState(false);
    const [modelChecked, setModelChecked] = useState(false);
    const [serialChecked, setSerialChecked] = useState(false);

    const selectStyle = {
        fontSize: "13px",
    };

    const [state, setState] = React.useState<State>({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;

    const handleClick = (newState: SnackbarOrigin) => () => {
        setState({ ...newState, open: true });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const [trailerSelected, setTrailerSelected] = useState(false);
    const [baySelected, setBaySelected] = useState(false);

    const handleRadioChange = (event: any) => {
        if (event.target.value === 'T') {
            setTrailerSelected(true);
            setBaySelected(false);
        } else if (event.target.value === 'B') {
            setBaySelected(true);
            setTrailerSelected(false);
        }
    };

    const handleRadioChange2 = (event: any) => {
        setSelectedValue(event.target.value);
    };

    const IconComponent = () => (
        <div className="dropdown-icon-group">
            <ArrowDropUpIcon className="icon-style" />
            <ArrowDropDownIcon className="icon-style" />
        </div>
    );

    return (
        <div className="bg-layout overage-entry">

            {/* header */}
            <div className="header-section description">
                <div className="back-section">
                    <div className="back">
                        <ArrowLeftIcon />
                    </div>
                    <div className="page-hader-content">
                        <div className="page-header">Delivered Shortage Investigation</div>
                        <div className="page-subtitle">Last update: 09/29/2022 08:34PM</div>
                    </div>
                </div>
                <span className="badge">#48674895</span>
            </div>

            {/* body */}
            <div className="content-area">
                <Accordion className="accordion" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary className="header" expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header" >
                        <Typography className="queue-check">
                            1
                        </Typography>
                        <Typography className="accordion-header">Delivered Short Exception</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-container">

                        <Grid container spacing={2} className="filter-margin">
                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id="type">
                                        Type <sup>*</sup>
                                    </InputLabel>
                                    <Select
                                        variant="outlined"
                                        id="date"
                                        placeholder="Select a Date"
                                        className="select"
                                        disabled
                                        fullWidth
                                        value={selectedType}
                                        onChange={handleTypeChange}
                                        style={selectStyle}
                                        IconComponent={() => <div className="dropdown-icon-group"><ArrowDropUpIcon className="icon-style" /> <ArrowDropDownIcon className="icon-style" /> </div>}
                                    >
                                        <MenuItem style={selectStyle} value={1}>DS</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id="reportedType">
                                        Reported Terminal <sup>*</sup>
                                    </InputLabel>
                                    <TextField
                                        id="checkDigit"
                                        variant="outlined"
                                        disabled
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconComponent />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} className="filter-margin">

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id="checkDigit">
                                        PRO# <sup>*</sup>
                                    </InputLabel>
                                    <TextField
                                        id="checkDigit"
                                        variant="outlined"
                                        disabled
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id="originTerminal">
                                        Origin Terminal <sup>&nbsp;</sup>
                                    </InputLabel>
                                    <TextField
                                        id="originTerminal"
                                        disabled
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id=" ">
                                        Destination Terminal <sup>&nbsp;</sup>
                                    </InputLabel>
                                    <TextField
                                        id=" "
                                        disabled
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                        </Grid>

                        <Grid container spacing={2} className="filter-margin">

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id="checkDigit">
                                        Last Trailer Terminal <sup>&nbsp;</sup>
                                    </InputLabel>
                                    <TextField
                                        id="checkDigit"
                                        variant="outlined"
                                        disabled
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id="originTerminal">
                                        Last Manifest Terminal <sup>&nbsp;</sup>
                                    </InputLabel>
                                    <TextField
                                        id="originTerminal"
                                        disabled
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                        </Grid>

                        <Grid container spacing={2} className="filter-margin">

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id=" ">
                                        Shipper Account # <sup>&nbsp;</sup>
                                    </InputLabel>
                                    <TextField
                                        id=" "
                                        disabled
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id=" ">
                                        Shipper Name <sup>&nbsp;</sup>
                                    </InputLabel>
                                    <TextField
                                        id=" "
                                        disabled
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id=" ">
                                        Shipper Address <sup>&nbsp;</sup>
                                    </InputLabel>
                                    <TextField
                                        id=" "
                                        disabled
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                        </Grid>

                        <Grid container spacing={2} className="filter-margin">

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id=" ">
                                        Shipper  City <sup>&nbsp;</sup>
                                    </InputLabel>
                                    <TextField
                                        id=" "
                                        disabled
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id=" ">
                                        Shipper State <sup>&nbsp;</sup>
                                    </InputLabel>
                                    <TextField
                                        id=" "
                                        disabled
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id=" ">
                                        Shipper Zip <sup>&nbsp;</sup>
                                    </InputLabel>
                                    <TextField
                                        id=" "
                                        disabled
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                        </Grid>

                        <Grid container spacing={2} className="filter-margin">

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id=" ">
                                        Consignee Account # <sup>&nbsp;</sup>
                                    </InputLabel>
                                    <TextField
                                        id=" "
                                        disabled
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id=" ">
                                        Consignee Name <sup>&nbsp;</sup>
                                    </InputLabel>
                                    <TextField
                                        id=" "
                                        disabled
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id=" ">
                                        Consignee Address <sup>&nbsp;</sup>
                                    </InputLabel>
                                    <TextField
                                        id=" "
                                        disabled
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                        </Grid>

                        <Grid container spacing={2} className="filter-margin">

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id=" ">
                                        Consignee City <sup>&nbsp;</sup>
                                    </InputLabel>
                                    <TextField
                                        id=" "
                                        disabled
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id=" ">
                                        Consignee State <sup>&nbsp;</sup>
                                    </InputLabel>
                                    <TextField
                                        id=" "
                                        disabled
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id=" ">
                                        Consignee Zip <sup>&nbsp;</sup>
                                    </InputLabel>
                                    <TextField
                                        id=" "
                                        disabled
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                        </Grid>

                        <Grid container spacing={2} className="filter-margin">

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id=" ">
                                        Commodity <sup>*</sup>
                                    </InputLabel>
                                    <Autocomplete
                                        className="autocomplete"
                                        disablePortal
                                        options={["Option 1", "Option 2", "Option 3", "Option 4", "Option 5", "Option 6", "Option 7", "Option 8", "Option 9", "Option 10"]}
                                        forcePopupIcon={false}
                                        disableCloseOnSelect
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" className='reported-terminal' />
                                        )}
                                        renderTags={(value) => (
                                            <div
                                                className="auto-complete-selected-container"
                                                title={value.join(", ")}
                                            >
                                                {value.join(", ")}
                                            </div>
                                        )} // Add renderTags property to customize rendering of selected values
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id="type">
                                        Package Type <sup>*</sup>
                                    </InputLabel>
                                    <Select
                                        variant="outlined"
                                        id="date"
                                        placeholder="Select a Date"
                                        className="select"

                                        fullWidth
                                        value={selectedType}
                                        onChange={handleTypeChange}

                                        style={selectStyle}
                                        IconComponent={() => <div className="dropdown-icon-group"><ArrowDropUpIcon className="icon-style" /> <ArrowDropDownIcon className="icon-style" /> </div>}
                                    >
                                        <MenuItem style={selectStyle} value={1}> </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id="checkDigit">
                                        Total Pieces <sup>*</sup>
                                    </InputLabel>
                                    <TextField
                                        id="checkDigit"
                                        placeholder="Enter Total Pieces"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id="checkDigit">
                                        Weight <sup>*</sup>
                                    </InputLabel>
                                    <TextField
                                        id="checkDigit"
                                        placeholder="Enter Weight"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                        </Grid>

                        <Grid container spacing={2} className="filter-margin">

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id="checkDigit">
                                        Does The Shipment Have (Item-Carton-mdl-Serial) <sup>*</sup>
                                    </InputLabel>
                                    <TextField
                                        id="checkDigit"
                                        placeholder="Enter Shipment Details"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={9} xs={12}>
                                <FormControl className="radio-group value-range">
                                    <FormLabel id="valueRange">Value Range <sup>*</sup></FormLabel >


                                    <Grid container spacing={2}>

                                        <Grid item md={12} xs={12} className="float-div">

                                            <RadioGroup
                                                row
                                                aria-labelledby="valueRange"
                                                name="valueRange"
                                                value={selectedValue}
                                                onChange={handleRadioChange2}
                                            >
                                                <FormControlLabel value="yes" control={<Radio />} label="$0 - $250.00" disabled  />
                                                <FormControlLabel value="greaterThan" control={<Radio />} label="$250.00 - $1,000.00" disabled  />
                                                <FormControlLabel value="greaterThen" control={<Radio />} label="Greater than $1,000.00" disabled  />
                                            </RadioGroup>
                                            {selectedValue === 'greaterThen' && (
                                                <FormGroup className="form-row l-5">
                                                    <TextField
                                                        className="justify-content disabled"
                                                        id=" "
                                                        value="$"
                                                        variant="outlined"
                                                        disabled
                                                    />
                                                    <TextField
                                                        className="justify-content"
                                                        id=" "
                                                        placeholder="Enter Amount"
                                                        variant="outlined"
                                                        disabled 
                                                    />
                                                </FormGroup>
                                            )}

                                        </Grid>

                                    </Grid>

                                </FormControl>
                            </Grid>

                        </Grid>


                        <Grid container spacing={2} className="filter-margin">

                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id=" ">
                                       Number of Shrink Wrapped Pallets <sup>&nbsp;</sup>
                                    </InputLabel>
                                    <TextField
                                        id=" "
                                        placeholder=" "
                                        variant="outlined"
                                        disabled
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={9} xs={12}>
                                <FormControl className="radio-group value-range">
                                    <FormLabel id="valShrinkwrapueRange">Was the Original Shrinkwrap Intact? <sup>&nbsp;</sup></FormLabel >


                                    <Grid container spacing={2}>

                                        <Grid item md={12} xs={12} className="float-div">

                                            <RadioGroup
                                                row
                                                aria-labelledby="Shrinkwrap"
                                                name="Shrinkwrap" 
                                            >
                                                <FormControlLabel value="yes" control={<Radio />} label="Yes" disabled  />
                                                <FormControlLabel value="no" control={<Radio />} label="No" disabled  /> 
                                            </RadioGroup>
                                             
                                        </Grid>

                                    </Grid>

                                </FormControl>
                            </Grid> 

                        </Grid>
  
                        <Grid container spacing={2} className="filter-margin">
  
                            <Grid item md={9} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel style={selectStyle} shrink={true} id="comments">
                                        Additional Details <sup>*</sup>
                                    </InputLabel>
                                    <TextField
                                        id="comments"
                                        placeholder="Enter Additional Details"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>

                        </Grid>
 
                        <div className="btn-group-submit">

                            <Button
                                type="button"
                                variant="contained"
                                className="clear-btn ml"
                            >
                                Clear
                            </Button>
                            <Button
                                variant="contained"
                                className="assign ml mr-0"
                            >
                                Edit
                            </Button>
                            <Button
                                variant="contained"
                                className="entry ml"
                            >
                                Submit
                            </Button>


                        </div>

                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
};

export default DeliveredShortException;