import * as R from "ramda";
import { Action } from "redux";
import { AppState } from "./appState";
import ACTION_TYPE from "./ActionTypes";

/**
 * Redux action to dispatch Footer Tab Position
 * @param tabPosition - Type: number - save footer tab position on footer tab chagnes
 */

// define action 
export type FooterTabPositionAction = {
  type: ACTION_TYPE.TAB_POSITION;
  tabPosition: number;
};


// set action
export const FooterTabPosition = (
  tabPosition: number
): FooterTabPositionAction => ({
  type: ACTION_TYPE.TAB_POSITION,
  tabPosition: tabPosition,
});

// set reducer - update redux store
export const FooterTabPositionReducer = (
  state: AppState,
  action: Action
): AppState => {
  if (action.type !== ACTION_TYPE.TAB_POSITION) {
    return state;
  }
  return R.mergeDeepRight(state, {
    tabPosition: (action as FooterTabPositionAction).tabPosition,
  });
};
