import { createTheme, ThemeProvider } from "@mui/material/styles";
import AccordionDetails from "@mui/material/AccordionDetails";
import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Grid,
  FormControl,
  TextField,
  Checkbox,
  Snackbar,
  Button,
  Tooltip,
  ClickAwayListener,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import "./shortageInvestigation.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { State } from "../../../components/shared/Notification";
import {
  ASInvestigationInitValue,
  ASInvestigationSchema,
} from "../../../schema/asInvestigation";
import { FieldArray, FormikErrors, FormikProvider, useFormik } from "formik";
import dayjs from "dayjs";
import {
  ASInvestigationWizardStep,
  Day1Fields,
  Day10Fields,
  Day15Fields,
  Day20Fields,
  Day2Fields,
  Day3Fields,
  Day4Fields,
  Day5Fields,
  EShortageInvestigationStatus,
  StepStatusMap,
  EASInvestigationFieldsErrorMessage,
} from "../../../constants/shortage";
import { ShortageInvestigationCreateUpdateAPI } from "../../../API/shortageInvestigationCreateUpdate";
import { useDispatch, useSelector } from "react-redux";
import { PopoutNotification } from "../../../AppState/popoutNotification";
import NOTIFICATION_TYPE from "../../../AppState/NotificationType";
import { isFormValueChanged } from "../../../AppState/isFormValueChanged";
import Loader from "../../../components/loader/loader";
import {
  CombineTowJsonObject,
  HandleKeyDown,
  HandleNumberMaxLength,
  HtmlToImage,
} from "../../../helpers/utils.helper";
import {
  EASInvestigationFields,
  IAllShortageInvestigation,
  IDay5Investigation,
} from "../../../models/shortage";
import _ from "lodash";
import ASInvestigationTemplate from "../../../templates/asInvestigationTemplate";
import { FileUploadAPI } from "../../../API/fileUpload";
import { IUploadFile } from "../../../models/master";
import { EUploadKey } from "../../../constants/app";
import AppState from "./../../../AppState";
import SendAPBImageList from "../../../components/sendAPBImageList/sendAPBImageList";

type Props = {
  exceptionType: string;
  osdNumber: number;
  proNumber?: number | string | null;
  isEditMode?: boolean;
  setInvestigationStatus: (status: number) => void;
  status?: number;
  isFreightLocated?: boolean;
  expanded?: string | boolean;
  investigationDetails?: any;
};
interface TooltipState {
  [key: string]: boolean;
}

const ShortageInvestigation: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState<number>(
    StepStatusMap[EShortageInvestigationStatus.exceptionEntry]
  );
  // dispatch(userRole("Supervisor, OS&d"));
  const [pageLoading, setPageLoading] = React.useState<boolean>(false);
  const [formFieldsDisabled, setFormFieldsDisabled] = React.useState<boolean>(
    props?.isEditMode || false
  );
  const [resetTimestamps, setResetTimestamps] = useState<string>(
    Date.now().toString()
  );
  const [open, setOpen] = useState<boolean>(false);
  const [isFreightLocated, setIsFreightLocated] = useState<boolean>(false);
  const [status, setStatus] = React.useState<number>(1);
  const [lastStatusNumber, setLastStatusNumber] = React.useState<number>(1);
  const [invalidFields, setInvalidFields] = React.useState<string[]>([]);
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [isEditMode, setIsEditMode] = React.useState<boolean>(
    props?.isEditMode || false
  );
  const isPrivilegedUser: boolean = useSelector(
    (state: AppState) => state.isPrivilegedUser
  );
  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  const [initValues, setInitValues] = React.useState<IAllShortageInvestigation>(
    _.mergeWith(
      {},
      ASInvestigationInitValue,
      props?.investigationDetails,
      CombineTowJsonObject
    )
  );

  // Handle image selection
  const handleImageSelect = (imageUrl: string) => {
    setSelectedImages((prevSelectedImages) =>
      prevSelectedImages.includes(imageUrl)
        ? prevSelectedImages.filter((url) => url !== imageUrl)
        : [...prevSelectedImages, imageUrl]
    );
  };

  const showSendAPB = () => {
    const { osdNumber } = props;
    const { highValue, details7818_2, check7818_2 } = values;
    const { highValue: initialHighValue, details7818_2: initialDetails7818_2, check7818_2: initialCheck7818_2 } = initValues;
  
    const isStepActive = activeStep === StepStatusMap[8];
    const isFormEnabled = !formFieldsDisabled;
    const hasChangedValue = !initialDetails7818_2 || initialCheck7818_2 !== "Y" || initialHighValue !== "Y";
  
    return (
      osdNumber &&
      isStepActive &&
      isFormEnabled &&
      highValue === "Y" &&
      details7818_2 &&
      check7818_2 === "Y" &&
      hasChangedValue
    );
  };

  const uploadInvestigation = async () => {
    const images: File[] = [];
    setPageLoading(true);
    let ids = ASInvestigationWizardStep;
    if (isFreightLocated) {
      ids = ids.slice(0, lastStatusNumber - 1);
    }
    const imagePromises = ids.map((day: string, index: number) =>
      HtmlToImage(`investigation-${day}`, `${index + 1}.jpeg`).then(
        (img: File) => {
          images.push(img);
        }
      )
    );

    await Promise.all(imagePromises);
    const reqInput: IUploadFile = {
      osdNumber: props.osdNumber,
      docType: "FX",
      uploadKey: EUploadKey.pro,
    };
    FileUploadAPI(images, reqInput)
      .then((res) => {
        setPageLoading(false);
        if (res.errors && res.errors.length > 0) {
          dispatch(
            PopoutNotification(
              NOTIFICATION_TYPE.INFO,
              `The following files failed to upload: ${res.errors.join(", ")}`,
              Date.now().toString()
            )
          );
        } else {
          dispatch(
            PopoutNotification(
              NOTIFICATION_TYPE.SUCCESS,
              res.result || "OS&D Investigation Image uploaded successfully",
              Date.now().toString()
            )
          );
        }
      })
      .catch((error) => {
        setPageLoading(false);
        dispatch(
          PopoutNotification(
            NOTIFICATION_TYPE.ERROR,
            typeof error === "string"
              ? error
              : `OS&D Investigation Image uploading failed`,
            Date.now().toString()
          )
        );
      });
  };

  // If active day investigation is completed the checkboxes is disabled of day
  const isControlDisabled = (fieldName: keyof IAllShortageInvestigation) => {
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;
    let value = "Y";
    if (fieldName === "showAtDestTerm") {
      value = "N";
    }
    if (
      typeof initValues[fieldName] === "string" &&
      initValues[fieldName] === value
    ) {
      return true;
    }
  };

  /**  
    Switch the investigation day step
    Allows the user to navigate to a previous day's investigation from the current day in progress
    However, the user cannot proceed to the next day until the current day's investigation is completed
  */

  const handleStep = (step: number) => () => {
    const iStatus = isFreightLocated ? lastStatusNumber : status;
    if (StepStatusMap[iStatus as keyof typeof StepStatusMap] < step) return;
    setFormFieldsDisabled(true);
    setIsEditMode(true);
    reset();
    setActiveStep(step);
  };

  const theme = createTheme({
    components: {
      MuiStepLabel: {
        styleOverrides: {
          iconContainer: {
            display: "none", // This will hide the icon container
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          line: {
            display: "none", // This will hide the connector line
          },
        },
      },
      MuiStep: {
        styleOverrides: {
          horizontal: {
            width: "12.5%", // This will set the width of each step to 12.5%
            padding: "0", // This will set the padding to 0
          },
        },
      },
      MuiStepButton: {
        styleOverrides: {
          root: {
            margin: "initial", // This will set the margin to its initial value
            padding: "0", // This will set the padding to its initial value
            zIndex: "1", // This will set the z-index to 1
            height: "50px",
            fontWeight: "bold",
            fontStyle: "italic",
            letterSpacing: "1px",
            textTransform: "uppercase",
          },
        },
      },
    },
  });

  // Declare a new state variable for tooltips with a default empty object
  const [openTooltips, setOpenTooltips] = useState<TooltipState>({});

  // Function to handle tooltip close
  const handleTooltipClose = (id: string) => {
    setOpenTooltips((prev) => ({ ...prev, [id]: false }));
  };

  // Function to handle tooltip open
  const handleTooltipOpen = (id: string) => {
    setOpenTooltips((prev) => ({ ...prev, [id]: true }));
  };

  const [state, setState] = React.useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  // Enable fields in edit mode upon clicking the edit button
  const enableEditing = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevents the default form submission behavior
    setFormFieldsDisabled(false); // Enables the form fields for editing
  };

  // Initialize form handling using Formik
  // This setup uses Formik to manage the form state, validation, and submission process.
  const form = useFormik({
    validationSchema: ASInvestigationSchema, // Validation schema for the form fields
    validateOnChange: false, // Disable validation on field change
    initialValues: initValues, // Initial values for the form fields
    onSubmit: () => {
      saveOrSubmit("submit"); // Function to handle form submission
    },
  });

  // Destructure various handlers and values provided by Formik for easy use within the component
  const {
    values, // Current values of the form fields
    handleChange, // Handler for changing form field values
    handleBlur, // Handler for managing field blur events
    validateField, // Function to validate a specific field
    setFieldValue, // Function to set the value of a specific field
    validateForm, // Function to validate the entire form
    resetForm, // Function to reset the form to its initial values
  } = form;

  const isValidDependedField = (
    fieldName: keyof IAllShortageInvestigation,
    dependedField: keyof IAllShortageInvestigation,
    activeStep: number,
    val: string = "Y"
  ) => {
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;
    if (
      values[dependedField] === val &&
      values[fieldName] &&
      values[fieldName]?.toString() === "" &&
      invalidFields.indexOf(fieldName) === -1
    ) {
      setInvalidFields([...invalidFields, fieldName]);
    } else {
      setInvalidFields([
        ...invalidFields.filter((field) => field !== fieldName),
      ]);
    }
  };

  const addRemove = (type: string = "add") => {
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;
    const l = values?.investigationDetails?.length!;

    if (type === "add") {
      setInvalidFields([
        ...invalidFields,
        `manifest${l}`,
        `terminal${l}`,
        `discrepancies${l}`,
      ]);
    } else {
      setInvalidFields([
        ...invalidFields.filter(
          (field) =>
            ![`manifest${l}`, `terminal${l}`, `discrepancies${l}`].includes(
              field
            )
        ),
      ]);
    }
  };

  const isValueExist = (
    fieldName: keyof IAllShortageInvestigation | string,
    index?: number
  ) => {
    if (StepStatusMap[status as keyof typeof StepStatusMap] <= activeStep)
      return;
    let value;
    let initValue;
    if (index !== undefined) {
      value = _.get(values, `investigationDetails[${index}][${fieldName}]`, "");
      initValue = _.get(
        initValues,
        `investigationDetails[${index}][${fieldName}]`,
        ""
      );
    } else {
      if (fieldName in values && fieldName in initValues) {
        value = values[fieldName as keyof IAllShortageInvestigation];
        initValue = initValues[fieldName as keyof IAllShortageInvestigation];
      }
    }
    if (
      typeof value === "string" &&
      (typeof initValue === "string" || typeof initValue === "number") &&
      value === "" &&
      initValue !== "" &&
      invalidFields.indexOf(
        index || index === 0 ? fieldName + index : fieldName
      ) === -1
    ) {
      setInvalidFields([
        ...invalidFields,
        index || index === 0 ? fieldName + index : fieldName,
      ]);
    }
    if (
      invalidFields.indexOf(
        index || index === 0 ? fieldName + index : fieldName
      ) !== -1
    ) {
      setInvalidFields([
        ...invalidFields.filter(
          (field) =>
            field !== (index || index === 0 ? fieldName + index : fieldName)
        ),
      ]);
    }
  };

  const reset = () => {
    resetForm();
    setResetTimestamps(Date.now().toString());
    setInvalidFields([]);
    const source: IAllShortageInvestigation = JSON.parse(
      JSON.stringify(initValues)
    );
    const fields: string[] = [
      ...Day1Fields,
      ...Day2Fields,
      ...Day3Fields,
      ...Day4Fields,
      ...Day10Fields,
      ...Day15Fields,
      ...Day20Fields,
      "contactTerminals",
      "pickupManifest",
    ];
    fields.forEach((f: string) => {
      const fieldValue = source[f as keyof IAllShortageInvestigation];
      setFieldValue(f, fieldValue);
    });
    setFieldValue(`pickupManifest`, _.get(source, `pickupManifest`, undefined));

    _.get(source, "investigationDetails", []).forEach(
      (obj: any, index: number) => {
        setFieldValue(
          `investigationDetails[${index}].terminal`,
          _.get(obj, `terminal`, "")
        );
        setFieldValue(
          `investigationDetails[${index}].manifest`,
          _.get(obj, `manifest`, undefined)
        );
        setFieldValue(
          `investigationDetails[${index}].discrepancies`,
          _.get(obj, `discrepancies`, "")
        );
      }
    );
  };

  const validateDay5Fields = () => {
    values.investigationDetails &&
      values.investigationDetails.forEach((_: any, index: number) => {
        validateField(`investigationDetails[${index}].terminal`);
        validateField(`investigationDetails[${index}].manifest`);
        validateField(`investigationDetails[${index}].discrepancies`);
      });
  };

  const updateInitValue = (
    key: string,
    value: string | number | null | undefined
  ) => {
    setInitValues((prevState) => {
      // Update the top-level property
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const completeAndImage = async () => {
    try {
      setPageLoading(true);
      const submitResponse = await ShortageInvestigationCreateUpdateAPI(
        {},
        props.osdNumber?.toString(),
        "submit"
      );
      const { statusNumber, lastStatusNumber } = submitResponse;
      setStatus(statusNumber);
      setLastStatusNumber(lastStatusNumber);
      props.setInvestigationStatus(statusNumber);
      setPageLoading(false);
      const message =
        "OS&D Investigation notes have been completed successfully & Upload Investigation Image In progress";
      uploadInvestigation();

      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.SUCCESS,
          message,
          Date.now().toString()
        )
      );
    } catch (error) {
      setPageLoading(false);
      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.ERROR,
          typeof error === "string"
            ? error
            : `OS&D Investigation notes submit failed`,
          Date.now().toString()
        )
      );
    }
  };

  const saveOrSubmit = (action: string, freightLocated: string = "N") => {
    if (invalidFields?.length > 0) return;
    setPageLoading(true);
    const reqParams = JSON.parse(JSON.stringify(values));
    reqParams["freightLocated"] = isFreightLocated ? "Y" : freightLocated;
    const deleteFields: string[] = [];
    const fieldsMap: { [key: string]: string[] } = {
      [EShortageInvestigationStatus.exceptionEntry]: [
        ...Day2Fields,
        ...Day3Fields,
        ...Day4Fields,
        ...Day5Fields,
        ...Day10Fields,
        ...Day15Fields,
        ...Day20Fields,
      ],
      [EShortageInvestigationStatus.day1InProgress]: [
        ...Day2Fields,
        ...Day3Fields,
        ...Day4Fields,
        ...Day5Fields,
        ...Day10Fields,
        ...Day15Fields,
        ...Day20Fields,
      ],
      [EShortageInvestigationStatus.day2InProgress]: [
        ...Day3Fields,
        ...Day4Fields,
        ...Day5Fields,
        ...Day10Fields,
        ...Day15Fields,
        ...Day20Fields,
      ],
      [EShortageInvestigationStatus.day3InProgress]: [
        ...Day4Fields,
        ...Day5Fields,
        ...Day10Fields,
        ...Day15Fields,
        ...Day20Fields,
      ],
      [EShortageInvestigationStatus.day4InProgress]: [
        ...Day5Fields,
        ...Day10Fields,
        ...Day15Fields,
        ...Day20Fields,
      ],
      [EShortageInvestigationStatus.day5InProgress]: [
        ...Day10Fields,
        ...Day15Fields,
        ...Day20Fields,
      ],
      [EShortageInvestigationStatus.day10InProgress]: [
        ...Day15Fields,
        ...Day20Fields,
      ],
      [EShortageInvestigationStatus.day15InProgress]: [...Day20Fields],
    };

    const inprogressDay = isFreightLocated ? lastStatusNumber : status;

    if (
      reqParams["freightLocated"] === "Y" &&
      (reqParams["contactTerminals"] === null ||
        reqParams["contactTerminals"] === undefined ||
        reqParams["contactTerminals"] === "N")
    ) {
      deleteFields.push("contactTerminals", "investigationDetails");
    }

    if (fieldsMap[inprogressDay]) {
      deleteFields.push(...fieldsMap[inprogressDay]);
    }

    deleteFields.forEach((f: string) => {
      delete reqParams[f];
    });

    if (reqParams["consigneePhone"])
      reqParams["consigneePhone"] = reqParams["consigneePhone"]?.toString();
    if (reqParams["shipperPhone"])
      reqParams["shipperPhone"] = reqParams["shipperPhone"]?.toString();
    if (reqParams["pickupManifest"] && !isNaN(reqParams["pickupManifest"])) {
      reqParams["pickupManifest"] = parseInt(reqParams["pickupManifest"]);
    } else {
      reqParams["pickupManifest"] = undefined;
    }

    _.get(reqParams, "investigationDetails", []).forEach(
      (e: IDay5Investigation, i: number) => {
        reqParams["investigationDetails"][i]["manifest"] = +e["manifest"]!;
      }
    );

    if (showSendAPB()) {
      reqParams["attachmentImageUrls"] = selectedImages;
    }

    ShortageInvestigationCreateUpdateAPI(reqParams, props.osdNumber?.toString())
      .then((res) => {
        const { statusNumber } = res;
        Object.keys(res).forEach((field: string) => {
          if (
            [
              ...Day1Fields,
              ...Day2Fields,
              ...Day3Fields,
              ...Day4Fields,
              ...Day10Fields,
              ...Day15Fields,
              ...Day20Fields,
              ...Day5Fields,
            ].indexOf(field) !== -1
          ) {
            updateInitValue(field, res[field]);
          }
        });

        let message = "";

        if (action === "save") {
          message = "OS&D Investigation notes have been saved successfully";
          setPageLoading(false);
        } else if (action === "freightLocated") {
          setPageLoading(false);
          message =
            "OS&D Investigation notes freight located have been successfully";
        } else {
          message =
            "OS&D Investigation notes have been completed successfully & Upload Investigation Image In progress";
          uploadInvestigation();
        }

        dispatch(
          PopoutNotification(
            NOTIFICATION_TYPE.SUCCESS,
            message,
            Date.now().toString()
          )
        );
        if (
          status === statusNumber ||
          statusNumber > EShortageInvestigationStatus.day20InProgress ||
          (status === EShortageInvestigationStatus.exceptionEntry &&
            statusNumber === EShortageInvestigationStatus.day1InProgress)
        ) {
          setFormFieldsDisabled(true);
          setIsEditMode(true);
        } else {
          setIsEditMode(false);
        }
        setStatus(statusNumber);
        setIsFreightLocated(res?.freightLocated === "Y");
        props?.setInvestigationStatus(statusNumber);
        
        if (res?.freightLocated === "N") {
          setActiveStep(
            StepStatusMap[statusNumber as keyof typeof StepStatusMap]
          );
        } else {
          setLastStatusNumber(res.lastStatusNumber);
        }
        validateForm(values);
        dispatch(isFormValueChanged(false));
      })
      .catch((err) => {
        setPageLoading(false);
        dispatch(
          PopoutNotification(
            NOTIFICATION_TYPE.ERROR,
            typeof err === "string"
              ? err
              : `OS&D Investigation notes ${action} failed`,
            Date.now().toString()
          )
        );
      });
  };

  const stepClass = (index: number) => {
    const iStatus = isFreightLocated ? lastStatusNumber : status;
    if (
      isFreightLocated &&
      StepStatusMap[iStatus as keyof typeof StepStatusMap] >= index
    ) {
      return "completeStep";
    }
    if (
      (index === activeStep &&
        StepStatusMap[iStatus as keyof typeof StepStatusMap] > activeStep) ||
      StepStatusMap[iStatus as keyof typeof StepStatusMap] > index ||
      iStatus === EShortageInvestigationStatus.pendingSrApproval ||
      (!isFreightLocated && iStatus === EShortageInvestigationStatus.complete)
    ) {
      return "completeStep";
    } else if (
      index === activeStep ||
      StepStatusMap[iStatus as keyof typeof StepStatusMap] === index
    ) {
      return "activeStep";
    }
    return "";
  };

  const dayCount = (step: number) => {
    const val = step + 1;
    if (val === 6) {
      return 10;
    } else if (val === 7) {
      return 15;
    }
    if (val > 7) {
      return 20;
    }

    return val;
  };

  useEffect(() => {
    validateForm().then((formErrors: FormikErrors<any>) => {
      const investigationDetailsErrors = formErrors.investigationDetails;
      if (!investigationDetailsErrors || !investigationDetailsErrors.length) {
        setAddButtonDisabled(false);
      } else {
        validateDay5Fields();
        setAddButtonDisabled(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (!props.investigationDetails) return;
    const { investigationFreightLocated } = _.get(
      props,
      "investigationDetails",
      {}
    );
    setIsFreightLocated(investigationFreightLocated === "Y");

    setLastStatusNumber(
      _.get(props, "investigationDetails.investigationLastStatusNumber", 1)
    );

    Object.keys(props?.investigationDetails).forEach((key: string) => {
      if (
        [
          ...Day1Fields,
          ...Day2Fields,
          ...Day3Fields,
          ...Day4Fields,
          ...Day10Fields,
          ...Day15Fields,
          ...Day20Fields,
          ...Day5Fields,
        ].indexOf(key) !== -1
      ) {
        setFieldValue(key, _.get(props, `investigationDetails[${key}]`));
        updateInitValue(key, _.get(props, `investigationDetails[${key}]`));
      }

      _.get(props, "investigationDetails.investigationDetails", []).forEach(
        (obj: any, index: number) => {
          setFieldValue(
            `investigationDetails[${index}].terminal`,
            _.get(obj, `terminal`, "")
          );
          setFieldValue(
            `investigationDetails[${index}].manifest`,
            _.get(obj, `manifest`, undefined)
          );
          setFieldValue(
            `investigationDetails[${index}].discrepancies`,
            _.get(obj, `discrepancies`, "")
          );
        }
      );
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.investigationDetails]);

  useEffect(() => {
    if (props.status) {
      const currentDay =
        lastStatusNumber &&
        lastStatusNumber >
          props.investigationDetails?.investigationLastStatusNumber
          ? lastStatusNumber
          : props.investigationDetails?.investigationLastStatusNumber;
      const investigationStatus =
        isFreightLocated ||
        props.investigationDetails?.investigationFreightLocated === "Y"
          ? currentDay
          : props.status;
      setActiveStep(
        StepStatusMap[investigationStatus as keyof typeof StepStatusMap]
      );
      setStatus(props.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status]);

  useEffect(() => {
    dispatch(isFormValueChanged(!_.isEqual(initValues, values)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, initValues]);

  return (
    <div id="as">
      <AccordionDetails className="accordion-container">
        <Grid item md={12} xs={12}>
          <FormControl className="custom-group">
            <FormLabel id=" ">
              Investigation Day&nbsp;
              {dayCount(activeStep)}
              <sup>*</sup>{" "}
            </FormLabel>
          </FormControl>
        </Grid>
        <ThemeProvider theme={theme}>
          <Stepper nonLinear activeStep={activeStep}>
            {ASInvestigationWizardStep.map((label, index) => (
              <Step key={label} className={stepClass(index)}>
                <StepButton
                  onClick={index === activeStep ? void 0 : handleStep(index)}
                  disableRipple
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </ThemeProvider>
        <FormikProvider key={resetTimestamps} value={form}>
          <>
            {activeStep === StepStatusMap[1] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="destinationTerminal">
                        Does the freight show at the destination terminal?{" "}
                        <sup>*</sup> &nbsp;
                        <ClickAwayListener
                          key="day1"
                          onClickAway={() => handleTooltipClose("day1")}
                        >
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose("day1")}
                            open={openTooltips["day1"] || false}
                            disableFocusListener
                            disableTouchListener
                            title="Freight must be forced to the DT if not already showing there"
                          >
                            <InfoIcon
                              className="info-icon"
                              onMouseEnter={() => handleTooltipOpen("day1")}
                              onMouseLeave={() => handleTooltipClose("day1")}
                            />
                          </Tooltip>
                        </ClickAwayListener>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="destinationTerminal"
                            name="destinationTerminal"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="showAtDestTerm"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("dateToDestTerm", null, true);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.showAtDestTerm === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="showAtDestTerm"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue(
                                  "dateToDestTerm",
                                  initValues["dateToDestTerm"],
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.showAtDestTerm === "N"}
                            />
                          </RadioGroup>

                          {values.showAtDestTerm === "N" && (
                            <FormLabel id="destinationTerminal">
                              Date forced to destination terminal <sup>*</sup>
                            </FormLabel>
                          )}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            {values.showAtDestTerm === "N" && (
                              <DatePicker
                                disableFuture
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                value={dayjs(values.dateToDestTerm ?? null)}
                                name="dateToDestTerm"
                                onChange={(value) => {
                                  setOpen(false);
                                  setFieldValue(
                                    "dateToDestTerm",
                                    value?.format("MM/DD/YY"),
                                    true
                                  );
                                }}
                                format="MM/DD/YYYY"
                                slotProps={{
                                  textField: {
                                    onBlur: (e) => {
                                      handleBlur(e);
                                      isValidDependedField(
                                        "dateToDestTerm",
                                        "showAtDestTerm",
                                        StepStatusMap[2],
                                        "N"
                                      );
                                    },
                                    onClick: () => setOpen(true),
                                    inputProps: {
                                      readOnly: true,
                                    },
                                  },
                                }}
                                disabled={formFieldsDisabled}
                                className="col-md-4 mt-1"
                              />
                            )}
                          </LocalizationProvider>
                          {invalidFields.includes(
                            EASInvestigationFields.DateToDestTerm
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day1}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        <FormControlLabel
                          className="checkbox-shoartage"
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.notInDestTerm === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "notInDestTerm",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "notInDestTermSteps",
                                  e.target.checked
                                    ? values.notInDestTermSteps
                                    : null,
                                  true
                                );
                              }}
                              disabled={
                                formFieldsDisabled ||
                                isControlDisabled("notInDestTerm")
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Confirm freight is not in the destination terminals OS&D
                        bay or on their Overage report <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.notInDestTerm === "Y" && (
                            <TextField
                              name="notInDestTermSteps"
                              value={values.notInDestTermSteps}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              inputProps={{ maxLength: 60 }}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EASInvestigationFields.NotInDestTermSteps
                                );
                              }}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                          {invalidFields.includes(
                            EASInvestigationFields.NotInDestTermSteps
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day1}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[3] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        <FormControlLabel
                          className="checkbox-shoartage"
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.noMovement === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "noMovement",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "dateLastMovement",
                                  e.target.checked
                                    ? values.dateLastMovement
                                    : null,
                                  true
                                );
                                setFieldValue(
                                  "noMovementSteps",
                                  e.target.checked
                                    ? values.noMovementSteps
                                    : null,
                                  true
                                );
                              }}
                              disabled={
                                formFieldsDisabled ||
                                isControlDisabled("noMovement")
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Confirm the master shows no movement ? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={4} xs={12} className="all-short-entry">
                          {values.noMovement === "Y" && (
                            <FormLabel
                              id="destinationTerminal"
                              className="date-label-margin"
                            >
                              Select date of last movement <sup>*</sup>
                            </FormLabel>
                          )}
                          {values.noMovement === "Y" && (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                disableFuture
                                value={dayjs(values.dateLastMovement ?? null)}
                                name="dateLastMovement"
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                onChange={(value) => {
                                  setFieldValue(
                                    "dateLastMovement",
                                    value?.format("MM/DD/YY"),
                                    true
                                  );
                                  setOpen(false);
                                }}
                                format="MM/DD/YYYY"
                                slotProps={{
                                  textField: {
                                    onBlur: handleBlur,
                                    onClick: () => {
                                      setOpen(true);
                                    },
                                    inputProps: {
                                      readOnly: true,
                                    },
                                  },
                                }}
                                disabled={formFieldsDisabled}
                                className="col-md-12"
                              />
                            </LocalizationProvider>
                          )}
                        </Grid>
                        <Grid item md={8} xs={12} className="all-short-entry">
                          {values.noMovement === "Y" && (
                            <FormLabel
                              id="destinationTerminal"
                              className="date-label-margin"
                            >
                              &nbsp;
                            </FormLabel>
                          )}
                          {values.noMovement === "Y" && (
                            <TextField
                              name="noMovementSteps"
                              value={values.noMovementSteps}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              inputProps={{ maxLength: 60 }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                          {invalidFields.includes(
                            EASInvestigationFields.NoMovementSteps
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day2}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        <FormControlLabel
                          className="checkbox-shoartage"
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.suffixBill === "Y"}
                              disabled={
                                formFieldsDisabled ||
                                isControlDisabled("suffixBill")
                              }
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "suffixBill",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "suffixBillSteps",
                                  e.target.checked
                                    ? values.suffixBillSteps
                                    : null,
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Check if a suffix bill or OD400 was cut to move some or
                        all of the freight <sup>*</sup>
                        <ClickAwayListener
                          key={"day2"}
                          onClickAway={() => handleTooltipClose("day2")}
                        >
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose("day2")}
                            open={openTooltips["day2"] || false}
                            disableFocusListener
                            disableTouchListener
                            title="If yes, view the AS exception & confirm it was cleared properly. If no, move to next step."
                          >
                            <InfoIcon
                              className="info-icon"
                              onMouseEnter={() => handleTooltipOpen("day2")}
                              onMouseLeave={() => handleTooltipClose("day2")}
                            />
                          </Tooltip>
                        </ClickAwayListener>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.suffixBill === "Y" && (
                            <TextField
                              name="suffixBillSteps"
                              value={values.suffixBillSteps}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              inputProps={{ maxLength: 60 }}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EASInvestigationFields.SuffixBillSteps
                                );
                              }}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                    {invalidFields.includes(
                      EASInvestigationFields.SuffixBillSteps
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day2}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled || isControlDisabled("bolCheck")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.bolCheck === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "bolCheck",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "bolCheckSteps",
                                  e.target.checked
                                    ? values.bolCheckSteps
                                    : null,
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Check the BOL to confirm completion and no
                        discrepancies? <sup>*</sup>
                        <ClickAwayListener
                          key={"day3"}
                          onClickAway={() => handleTooltipClose("day3")}
                        >
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose("day3")}
                            open={openTooltips["day3"] || false}
                            disableFocusListener
                            disableTouchListener
                            title="Use info on BOL in option 5 to look for duplicate Pros/billing errors. Be sure to check for how the BOL was signed."
                          >
                            <InfoIcon
                              className="info-icon"
                              onMouseEnter={() => handleTooltipOpen("day3")}
                              onMouseLeave={() => handleTooltipClose("day3")}
                            />
                          </Tooltip>
                        </ClickAwayListener>
                        {/* ))} */}
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.bolCheck === "Y" && (
                            <>
                              <TextField
                                name="bolCheckSteps"
                                value={values.bolCheckSteps}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 60 }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist(
                                    EASInvestigationFields.BolCheckSteps
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                variant="outlined"
                              />
                              {invalidFields.includes(
                                EASInvestigationFields.BolCheckSteps
                              ) && (
                                <span className="form-error">
                                  {EASInvestigationFieldsErrorMessage.day2}
                                </span>
                              )}
                              <br />
                              <br />
                              <Snackbar
                                className="snackbar-error flex-start"
                                anchorOrigin={{ vertical, horizontal }}
                                open={true}
                                onClose={handleClose}
                                message="Note: Search for PO #, BOL#, Shipper #, other Pro #’s, Driver signature & date, and any notation of shipper load & count."
                                key={vertical + horizontal}
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="destinationTerminal">
                        Is there a DR? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="drCheck"
                            name="drCheck"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="drCheck"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue(
                                  "drCheckSteps",
                                  initValues["drCheckSteps"],
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.drCheck === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="drCheck"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("drCheckSteps", null, true);
                                setInvalidFields([
                                  ...invalidFields.filter(
                                    (field) => field !== "drCheckSteps"
                                  ),
                                ]);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.drCheck === "N"}
                            />
                          </RadioGroup>

                          {values.drCheck === "Y" && (
                            <>
                              <FormLabel id="destinationTerminal">
                                Did you confirm completion and no discrepancies?{" "}
                                <sup>*</sup>
                              </FormLabel>
                              <TextField
                                id="drCheckSteps"
                                name="drCheckSteps"
                                fullWidth
                                value={values.drCheckSteps}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    "drCheckSteps",
                                    "drCheck",
                                    StepStatusMap[3]
                                  );
                                  isValueExist(
                                    EASInvestigationFields.DrCheckSteps
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                className="mt-1"
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 60 }}
                                variant="outlined"
                              />
                            </>
                          )}
                          {invalidFields.includes(
                            EASInvestigationFields.DrCheckSteps
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day2}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[4] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled || isControlDisabled("pumCheck")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.pumCheck === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "pumCheck",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "pickupManifest",
                                  e.target.checked
                                    ? values.pickupManifest
                                    : null,
                                  true
                                );
                                // setFieldValue("pumSteps", e.target.checked ? values.pumSteps : null, true)
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Check the PUM (Pickup manifest) for discrepancies,
                        shipper load and count information, and proper reporting
                        process <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={4} xs={12} className="all-short-entry">
                          {values.pumCheck === "Y" && (
                            <>
                              <FormLabel
                                id="destinationTerminal"
                                className="mt-0 mb-1"
                              >
                                Pickup Manifest # <sup>*</sup>
                              </FormLabel>
                              <TextField
                                name="pickupManifest"
                                value={values.pickupManifest}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                type="number"
                                onKeyDown={HandleKeyDown}
                                onInput={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  HandleNumberMaxLength(e, 9);
                                }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist(
                                    EASInvestigationFields.PickupManifest
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                variant="outlined"
                              />
                            </>
                          )}
                          {invalidFields.includes(
                            EASInvestigationFields.PickupManifest
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                        <Grid item md={8} xs={12} className="all-short-entry">
                          {values.pumCheck === "Y" && (
                            <>
                              <FormLabel
                                id="destinationTerminal"
                                className="mt-0 mb-1"
                              >
                                List any and all discrepancies <sup>*</sup>
                              </FormLabel>
                              <TextField
                                name="pumSteps"
                                value={values.pumSteps}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 60 }}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValueExist(EASInvestigationFields.PumSteps);
                                }}
                                disabled={formFieldsDisabled}
                                variant="outlined"
                              />
                            </>
                          )}
                          {invalidFields.includes(
                            EASInvestigationFields.PumSteps
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="shipperLoad">
                        Was this a shipper load and count? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="shipperLoad"
                            name="shipperLoad"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="shipperLoad"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue(
                                  "discrepancies",
                                  initValues["discrepancies"],
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.shipperLoad === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="shipperLoad"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("discrepancies", null, true);
                                setInvalidFields([
                                  ...invalidFields.filter(
                                    (field) => field !== "discrepancies"
                                  ),
                                ]);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.shipperLoad === "N"}
                            />
                          </RadioGroup>

                          {values.shipperLoad === "Y" && (
                            <FormLabel id="destinationTerminal">
                              Were there any discrepancies? If so, were they
                              properly reported to the shipper? <sup>*</sup>
                            </FormLabel>
                          )}
                          {values.shipperLoad === "Y" && (
                            <TextField
                              name="discrepancies"
                              value={values.discrepancies}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              inputProps={{ maxLength: 60 }}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValidDependedField(
                                  EASInvestigationFields.Discrepancies,
                                  EASInvestigationFields.ShipperLoad,
                                  StepStatusMap[4]
                                );
                                isValueExist(
                                  EASInvestigationFields.Discrepancies
                                );
                              }}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}

                          {invalidFields.includes(
                            EASInvestigationFields.Discrepancies
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("checkLinehaulManifests")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.checkLinehaulManifests === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "checkLinehaulManifests",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "linehaulManifests",
                                  e.target.checked
                                    ? values.linehaulManifests
                                    : null,
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Check all linehaul manifests – movement and
                        abnormalities. <sup>*</sup> &nbsp;
                        <ClickAwayListener
                          key={"day4"}
                          onClickAway={() => handleTooltipClose("day4")}
                        >
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose("day4")}
                            open={openTooltips["day4"] || false}
                            disableFocusListener
                            disableTouchListener
                            title="Get manifest numbers from the movement screen (F10)"
                          >
                            <InfoIcon
                              className="info-icon"
                              onMouseEnter={() => handleTooltipOpen("day4")}
                              onMouseLeave={() => handleTooltipClose("day4")}
                            />
                          </Tooltip>
                        </ClickAwayListener>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.checkLinehaulManifests === "Y" && (
                            <TextField
                              name="linehaulManifests"
                              value={values.linehaulManifests}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              inputProps={{ maxLength: 60 }}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EASInvestigationFields.LinehaulManifests
                                );
                              }}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                          {invalidFields.includes(
                            EASInvestigationFields.LinehaulManifests
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("interviewWorkers")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.interviewWorkers === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "interviewWorkers",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "workerSteps",
                                  e.target.checked ? values.workerSteps : null,
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Interview the dock worker(s) about movement
                        abnormalities. <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.interviewWorkers === "Y" && (
                            <TextField
                              name="workerSteps"
                              value={values.workerSteps}
                              fullWidth
                              placeholder="Describe Steps Taken"
                              inputProps={{ maxLength: 60 }}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EASInvestigationFields.WorkerSteps
                                );
                              }}
                              disabled={formFieldsDisabled}
                              variant="outlined"
                            />
                          )}
                          {invalidFields.includes(
                            EASInvestigationFields.WorkerSteps
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day3}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <h3 className="header-text mb-0">
                      Call consignee to confirm what was ordered and received
                      (if anything)
                    </h3>
                  </Grid>

                  <Grid item md={3} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        Phone Number <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="consigneePhone"
                        value={values.consigneePhone}
                        fullWidth
                        inputProps={{ maxLength: 12 }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EASInvestigationFields.ConsigneePhone);
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Enter Phone Number"
                      />
                    </FormControl>
                    {invalidFields.includes(
                      EASInvestigationFields.ConsigneePhone
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={3} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        Consignee Name <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="consigneeName"
                        value={values.consigneeName}
                        fullWidth
                        inputProps={{ maxLength: 30 }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EASInvestigationFields.ConsigneeName);
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Enter Consignee Name"
                      />
                    </FormControl>
                    {invalidFields.includes(
                      EASInvestigationFields.ConsigneeName
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="destinationTerminal">
                        Is the consignee still short? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="destinationTerminal"
                            name="destinationTerminal"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="consigneeShort"
                              // onChange={handleChange}
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue(
                                  "itemNumbers",
                                  initValues["itemNumbers"],
                                  true
                                );
                                setFieldValue(
                                  "orderReceived",
                                  initValues["orderReceived"],
                                  true
                                );
                                setFieldValue(
                                  "freightLabeled",
                                  initValues["freightLabeled"],
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.consigneeShort === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="consigneeShort"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("itemNumbers", null, true);
                                setFieldValue("orderReceived", null, true);
                                setFieldValue("freightLabeled", null, true);
                                setInvalidFields([
                                  ...invalidFields.filter(
                                    (field) =>
                                      ![
                                        "itemNumbers",
                                        "orderReceived",
                                        "orderReceived",
                                      ].includes(field)
                                  ),
                                ]);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.consigneeShort === "N"}
                            />
                          </RadioGroup>

                          {values.consigneeShort === "N" && (
                            <Snackbar
                              className="snackbar-error flex-start"
                              anchorOrigin={{ vertical, horizontal }}
                              open={true}
                              onClose={handleClose}
                              message="Note: If the consignee is no longer short, the investigation is considered complete once we obtain written confirmation and/or a signed DR."
                              key={vertical + horizontal}
                            />
                          )}

                          {values.consigneeShort === "Y" && (
                            <>
                              <FormLabel id=" ">
                                What are the item numbers received and still
                                missing? <sup>*</sup>
                              </FormLabel>
                              <TextField
                                name="itemNumbers"
                                value={values.itemNumbers}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    EASInvestigationFields.ItemNumbers,
                                    EASInvestigationFields.ConsigneeShort,
                                    StepStatusMap[4]
                                  );
                                  isValueExist(
                                    EASInvestigationFields.ItemNumbers
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 60 }}
                                variant="outlined"
                              />
                              {invalidFields.includes(
                                EASInvestigationFields.ItemNumbers
                              ) && (
                                <span className="form-error">
                                  {EASInvestigationFieldsErrorMessage.day3}
                                </span>
                              )}
                              <FormLabel id=" ">
                                What was ordered and received for each PO?{" "}
                                <sup>*</sup>
                              </FormLabel>
                              <TextField
                                name="orderReceived"
                                value={values.orderReceived}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    EASInvestigationFields.OrderReceived,
                                    EASInvestigationFields.ConsigneeShort,
                                    StepStatusMap[4]
                                  );
                                  isValueExist(
                                    EASInvestigationFields.OrderReceived
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 60 }}
                                variant="outlined"
                              />
                              {invalidFields.includes(
                                EASInvestigationFields.OrderReceived
                              ) && (
                                <span className="form-error">
                                  {EASInvestigationFieldsErrorMessage.day3}
                                </span>
                              )}
                              <FormLabel id=" ">
                                Was the freight received labeled for them?
                                <sup>*</sup>
                              </FormLabel>
                              <TextField
                                name="freightLabeled"
                                value={values.freightLabeled}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    EASInvestigationFields.FreightLabeled,
                                    EASInvestigationFields.ConsigneeShort,
                                    StepStatusMap[4]
                                  );
                                  isValueExist(
                                    EASInvestigationFields.FreightLabeled
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                fullWidth
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 60 }}
                                variant="outlined"
                              />
                              {invalidFields.includes(
                                EASInvestigationFields.FreightLabeled
                              ) && (
                                <span className="form-error">
                                  {EASInvestigationFieldsErrorMessage.day3}
                                </span>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <h3 className="header-text mb-0">
                      Call Shipper to confirm what was shipped, how it was
                      packaged and the value?
                    </h3>
                  </Grid>

                  <Grid item md={3} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        Phone Number <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="shipperPhone"
                        value={values.shipperPhone}
                        fullWidth
                        inputProps={{ maxLength: 12 }}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EASInvestigationFields.ShipperPhone);
                        }}
                        disabled={formFieldsDisabled}
                        variant="outlined"
                        placeholder="Enter Phone Number"
                      />
                    </FormControl>
                    {invalidFields.includes(
                      EASInvestigationFields.ShipperPhone
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={3} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        Shipper Name <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="shipperName"
                        value={values.shipperName}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EASInvestigationFields.ShipperName);
                        }}
                        disabled={formFieldsDisabled}
                        placeholder="Enter Shipper Name"
                        inputProps={{ maxLength: 30 }}
                        variant="outlined"
                      />
                    </FormControl>
                    {invalidFields.includes(
                      EASInvestigationFields.ShipperName
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        What are the details of what shipped? <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="shippedDetails"
                        value={values.shippedDetails}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EASInvestigationFields.ShippedDetails);
                        }}
                        disabled={formFieldsDisabled}
                        fullWidth
                        placeholder="Describe Steps Taken"
                        inputProps={{ maxLength: 60 }}
                        variant="outlined"
                      />
                    </FormControl>
                    {invalidFields.includes(
                      EASInvestigationFields.ShippedDetails
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        Was this short shipped? <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="shortShipped"
                        value={values.shortShipped}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EASInvestigationFields.ShortShipped);
                        }}
                        disabled={formFieldsDisabled}
                        fullWidth
                        placeholder="Describe Steps Taken"
                        inputProps={{ maxLength: 60 }}
                        variant="outlined"
                      />
                    </FormControl>
                    {invalidFields.includes(
                      EASInvestigationFields.ShortShipped
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        What is the value of the product? <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="productValue"
                        value={values.productValue}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EASInvestigationFields.ProductValue);
                        }}
                        disabled={formFieldsDisabled}
                        fullWidth
                        placeholder="Describe Steps Taken"
                        inputProps={{ maxLength: 60 }}
                        variant="outlined"
                      />
                    </FormControl>
                    {invalidFields.includes(
                      EASInvestigationFields.ProductValue
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} className="pt-0">
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        Were there multiple shipments for the same consignee
                        that day? <sup>*</sup>
                      </FormLabel>
                      <TextField
                        name="multShipments"
                        value={values.multShipments}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          isValueExist(EASInvestigationFields.MultShipments);
                        }}
                        disabled={formFieldsDisabled}
                        fullWidth
                        placeholder="Describe Steps Taken"
                        inputProps={{ maxLength: 60 }}
                        variant="outlined"
                      />
                    </FormControl>
                    {invalidFields.includes(
                      EASInvestigationFields.MultShipments
                    ) && (
                      <span className="form-error">
                        {EASInvestigationFieldsErrorMessage.day3}
                      </span>
                    )}
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[5] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled || isControlDisabled("check7818")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.check7818 === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "check7818",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "details7818",
                                  e.target.checked ? values.details7818 : null,
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Check 78/18 and/or Power BI for any overages (OV, NB,
                        DO) to apply {values.check7818} <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.check7818 === "Y" && (
                            <TextField
                              id={`details7818`}
                              name="details7818"
                              value={values.details7818}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EASInvestigationFields.Details7818
                                );
                              }}
                              disabled={formFieldsDisabled}
                              fullWidth
                              className=""
                              placeholder="Provide details of your search"
                              inputProps={{ maxLength: 60 }}
                              variant="outlined"
                            />
                          )}

                          {invalidFields.includes(
                            EASInvestigationFields.Details7818
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day4}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="destinationTerminal">
                        Did this show manifested to the destination terminal
                        (even if NF)? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="destinationTerminal"
                            name="manifested"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="manifested"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue(
                                  "cameraFindings",
                                  initValues["cameraFindings"],
                                  true
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.manifested === "Y"}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="manifested"
                              onChange={(e: React.ChangeEvent<any>) => {
                                handleChange(e);
                                setFieldValue("cameraFindings", null, true);
                                setInvalidFields([
                                  ...invalidFields.filter(
                                    (field) => field !== "cameraFindings"
                                  ),
                                ]);
                              }}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                              checked={values.manifested === "N"}
                            />
                          </RadioGroup>

                          {values.manifested === "Y" && (
                            <>
                              <FormLabel id="destinationTerminal">
                                Check cameras at DT and report findings{" "}
                                <sup>&nbsp;</sup>
                              </FormLabel>
                              <TextField
                                name="cameraFindings"
                                value={values.cameraFindings}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  isValidDependedField(
                                    EASInvestigationFields.CameraFindings,
                                    EASInvestigationFields.Manifested,
                                    StepStatusMap[5]
                                  );
                                  isValueExist(
                                    EASInvestigationFields.CameraFindings
                                  );
                                }}
                                disabled={formFieldsDisabled}
                                fullWidth
                                className="mt-1"
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 60 }}
                                variant="outlined"
                              />
                            </>
                          )}
                          {invalidFields.includes(
                            EASInvestigationFields.CameraFindings
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day4}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("callSameDays")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.callSameDays === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "callSameDays",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "sameDayPros",
                                  e.target.checked ? values.sameDayPros : null,
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Call same days <sup>*</sup>
                        <ClickAwayListener
                          key={"day5"}
                          onClickAway={() => handleTooltipClose("day5")}
                        >
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose("day5")}
                            open={openTooltips["day5"] || false}
                            disableFocusListener
                            disableTouchListener
                            title="Use F17 from FB inquiry screen for pickup info."
                          >
                            <InfoIcon
                              className="info-icon"
                              onMouseEnter={() => handleTooltipOpen("day5")}
                              onMouseLeave={() => handleTooltipClose("day5")}
                            />
                          </Tooltip>
                        </ClickAwayListener>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.callSameDays === "Y" && (
                            <TextField
                              name="sameDayPros"
                              value={values.sameDayPros}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EASInvestigationFields.SameDayPros
                                );
                              }}
                              disabled={formFieldsDisabled}
                              fullWidth
                              className=""
                              placeholder="List all same day Pro’s and their delivery info"
                              inputProps={{ maxLength: 60 }}
                              variant="outlined"
                            />
                          )}
                          {invalidFields.includes(
                            EASInvestigationFields.SameDayPros
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day4}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[6] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("contactTerminals")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.contactTerminals === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "contactTerminals",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "investigationDetails",
                                  e.target.checked
                                    ? _.isArray(values.investigationDetails) &&
                                      values.investigationDetails.length > 0
                                      ? values.investigationDetails
                                      : ASInvestigationInitValue.investigationDetails
                                    : [],
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />
                        Contact the terminals that handled the freight to find
                        leads and check cameras if applicable * <sup>*</sup>
                        <ClickAwayListener
                          key={"day6"}
                          onClickAway={() => handleTooltipClose("day6")}
                        >
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose("day6")}
                            open={openTooltips["day6"] || false}
                            disableFocusListener
                            disableTouchListener
                            title="Use movement screen (F10) to locate terminals."
                          >
                            <InfoIcon
                              className="info-icon"
                              onMouseEnter={() => handleTooltipOpen("day6")}
                              onMouseLeave={() => handleTooltipClose("day6")}
                            />
                          </Tooltip>
                        </ClickAwayListener>
                      </FormLabel>
                    </FormControl>
                  </Grid>

                  {values.contactTerminals === "Y" && (
                    <Grid
                      container
                      spacing={2}
                      className="filter-margin custom-container-pos"
                    >
                      <FieldArray name="investigationDetails">
                        {({ push, remove }) => (
                          <>
                            {_.get(values, "investigationDetails", []).map(
                              (
                                investigationD: IDay5Investigation,
                                index: number
                              ) => (
                                <React.Fragment key={index}>
                                  <Grid item md={12} xs={12} className="pt-0">
                                    <h3 className="header-text mb-0">
                                      Investigation details: {index + 1}
                                    </h3>
                                  </Grid>

                                  <Grid item md={3} xs={12} className="pt-0">
                                    <FormControl className="radio-group question-group ">
                                      <FormLabel id=" ">
                                        TID <sup>*</sup>
                                      </FormLabel>
                                      <TextField
                                        id={`tid-${index}`}
                                        name={`investigationDetails[${index}].terminal`}
                                        value={_.get(
                                          values,
                                          `investigationDetails[${index}].terminal`
                                        )}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                          isValueExist(
                                            EASInvestigationFields.Terminal,
                                            index
                                          );
                                        }}
                                        disabled={formFieldsDisabled}
                                        fullWidth
                                        className="mt-1"
                                        placeholder="Enter TID"
                                        inputProps={{ maxLength: 5 }}
                                        variant="outlined"
                                      />
                                    </FormControl>
                                    {invalidFields.includes(
                                      EASInvestigationFields.Terminal + index
                                    ) && (
                                      <span className="form-error">
                                        {
                                          EASInvestigationFieldsErrorMessage.day5
                                        }
                                      </span>
                                    )}
                                  </Grid>

                                  <Grid item md={3} xs={12} className="pt-0">
                                    <FormControl className="radio-group question-group ">
                                      <FormLabel id=" ">
                                        Manifest #<sup>*</sup>
                                      </FormLabel>
                                      <TextField
                                        id={`manifest-${index}`}
                                        type="number"
                                        onKeyDown={HandleKeyDown}
                                        onInput={(
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          HandleNumberMaxLength(e, 9);
                                        }}
                                        name={`investigationDetails[${index}].manifest`}
                                        value={_.get(
                                          values,
                                          `investigationDetails[${index}].manifest`
                                        )}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                          isValueExist(
                                            EASInvestigationFields.Manifest,
                                            index
                                          );
                                        }}
                                        disabled={formFieldsDisabled}
                                        fullWidth
                                        className="mt-1"
                                        placeholder="Enter Manifest #"
                                        inputProps={{ maxLength: 9 }}
                                        variant="outlined"
                                      />
                                    </FormControl>
                                    {invalidFields.includes(
                                      EASInvestigationFields.Manifest + index
                                    ) && (
                                      <span className="form-error">
                                        {
                                          EASInvestigationFieldsErrorMessage.day5
                                        }
                                      </span>
                                    )}
                                  </Grid>

                                  <Grid item md={6} xs={12} className="pt-0">
                                    <FormControl className="radio-group question-group ">
                                      <FormLabel id=" ">
                                        List any discrepancies or abnormalities{" "}
                                        <sup>*</sup>
                                      </FormLabel>
                                      <TextField
                                        id={`pumSteps-${index}`}
                                        name={`investigationDetails[${index}].discrepancies`}
                                        value={_.get(
                                          values,
                                          `investigationDetails[${index}].discrepancies`
                                        )}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                          isValueExist(
                                            EASInvestigationFields.Discrepancies,
                                            index
                                          );
                                        }}
                                        disabled={formFieldsDisabled}
                                        fullWidth
                                        className="mt-1"
                                        placeholder="List all terminals contacted and your findings"
                                        inputProps={{ maxLength: 60 }}
                                        variant="outlined"
                                      />
                                    </FormControl>
                                    {invalidFields.includes(
                                      EASInvestigationFields.Discrepancies +
                                        index
                                    ) && (
                                      <span className="form-error">
                                        {
                                          EASInvestigationFieldsErrorMessage.day5
                                        }
                                      </span>
                                    )}
                                  </Grid>
                                </React.Fragment>
                              )
                            )}
                            <Grid
                              item
                              md={12}
                              xs={12}
                              className="btn-group-submit"
                            >
                              {!(
                                formFieldsDisabled ||
                                status === EShortageInvestigationStatus.complete
                              ) && (
                                <>
                                  {values.investigationDetails?.length! < 6 && (
                                    <Button
                                      onClick={
                                        addButtonDisabled
                                          ? void 0
                                          : () => {
                                              push({
                                                terminal: "",
                                                manifest: "",
                                                discrepancies: "",
                                              });
                                              addRemove();
                                            }
                                      }
                                      variant="contained"
                                      className={`entry ml ${
                                        addButtonDisabled
                                          ? "disable-btn disable"
                                          : ""
                                      }`}
                                    >
                                      Add
                                    </Button>
                                  )}
                                  {values.investigationDetails?.length !==
                                    1 && (
                                    <Button
                                      onClick={() => {
                                        remove(
                                          values.investigationDetails?.length! -
                                            1
                                        );
                                        addRemove("remove");
                                      }}
                                      variant="contained"
                                      className="clear-btn mr-0 ml"
                                    >
                                      Remove
                                    </Button>
                                  )}
                                </>
                              )}
                            </Grid>
                          </>
                        )}
                      </FieldArray>
                    </Grid>
                  )}
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[7] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled || isControlDisabled("followUp")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.followUp === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "followUp",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "followUpDetails",
                                  e.target.checked
                                    ? values.followUpDetails
                                    : null,
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Follow up on all leads/request from previous days{" "}
                        <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.followUp === "Y" && (
                            <TextField
                              name="followUpDetails"
                              value={values.followUpDetails}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EASInvestigationFields.FollowUpDetails
                                );
                              }}
                              disabled={formFieldsDisabled}
                              fullWidth
                              placeholder="Provide details of your search"
                              inputProps={{ maxLength: 60 }}
                              variant="outlined"
                            />
                          )}
                          {invalidFields.includes(
                            EASInvestigationFields.FollowUpDetails
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day10}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {activeStep === StepStatusMap[8] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("check7818_2")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.check7818_2 === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "check7818_2",
                                  e.target.checked ? "Y" : "N"
                                );
                                setFieldValue(
                                  "details7818_2",
                                  e.target.checked
                                    ? values.details7818_2
                                    : null,
                                  true
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Check 78/18 and/or Power BI for any overages (OV, NB,
                        DO) to apply <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          {values.check7818_2 === "Y" && (
                            <TextField
                              name="details7818_2"
                              value={values.details7818_2}
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                isValueExist(
                                  EASInvestigationFields.Details7818_2
                                );
                              }}
                              disabled={formFieldsDisabled}
                              fullWidth
                              placeholder="Provide details of your search"
                              inputProps={{ maxLength: 60 }}
                              variant="outlined"
                            />
                          )}
                          {invalidFields.includes(
                            EASInvestigationFields.Details7818_2
                          ) && (
                            <span className="form-error">
                              {EASInvestigationFieldsErrorMessage.day15}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id="destinationTerminal">
                        Is this high value or high quantity? <sup>*</sup>
                      </FormLabel>
                      <Grid container spacing={2}>
                        <Grid item md={12} xs={12} className="all-short-entry">
                          <RadioGroup
                            row
                            aria-labelledby="destinationTerminal"
                            name="highValue"
                            className="textarea-radio-group"
                          >
                            <FormControlLabel
                              value="Y"
                              control={<Radio />}
                              label="Yes"
                              name="highValue"
                              checked={values.highValue === "Y"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                            />
                            <FormControlLabel
                              value="N"
                              control={<Radio />}
                              label="No"
                              name="highValue"
                              checked={values.highValue === "N"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={formFieldsDisabled}
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
                {/* {showSendAPB() && ( */}
                  <div className={showSendAPB() ? "" : "hide-me"}>
                    <SendAPBImageList
                      osdNumber={props.osdNumber}
                      setSelectedImages={setSelectedImages}
                      selectedImages={selectedImages}
                      handleImageSelect={handleImageSelect}
                      isSendAPBScreen={false}
                    />
                    <br />
                  </div>
                {/* )} */}
              </div>
            )}

            {activeStep === StepStatusMap[9] && (
              <div>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl className="radio-group question-group ">
                      <FormLabel id=" ">
                        <FormControlLabel
                          className="checkbox-shoartage"
                          disabled={
                            formFieldsDisabled ||
                            isControlDisabled("finalReCheck")
                          }
                          control={
                            <Checkbox
                              disableRipple
                              checked={values.finalReCheck === "Y"}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(
                                  "finalReCheck",
                                  e.target.checked ? "Y" : "N"
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label=""
                        />{" "}
                        Final re-check of all leads/requests/overages to confirm
                        the freight cannot be located at this time <sup>*</sup>
                      </FormLabel>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}
          </>
        </FormikProvider>
        <Grid item md={12} xs={12} className="btn-group-submit">
          {formFieldsDisabled &&
            status !== EShortageInvestigationStatus.complete &&
            (status !== EShortageInvestigationStatus.pendingSrApproval ||
              activeStep !==
                StepStatusMap[status as keyof typeof StepStatusMap]) && (
              <>
                <Button
                  variant="contained"
                  onClick={enableEditing}
                  className="assign ml mr-0"
                >
                  Edit
                </Button>
              </>
            )}
          {!formFieldsDisabled && (
            <>
              <Button
                onClick={() => reset()}
                variant="contained"
                className="clear-btn mr-0 ml"
                type="button"
              >
                {isEditMode ? "Cancel" : "Clear"}
              </Button>
              <Button
                variant="contained"
                onClick={
                  invalidFields?.length > 0
                    ? void 0
                    : () => {
                        saveOrSubmit("save");
                      }
                }
                className={
                  invalidFields?.length > 0
                    ? "disable-btn disable entry ml"
                    : "entry ml"
                }
              >
                Save
              </Button>
            </>
          )}
          {formFieldsDisabled &&
            !isFreightLocated &&
            StepStatusMap[status as keyof typeof StepStatusMap] ===
              activeStep &&
            [
              EShortageInvestigationStatus.complete,
              EShortageInvestigationStatus.exceptionEntry,
              EShortageInvestigationStatus.pendingSrApproval,
            ].indexOf(status) === -1 && (
              <Button
                onClick={() => saveOrSubmit("freightLocated", "Y")}
                variant="contained"
                className="entry ml"
              >
                Freight Located
              </Button>
            )}

          {formFieldsDisabled &&
            isPrivilegedUser &&
            StepStatusMap[
              (isFreightLocated
                ? lastStatusNumber
                : status) as keyof typeof StepStatusMap
            ] === activeStep &&
            status === EShortageInvestigationStatus.pendingSrApproval && (
              <Button
                onClick={completeAndImage}
                variant="contained"
                className="entry ml"
              >
                Complete & Image
              </Button>
            )}
        </Grid>
      </AccordionDetails>
      <Loader pageLoader={pageLoading}></Loader>
      {ASInvestigationWizardStep.map((day: string) => (
        <div className="hide-me" id={`investigation-${day}`}>
          <ASInvestigationTemplate
            currentDay={day}
            values={values}
            osdNumber={props.osdNumber}
          />
        </div>
      ))}
    </div>
  );
};

ShortageInvestigation.defaultProps = {
  status: 1,
};
export default ShortageInvestigation;
