import axios from "axios";
import * as _ from "lodash";
import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";
import { IASEntry, IASEntryCreateParams } from "../models/shortage";

/**
 * CRETE API call using Axios
 * @returns - Create shortage entry and return same object
 */

export const CreateShortageEntryAPI = async (params: IASEntryCreateParams): Promise<IASEntry> => {
  try {
    const response = await axios.post(getUrl(ApiType.SHORTAGE_ENTRY_CREATE), params, {
      headers: {
        "Content-Type": "application/json",
      }
    });
    return response.data;
  } catch (error: any) {
    let message = ConstantMessage.createShortageEntry;
    if (_.get(error, "response.status") === 500) {
      message = ConstantMessage.serverError;
    }else if (_.get(error, "response.status") === 400) {
      message = _.get(error, "errors[0].message") || _.get(error, "response.data.errors[0].message")
    }
    throw message;
  }
};
