import axios from "axios";
import * as _ from "lodash";

import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";
import { IShortagesList } from "../models/shortage";
import { IRequestParametersOsd } from "../models/osd";

/**
 * GET API call using Axios
 * @returns - Shortages List of type IShortagesList
 */

export const GetShortagesAPI = async (params: IRequestParametersOsd): Promise<IShortagesList> => {
  try {
    const response = await axios.post(getUrl(ApiType.GET_SHORTAGES), params, {
      headers: {
        "Content-Type": "application/json",
      }
    });
    return _.get(response, 'data', []);
  } catch (error: any) {
    let message = ConstantMessage.getShortagesAPIError;
    if (_.get(error, "response.status") === 500) {
      message = ConstantMessage.serverError;
    }else if (_.get(error, "response.status") === 400) {
      message =  _.get(error, "response.data.errors[0].message");
    }
    throw message;
  }
};
