import Button from "@mui/material/Button";
import "./allShortageEntry.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { InitAllShortageFormValue, ShortageEntryNBSchema } from "../../../schema/shortagesEntry";
import { commodityTypeMasterApi } from "./../../../API/getCommodityTypeMaster";
import {
  IFilterDropdown,
  TTerminal,
  saveFilterCommodityTypeData,
  savePackageOptionMaster,
} from "./../../../AppState/overagesFilterFormActions"
import AppState from "./../../../AppState";
import { getFilterDropdowns } from "./../../../API/getFilterDropdowns";
import { checkPro } from "./../../../API/checkPro";
import CustomDialog from "./../../../components/confirmationPopup/confirmationPopup";
import Loader from "./../../../components/loader/loader";
import { PopoutNotification } from "./../../../AppState/popoutNotification";
import NOTIFICATION_TYPE from "./../../../AppState/NotificationType";
import { getTerminals } from "./../../../API/getTerminals";
import { saveTerminalMasterState } from "./../../../AppState/terminalMaster";
import { AppURLs, CheckDigitFields, EFormAction, EUploadKey } from "./../../../constants/app";
import { DateHelper } from "./../../../helpers/date.helper";
import { HandleKeyDown, HandleNumberMaxLength, SortTerminals, UserTerminal } from "./../../../helpers/utils.helper";
import { TerminalSelection } from "./../../../AppState/terminalSelection";
import _ from "lodash";
import { IShortageEntryDetails } from "../../../models/shortage";
import { isFormValueChanged } from "../../../AppState/isFormValueChanged";
import { ICheckDigitPro } from "../../../models/osd";
import { CreateShortageEntryAPI } from "../../../API/createShortageEntry"; 
import ShortageInvestigation from "./shortageInvestigation";
import { GetASDetailsAPI } from "../../../API/getASEntryDetailsAPI";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip from "@mui/material/Tooltip";
import UploadDocument from "../../../components/uploadDocument/uploadDocument";
import { UpdateASEntryAPI } from "../../../API/updateASEntry";
import { EShortageInvestigationStatus } from "../../../constants/shortage";

interface TooltipState {
  [key: string]: boolean;
}

function AllShortageEntry() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const currentTerminalValue = useSelector(
    (state: AppState) => state.activeTerminal
  );
  // get terminal master from redux state
  let terminalOptions: string[] = useSelector(
    (state: AppState) => state.terminalMaster.reduce((acc: string[], t: TTerminal) => t.region != null ? [...acc, t.value] : acc, []));

  const formValueChanged = useSelector(
    (state: AppState) => state.isFormValueChanged
  );

  const [isSubmitted, setIsSubmitted] =
    React.useState<boolean>(false);

  const [formFieldsDisabled, setFormFieldsDisabled] = React.useState<boolean>(
    !!params?.osdNumber
  );

  const [osdNumber, setOsdNumber] = React.useState<number>(
    Number(params?.osdNumber)
  );
  const [osdDetails, setOsdDetails] = React.useState<IShortageEntryDetails>();
  const [updatedDateTime, setUpdatedDateTime] = React.useState<string>("");
  const [investigationStatus, setInvestigationStatus] = React.useState<number>();
  const [editFormValueChanged, setEditFormValueChanged] = React.useState<boolean>(false);
  const [inputValues, setInputValues] = React.useState({
    cInputValue: undefined
  });

  const updateInputValue = (key: string, value: string | undefined) => {
    setInputValues(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const [autocompleteValues, setAutocompleteValues] = React.useState<any>({
    cType: undefined,
  });

  const updateAutocompleteValue = (key: string, value: any) => {
    setAutocompleteValues((prevState: any) => ({
      ...prevState,
      [key]: value
    }));
  };

  const handleAutoCompleteOnChange = (value: any) => {
    if (value) {
      updateAutocompleteValue("cType", value);
      setFieldValue("commodity", value?.code);
    }
  };

  const onAutoInputChange = (value: string) => {
    if (value === "undefined" || (["commodity"].indexOf(document?.activeElement?.id || "") === -1 && !value)) return;
    updateInputValue("cInputValue", value);
  };


  const commodityCodeOptions = useSelector(
    (state: AppState) => state.filterCommodityTypeData
  );
  const packageCodeOptions = useSelector(
    (state: AppState) => state.packageOptionMasterData
  );
  const [entryUser, setEntryUser] = useState<string>("");
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState(false); // State to manage dialog open/close
  const [proCheckDError, setProCheckDError] = React.useState<boolean>(false);
  // get master from API for commodity code and package code
  const [expanded, setExpanded] = React.useState<string | false>("panel1"); // handle expand page using "expanded" variable

  const selectStyle = {
    fontSize: "13px",
  };

  // set Initial value of All Shortage entry form
  const [isEditFormValuePatched, setIsEditFormValuePatched] = React.useState<string>("");
  const initValue: any = InitAllShortageFormValue;
  initValue["reporting"] = UserTerminal();

  const [initialFormValue, setInitialFormValue] = React.useState<any>(initValue);

  // Initialize All shortage entry form using formik and pass validation schema and initial values
  // handle all form event and submit form using formik

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    setFieldValue,
    resetForm,
    isSubmitting
  } = useFormik({
    validationSchema: ShortageEntryNBSchema,
    validateOnChange: false,
    initialValues: initValue,

    // Submit the form if all form fields are validated
    onSubmit: (values) => {
      if ((!editFormValueChanged && osdNumber)) return;
      if (proCheckDError) {
        document.getElementsByName("proNumber")[0].focus();
      }
      if (proCheckDError) return;
      setPageLoading(true);
      const reqInput = JSON.parse(JSON.stringify(values));
      const deleteFields = [
        "trailerOrBay",
        "origin",
        "destination",
        "shipper",
        "shipperAccount",
        "shipperAddress",
        "shipperCity",
        "shipperState",
        "shipperZip",
        "consignee",
        "consigneeAccount",
        "consigneeAddress",
        "consigneeCity",
        "consigneeState",
        "consigneeZip",
      ];

      if (["1", "2"].includes(reqInput["valueRange"])) {
        deleteFields.push("value1k");
      } else {
        reqInput["value1k"] = parseInt(reqInput["value1k"]);
      }

      reqInput["proNumber"] = parseInt(reqInput["proNumber"]);
      reqInput["weight"] = parseInt(reqInput["weight"]);
      reqInput["pieces"] = parseInt(reqInput["pieces"]);
      reqInput["lastManifest"] = parseInt(reqInput["lastManifest"]);
      if (values.trailerOrBay === "T") {
        reqInput["manifest"] = parseInt(reqInput["manifest"]);
        deleteFields.push("bay");
      } else {
        deleteFields.push("trailer", "manifest");
      }


      deleteFields.forEach((f: string) => {
        delete reqInput[f];
      });
      reqInput["commodity"] = reqInput["commodity"]?.trim();
      reqInput["values"] = reqInput["values"]?.toString();

      if (osdNumber) {
        setPageLoading(true);
        UpdateASEntryAPI(reqInput, osdNumber)
          .then((response: any) => {
            setPageLoading(false);
            setIsSubmitted(true);
            setExpanded("panel2");
            setFormFieldsDisabled(true);
            viewAllShortageEntry();
            setInitialFormValue(values);
            dispatch(
              PopoutNotification(
                NOTIFICATION_TYPE.SUCCESS,
                `OS&D # ${osdNumber}  has been updated successfully`,
                Date.now().toString()
              )
            );
          })
          .catch((err) => {
            setPageLoading(false);
            dispatch(
              PopoutNotification(
                NOTIFICATION_TYPE.ERROR,
                typeof err === "string" ? err : "All Shortage entry updating failed",
                Date.now().toString()
              )
            );
          });
      } else {
        CreateShortageEntryAPI(reqInput)
          .then((response: any) => {
            setPageLoading(false);
            if (response.osdNumber) {
              setInitialFormValue(values);
              setIsSubmitted(true);
              setExpanded("panel2");
              setFormFieldsDisabled(true);
              viewAllShortageEntry(response.osdNumber);
              setOsdNumber(response.osdNumber);
              setEntryUser(response.username);
              dispatch(
                PopoutNotification(
                  NOTIFICATION_TYPE.SUCCESS,
                  `OS&D # ${response.osdNumber}  has been created successfully`,
                  Date.now().toString()
                )
              );
            } else {
              dispatch(
                PopoutNotification(
                  NOTIFICATION_TYPE.ERROR,
                  "All Shortage entry creation failed",
                  Date.now().toString()
                )
              );
            }
          })
          .catch((err: any) => {
            setPageLoading(false);
            dispatch(
              PopoutNotification(
                NOTIFICATION_TYPE.ERROR,
                err || "All Shortage entry creation failed",
                Date.now().toString()
              )
            );
          });
      }
    },
  });

  // get Commodity and package code option master
  const getMasters = (ParamString: string) => {
    if (ParamString === "commodityCodeOptions") {
      commodityTypeMasterApi()
        .then((results) => {
          const sortedData = results.data
            .sort((a: IFilterDropdown, b: IFilterDropdown) => parseInt(a?.code!) - parseInt(b?.code!))
            .map((a: IFilterDropdown) => {
              a.code = a?.code?.trim();
              return a;
            });
          if (values?.commodity) {
            updateAutocompleteValue("cType", sortedData.filter((d: IFilterDropdown) => d?.code?.trim() === values?.commodity)[0]);
          }
          dispatch(saveFilterCommodityTypeData(sortedData));
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (ParamString === "packageCode") {
      let params: { dataType: string } = {
        dataType: ParamString,
      };
      getFilterDropdowns(params)
        .then((results: IFilterDropdown[]) => {
          dispatch(savePackageOptionMaster(results));
        })
        .catch((error: any) => {
          console.error(error);
        });
    } else if (ParamString === "terminalMaster") {
      getTerminals()
        .then((results: any) => {
          dispatch(saveTerminalMasterState(SortTerminals(results.data)));
          if (currentTerminalValue.value === "") {
            dispatch(TerminalSelection(results?.data?.find((terminal: TTerminal) => terminal.value === UserTerminal() && terminal.region !== null) ?? results?.data[0]));
          }
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  };

  // Handle accordion expand
  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (
        ["panel2", "panel3"].includes(panel) &&
        isExpanded
        && !isSubmitted
        && !osdNumber
      )
        return;
        if(osdNumber) {
          setFormFieldsDisabled(true);
          reset(EFormAction.edit);
        } else {
          reset(EFormAction.create);
        }
      setExpanded(isExpanded ? panel : false);
    };

  const enableEditing = () => {
    setFormFieldsDisabled(false);
  };

  // validate pro check digit via API if get pro details proD is valid otherwise not valid and show validation message
  const validateProCheckD = async () => {
    if (!values.proNumber) return;

    const pro = _.get(values, "proNumber", "")!.toString().padStart(11, "0");
    try {
      const checkProResponse = await checkPro(pro);
      if (_.isEmpty(checkProResponse)) {
        setProCheckDError(true);
      } else {
        setProCheckDError(false);
      }

      patchProDetails(checkProResponse);
    } catch (error) {
      patchProDetails({});
      setProCheckDError(true);
    }
  };

  // patch pro details value with OV type got the values from proNumber API as above validateProCheckD method
  const patchProDetails = (checkProResponse: ICheckDigitPro) => {
    const fields: Array<keyof ICheckDigitPro> = CheckDigitFields;

    fields.forEach((f: keyof ICheckDigitPro) => {
      setFieldValue(
        f,
        typeof checkProResponse[f] === "string"
          ? checkProResponse[f]?.trim()
          : checkProResponse[f] || ""
      );
    });
  };

  // set form value on exception type changed
  const reset = (action: EFormAction) => {
    setProCheckDError(false)
    document.getElementsByName("reporting")[0].focus();
    resetForm();
    if (action === EFormAction.create) {
      updateAutocompleteValue("cType", undefined);
      updateInputValue("cInputValue", undefined);
    } else {
      resetForm();
      patchEditFormValues(initialFormValue);
    }
  };


  // handle confirmation pop while user want back according button
  const handleDialogOpen = () => {
    if (!formValueChanged) navigate(AppURLs.shortages);
    setDialogOpen(true);
  };

  // Close the confirmation modal pop and navigate user to back All Shortage listing page if user confirmed
  const handleDialogClose = (flag?: string) => {
    setDialogOpen(false);
    if (flag === "yes") {
      navigate(AppURLs.shortages);
    }
  };

  const patchEditFormValues = (allShortage: any) => {
    const fields = Object.keys(InitAllShortageFormValue);
    fields.forEach((field: string) => {
      const val = typeof allShortage[field] === "string"
        ? allShortage[field]?.trim() || ""
        : allShortage[field];
      setFieldValue(
        field,
        val || ""
      )
    });

    // commodityCodeOptions
    if (commodityCodeOptions && commodityCodeOptions.length > 0 && allShortage?.commodity) {
      updateAutocompleteValue("cType", commodityCodeOptions.find((d: any) => d?.code?.trim() === allShortage?.commodity));
      updateInputValue("cInputValue", allShortage?.commodity);
    }
    setIsEditFormValuePatched(Date.now().toString());
  }

  const viewAllShortageEntry = (osdId?: number | string ) => {
    if (params?.osdNumber || osdNumber || osdId) {
      // get AS entry and set form fields
      setPageLoading(true);
      GetASDetailsAPI(params?.osdNumber || osdNumber || osdId!)
        .then((allShortage: IShortageEntryDetails) => {

           const { 
            createdDate,
            lastUpdatedDate,
            createdTime,
            lastUpdatedTime,
           } = allShortage; 

            const date = createdDate &&
            createdDate! > lastUpdatedDate!
            ? createdDate
            : lastUpdatedDate;
            const time =  createdDate &&
            createdTime &&
            (createdDate! > lastUpdatedDate! ||
              (createdDate ===
                lastUpdatedDate &&
                createdTime! >
                  lastUpdatedTime!))
            ? createdTime
            : lastUpdatedTime
           
            if (date && time) {
              setUpdatedDateTime(DateHelper.mergerAS400DateTime(date, time));
            }
          setOsdDetails(allShortage);
          setInvestigationStatus(allShortage?.investigationStatusNumber ||  EShortageInvestigationStatus.exceptionEntry)
          patchEditFormValues(allShortage);
          setPageLoading(false);
        })
        .catch((err: any) => {
          console.error(err);
          setPageLoading(false);
        });
    }
  };

    // Declare a new state variable for tooltips with a default empty object
    const [openTooltips, setOpenTooltips] = useState<TooltipState>({});

    // Function to handle tooltip close
    const handleTooltipClose = (id: string) => {
      setOpenTooltips((prev) => ({ ...prev, [id]: false }));
    };
  
    // Function to handle tooltip open
    const handleTooltipOpen = (id: string) => {
      setOpenTooltips((prev) => ({ ...prev, [id]: true }));
    };


  useEffect(() => {
    if (commodityCodeOptions && commodityCodeOptions.length > 0 && osdDetails?.commodity) {
      updateAutocompleteValue("cType", commodityCodeOptions.find((d: any) => d?.code?.trim() === osdDetails?.commodity));
      updateInputValue("cInputValue", osdDetails?.commodity)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commodityCodeOptions, osdDetails]);

  useEffect(() => {
    if (params?.osdNumber && isEditFormValuePatched) {
      setInitialFormValue(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditFormValuePatched]);

  useEffect(() => {
    setEditFormValueChanged(!_.isEqual(values, initialFormValue));
    dispatch(isFormValueChanged(!_.isEqual(values, initialFormValue)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, initialFormValue]);

  useEffect(() => {
    dispatch(isFormValueChanged(false));
    if (params?.osdNumber) {
      viewAllShortageEntry();
    }
    if (commodityCodeOptions === null || commodityCodeOptions.length === 0) {
      getMasters("commodityCodeOptions");
    }
    if (packageCodeOptions === null || packageCodeOptions.length === 0) {
      getMasters("packageCode");
    }
    if (terminalOptions === null || terminalOptions.length === 0) {
      getMasters("terminalMaster");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (isSubmitting) return;
    if (Object.keys(errors).length > 0) {
      document.getElementsByName(Object.keys(errors)[0])[0].focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  return (
    <div className="bg-layout overage-entry">
      {/* header */}
      <div className="header-section">
        <div className="back-section">
          <div className="back" onClick={handleDialogOpen}>
            <ArrowLeftIcon />
          </div>
          <div className="page-hader-content">
            <div className="page-header">Shortage Entry</div>
            {(params?.osdNumber || isSubmitted) && (
              <div className="page-subtitle">
                Last update: &nbsp;
                { updatedDateTime ? updatedDateTime : "NA" }
              </div>
            )}
          </div>
          {!!osdNumber && <span className="badge">#{osdNumber}</span>}
        </div>
        <div className="edit-section"></div>
      </div>
      <CustomDialog open={dialogOpen} onClose={handleDialogClose} />

      {/* body */}
      <div className="content-area">
        <Accordion
          className="accordion"
          expanded={expanded === "panel1"}
          onChange={handleAccordionChange("panel1")}
        >
          <AccordionSummary
            className="header"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              className={
                isSubmitted || osdNumber
                  ? "queue-check queue-check-gc"
                  : "queue-check"
              }
            >
              1
            </Typography>
            <Typography className="accordion-header">Shortage Entry</Typography>
          </AccordionSummary>
          <form action="" onSubmit={handleSubmit}>
            <AccordionDetails className="accordion-container">
              <div id="overageEntry">
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true} id="type">
                        Type <sup>&nbsp;</sup>
                      </InputLabel>
                      <Select
                        disabled={true}
                        variant="outlined"
                        id="date"
                        tabIndex={0}
                        autoFocus
                        placeholder="Select a Date"
                        className="select"
                        fullWidth
                        name="type"
                        value={values?.type}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        style={selectStyle}
                      >
                        <MenuItem style={selectStyle} value="AS">
                          AS
                        </MenuItem>
                        <MenuItem style={selectStyle} value="BNF">
                          BNF
                        </MenuItem>
                        <MenuItem style={selectStyle} value="DS">
                          DS
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true} id="type">
                        Reporting Terminal <sup>*</sup>
                      </InputLabel>
                      <Autocomplete
                        disablePortal
                        disabled={!!(osdNumber || isSubmitted)}
                        className="select custom-autocomplete"
                        id="reporting-terminal"
                        size="small"
                        disableClearable={true}
                        value={values?.reporting}
                        options={terminalOptions}
                        getOptionLabel={(option) => option || ""}
                        onChange={(e, value) => setFieldValue("reporting", value)}
                        noOptionsText={"Records not found"}
                        style={selectStyle}
                        // popupIcon={<KeyboardArrowDownIcon className="icon-style" />}
                        popupIcon={
                          <div className="dropdown-icon-group">
                            <ArrowDropUpIcon className="icon-style" />{" "}
                            <ArrowDropDownIcon className="icon-style" />{" "}
                          </div>
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Reporting Terminal"
                            variant="outlined"
                            className="dropdown-list"
                            name="reporting"
                            style={selectStyle}
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              style: { fontSize: "0.9rem" },
                            }}
                          />
                        )}
                      />
                    </FormControl>{" "}
                  </Grid>
                </Grid>
                <div>
                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="checkDigit"
                        >
                          PRO# <sup>*</sup>
                        </InputLabel>
                        <TextField
                          id="checkDigit"
                          placeholder="Enter Pro Number"
                          variant="outlined"
                          onChange={handleChange}
                          error={!!((errors.proNumber && touched.proNumber) || proCheckDError)}
                          disabled={formFieldsDisabled}
                          onBlur={(e) => {
                            validateProCheckD();
                            handleBlur(e);
                          }}
                          value={values.proNumber}
                          name="proNumber"
                          type="number"
                          onKeyDown={HandleKeyDown}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            HandleNumberMaxLength(e, 11);
                          }}
                        />
                      </FormControl>
                      {errors.proNumber && touched.proNumber ? (
                        <span className="form-error">
                          {errors.proNumber.toString()}
                        </span>
                      ) : null}

                      {!(errors.proNumber && touched.proNumber) && proCheckDError ? (
                        <span className="form-error">Enter valid Pro Number</span>
                      ) : null}
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="origin"
                        >
                          Origin Terminal <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          id="origin"
                          value={values.origin}
                          name="origin"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} id=" ">
                          Destination Terminal <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          id=" "
                          value={values.destination}
                          name="destination"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} id=" ">
                          Shipper Account # <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          id=" "
                          value={values.shipperAccount}
                          name="shipperAccount"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} id=" ">
                          Shipper Name <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          id=" "
                          value={values.shipper}
                          name="shipper"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} id=" ">
                          Shipper Address <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          id=" "
                          value={values.shipperAddress}
                          name="shipperAddress"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="shipperZip"
                        >
                          Shipper Zip
                        </InputLabel>
                        <TextField
                          id=" "
                          value={values.shipperZip}
                          name="shipperZip"
                          disabled
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="shipperCity"
                        >
                          Shipper City
                        </InputLabel>
                        <TextField
                          id=" "
                          value={values.shipperCity}
                          name="shipperCity"
                          disabled
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="shipperState"
                        >
                          Shipper State
                        </InputLabel>
                        <TextField
                          id=" "
                          value={values.shipperState}
                          name="shipperState"
                          disabled
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} id=" ">
                          Consignee Account # <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          id=" "
                          value={values.consigneeAccount}
                          name="consigneeAccount"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} id=" ">
                          Consignee Name <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          id=" "
                          value={values.consignee}
                          name="consignee"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} id=" ">
                          Consignee Address <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          id=" "
                          value={values.consigneeAddress}
                          name="consigneeAddress"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="consigneeZip"
                        >
                          Consignee Zip
                        </InputLabel>
                        <TextField
                          id=" "
                          value={values.consigneeZip}
                          name="consigneeZip"
                          disabled
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="consigneeCity"
                        >
                          Consignee City
                        </InputLabel>
                        <TextField
                          id=" "
                          value={values.consigneeCity}
                          name="consigneeCity"
                          disabled
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="consigneeState"
                        >
                          Consignee State
                        </InputLabel>
                        <TextField
                          id=" "
                          value={values.consigneeState}
                          name="consigneeState"
                          disabled
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>

                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true} id=" ">
                        Last Manifest Number # <sup>*</sup>
                      </InputLabel>
                      <TextField
                        id="lastManifest"
                        type="number"
                        placeholder="Enter Last Manifest Number #"
                        variant="outlined"
                        name="lastManifest"
                        value={values.lastManifest}
                        onChange={handleChange}
                        error={!!(errors.lastManifest && touched.lastManifest)}
                        disabled={formFieldsDisabled}
                        onBlur={handleBlur}
                        onKeyDown={HandleKeyDown}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          HandleNumberMaxLength(e, 9);
                        }}
                      />
                    </FormControl>
                    {errors.lastManifest && touched.lastManifest ? (
                      <span className="form-error">
                        {errors.lastManifest.toString()}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <FormControl className="min-width checkbox-group">
                      <FormLabel id="shipmentLocation">Noted Off <sup>*</sup></FormLabel>
                      <RadioGroup className="form-row">
                        <Grid container spacing={2} className='mb-0'>
                          <Grid item md={6} xs={12} className="p10 mb-0">
                            <Grid container spacing={2} className='mb-0'>
                              <Grid item md={4} xs={12} className="p10 pt-5">
                                <FormControlLabel
                                  value="T"
                                  control={<Radio />}
                                  label="Trailer"
                                  name="trailerOrBay"
                                  onChange={handleChange}
                                  disabled={formFieldsDisabled}
                                  onBlur={handleBlur}
                                  className="full-width"
                                  checked={values.trailerOrBay === "T"}
                                />
                              </Grid>
                              <Grid item md={8} xs={12} className="p10 pt-5">
                                {values.trailerOrBay === "T" && (
                                  <FormGroup className="form-validation">

                                    <Grid container spacing={2} className='mb-0'>
                                      <Grid item md={6} xs={12} className="pt-0">
                                        <TextField
                                          className="justify-content"
                                          id="outlined-basic"
                                          placeholder="Enter Trailer No."
                                          variant="outlined"
                                          name="trailer"
                                          key={values.trailerOrBay}
                                          onChange={handleChange}
                                          error={!!(errors.trailer && touched.trailer)}
                                          disabled={formFieldsDisabled}
                                          onBlur={handleBlur}
                                          value={values.trailer}
                                          inputProps={{ maxLength: 15 }}
                                        />
                                      </Grid>

                                      <Grid item md={6} xs={12} className="pt-0">
                                         <TextField
                                          id="manifest-number"
                                          type="number"
                                          placeholder="Enter Manifest No."
                                          variant="outlined"
                                          name="manifest"
                                          value={values.manifest}
                                          onChange={handleChange}
                                          error={!!(errors.manifest && touched.manifest)}
                                          disabled={formFieldsDisabled}
                                          onBlur={handleBlur}
                                          onKeyDown={HandleKeyDown}
                                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            HandleNumberMaxLength(e, 9);
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </FormGroup>
                                )}
                              </Grid>
                            </Grid>

                          </Grid>
                          <Grid item md={6} xs={12} className="p10 mb-0">
                            <Grid container spacing={2} className='mb-0'>
                              <Grid item md={4} xs={12} className="p10 pt-5">
                                <FormControlLabel
                                  value="B"
                                  control={<Radio />}
                                  label="Bay/Dock"
                                  name="trailerOrBay"
                                  onChange={handleChange}
                                  disabled={formFieldsDisabled}
                                  onBlur={handleBlur}
                                  checked={values.trailerOrBay === "B"}
                                  className='full-width'
                                />
                              </Grid>
                              <Grid item md={8} xs={12} className="p10 pt-5">
                                {values.trailerOrBay === "B" && (
                                  <>
                                    <FormGroup className="form-validation">

                                      <Grid container spacing={2} className='mb-0'>
                                        <Grid item md={6} xs={12} className="pt-0">
                                          <TextField
                                            className="justify-content"
                                            id="outlined-basic"
                                            placeholder="Enter Bay/Dock No."
                                            variant="outlined"
                                            onChange={handleChange}
                                            error={!!(errors.bay && touched.bay)}
                                            disabled={formFieldsDisabled}
                                            onBlur={handleBlur}
                                            name="bay"
                                            value={values.bay}
                                            inputProps={{ maxLength: 4 }}
                                          />
                                        </Grid>
                                      </Grid>
                                    </FormGroup>
                                    {errors.bay && touched.bay ? (
                                      <span className="form-error">{errors.bay.toString()}</span>
                                    ) : null}
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                    {values.trailerOrBay === "T" && <>

                      {errors.trailer && touched.trailer ? (
                        <span className="form-error trailer">
                          {errors.trailer.toString()}
                        </span>
                      ) : null}
                      {errors.manifest && touched.manifest ? (
                        <span className="form-error manifest">
                          {errors.manifest.toString()}
                        </span>
                      ) : null}
                    </>}
                    {errors.trailerOrBay && touched.trailerOrBay ? (
                      <span className="form-error checkbox-group-validation">
                        {errors.trailerOrBay.toString()}
                      </span>
                    ) : null}
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={3} xs={12}>
                    <FormControl
                      style={{ minWidth: 120 }}
                      fullWidth
                      size="small"
                    >
                      <InputLabel
                        style={selectStyle}
                        shrink={true}
                        id="commodity"
                      >
                        Commodity <sup>*</sup>
                      </InputLabel>
                      <Autocomplete
                        disabled={formFieldsDisabled}
                        className="custom-autocomplete"
                        noOptionsText="Records not found"
                        disablePortal
                        getOptionLabel={(option) => `${option.code} -  ${option.displayValue}`}
                        value={autocompleteValues.cType}
                        key={autocompleteValues.cType?.code}
                        inputValue={inputValues.cInputValue}
                        onClose={() => {
                          updateInputValue("cInputValue", "");
                        }}
                        onInputChange={(e, v) => onAutoInputChange(v)}
                        forcePopupIcon={false}
                        options={commodityCodeOptions}
                        onChange={(e, v) => handleAutoCompleteOnChange(v)}
                        disableClearable={true}
                        renderOption={(props, option) => (
                          <li {...props} title={`${option.code?.trim()} - ${option.displayValue.trim()}`}>
                            {option.code?.trim()} - {option.displayValue.trim()}
                          </li>
                        )}

                        filterOptions={(options, { inputValue }) =>
                          options.filter(option =>
                            option.displayValue.concat(" ", option?.code ?? "")?.toLowerCase().includes(inputValue?.toLowerCase())
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            name="commodity"
                            placeholder="Enter & Select Commodity"
                            onBlur={handleBlur}
                            error={!!(errors.commodity && touched.commodity)}
                            {...params} variant="outlined" />
                        )}
                        renderTags={(value) => (
                          <div className="auto-complete-selected-container" title={value.join(", ")}>
                            {value.join(", ")}
                          </div>
                        )}
                      />
                    </FormControl>
                    {errors.commodity && touched.commodity ? (
                      <span className="form-error">
                        {errors.commodity.toString()}
                      </span>
                    ) : null}
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel
                        style={selectStyle}
                        shrink={true}
                        id="package"
                      >
                        Package Type <sup>*</sup>
                      </InputLabel>
                      <Select
                        variant="outlined"
                        id="package"
                        className="select"
                        fullWidth
                        onChange={handleChange}
                        error={!!(errors.package && touched.package)}
                        disabled={formFieldsDisabled}
                        onBlur={handleBlur}
                        name="package"
                        value={values.package}
                        style={selectStyle}
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Select Package Type</em>;
                          }
                          return selected;
                        }}
                        IconComponent={() => (
                          <div className="dropdown-icon-group">
                            <ArrowDropUpIcon className="icon-style" />{" "}
                            <ArrowDropDownIcon className="icon-style" />{" "}
                          </div>
                        )}
                      >
                        {packageCodeOptions.map((obj: any) => (
                          <MenuItem
                            style={selectStyle}
                            key={obj?.actualValue}
                            value={obj?.actualValue}
                          >
                            {obj?.actualValue}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.package && touched.package ? (
                      <span className="form-error">
                        {errors.package.toString()}
                      </span>
                    ) : null}
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true} id="pieces">
                        Total Pieces <sup>*</sup>
                      </InputLabel>
                      <TextField
                        id="outlined-basic"
                        placeholder="Enter Pieces"
                        type="number"
                        variant="outlined"
                        onChange={handleChange}
                        error={!!(errors.pieces && touched.pieces)}
                        disabled={formFieldsDisabled}
                        onBlur={handleBlur}
                        name="pieces"
                        value={values.pieces}
                        onKeyDown={HandleKeyDown}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          HandleNumberMaxLength(e, 5);
                        }}
                      />
                    </FormControl>
                    {errors.pieces && touched.pieces ? (
                      <span className="form-error">
                        {errors.pieces.toString()}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true} id="weight">
                        Weight <sup>*</sup>
                      </InputLabel>
                      <TextField
                        type="number"
                        id="weight"
                        placeholder="Enter Weight"
                        variant="outlined"
                        onChange={handleChange}
                        error={!!(errors.weight && touched.weight)}
                        disabled={formFieldsDisabled}
                        onBlur={handleBlur}
                        name="weight"
                        value={values.weight}
                        onKeyDown={HandleKeyDown}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          HandleNumberMaxLength(e, 7);
                        }}
                      />
                    </FormControl>
                    {errors.weight && touched.weight ? (
                      <span className="form-error">
                        {errors.weight.toString()}
                      </span>
                    ) : null}
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="filter-margin">


                  <Grid item md={4} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel
                        style={selectStyle}
                        shrink={true}
                        id="consigneeAddress"
                      >
                        Does the shipment have (Item#, Carton#, Model#, Serial#) <sup>*</sup>
                      </InputLabel>
                      <TextField
                        inputProps={{ maxLength: 56 }}
                        id="outlined-basic"
                        placeholder="Enter Item#, Carton#, Model#, Serial# with comma separated"
                        variant="outlined"
                        onChange={handleChange}
                        error={
                          !!(errors.itemCartonModelSerialNumber &&
                            touched.itemCartonModelSerialNumber)
                        }
                        disabled={formFieldsDisabled}
                        onBlur={handleBlur}
                        name="itemCartonModelSerialNumber"
                        value={values.itemCartonModelSerialNumber}
                      />
                    </FormControl>
                    {errors.itemCartonModelSerialNumber && touched.itemCartonModelSerialNumber ? (
                      <span className="form-error">
                        {errors.itemCartonModelSerialNumber.toString()}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <FormControl className="radio-group value-range">
                      <FormLabel id="valueRange">
                        Value Range <sup>*</sup>
                      </FormLabel>

                      <Grid container spacing={2}>
                        <Grid
                          item
                          md={12}
                          xs={12}
                          className="float-div form-content-start"
                        >
                          <FormGroup className="shift-left">
                            <RadioGroup
                              row
                              aria-labelledby="valueRange"
                              name="valueRange"
                              value={values.valueRange}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <FormControlLabel
                                value="1"
                                disabled={!!(osdNumber || isSubmitted)}
                                control={<Radio />}
                                label="$0 - $250.00"
                                name="valueRange"
                                onChange={handleChange}
                                checked={values.valueRange === "1"}
                              />
                              <FormControlLabel
                                value="2"
                                disabled={!!(osdNumber || isSubmitted)}
                                control={<Radio />}
                                label="$250.00 - $1,000.00"
                                name="valueRange"
                                onChange={handleChange}
                                checked={values.valueRange === "2"}
                              />
                              <FormControlLabel
                                disabled={!!(osdNumber || isSubmitted)}
                                value="3"
                                control={<Radio />}
                                label="Greater than $1,000.00"
                                name="valueRange"
                                onChange={handleChange}
                                checked={values.valueRange === "3"}
                              />
                              {['day1'].map((id) => (
                                <ClickAwayListener key={id} onClickAway={() => handleTooltipClose(id)}>
                                  <Tooltip
                                    PopperProps={{
                                    disablePortal: true,
                                    }}
                                    onClose={() => handleTooltipClose(id)}
                                    open={openTooltips[id] || false}
                                    disableFocusListener 
                                    disableTouchListener
                                    title="Round the value up to the nearest dollar amount"
                                  > 
                                    <InfoIcon
                                      className="info-icon round-info" 
                                      onMouseEnter={() => handleTooltipOpen(id)}
                                      onMouseLeave={() => handleTooltipClose(id)}
                                    />
                                  </Tooltip>
                                </ClickAwayListener>
                              ))}
                            </RadioGroup>
                          </FormGroup>

                          {values.valueRange === "3" && (
                            <FormGroup className="no-wrap">
                              <FormGroup className="form-row shift-left">
                                <TextField
                                  className="justify-content disabled"
                                  id=" "
                                  value="$"
                                  variant="outlined"
                                  disabled
                                />
                                <TextField
                                  type="number"
                                  className="justify-content"
                                  id=" "
                                  placeholder="Enter Amount"
                                  variant="outlined"
                                  onChange={handleChange}
                                  error={!!(errors.value1k && touched.value1k)}
                                  disabled={!!(osdNumber || isSubmitted)}
                                  onBlur={handleBlur}
                                  name="value1k"
                                  value={values.value1k}
                                  onKeyDown={HandleKeyDown}
                                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    HandleNumberMaxLength(e, 10);
                                  }}
                                />
                              </FormGroup>
                              {errors.value1k && touched.value1k ? (
                                <span className="form-error">
                                  {errors.value1k.toString()}
                                </span>
                              ) : null}
                            </FormGroup>
                          )}
                        </Grid>
                        {errors.valueRange && touched.valueRange ? (
                          <span className="form-error radio-group-validation">
                            {errors.valueRange.toString()}
                          </span>
                        ) : null}
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
                <h3 className="accordion-content-header">Additional Details</h3>

                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel
                        style={selectStyle}
                        shrink={true}
                        id="dimension"
                      >
                        Exact Dimensions/Measurements <sup>*</sup>
                      </InputLabel>
                      <TextField
                        id="dimension"
                        placeholder="Enter Dimensions/Measurements"
                        variant="outlined"
                        onChange={handleChange}
                        error={!!(errors.dimension && touched.dimension)}
                        name="dimension"
                        disabled={formFieldsDisabled}
                        onBlur={handleBlur}
                        value={values.dimension}
                        inputProps={{ maxLength: 75 }}
                      />
                    </FormControl>
                    {errors.dimension && touched.dimension ? (
                      <span className="form-error">
                        {errors.dimension.toString()}
                      </span>
                    ) : null}
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel
                        style={selectStyle}
                        shrink={true}
                        id="packagingDescription"
                      >
                        Packaging  Description <sup>*</sup>
                      </InputLabel>
                      <TextField
                        id="packagingDescription"
                        placeholder="Packaging  Description"
                        variant="outlined"
                        onChange={handleChange}
                        error={!!(errors.packagingDescription && touched.packagingDescription)}
                        disabled={formFieldsDisabled}
                        onBlur={handleBlur}
                        name="packagingDescription"
                        value={values.packagingDescription}
                        inputProps={{ maxLength: 75 }}
                      />
                    </FormControl>
                    {errors.packagingDescription && touched.packagingDescription ? (
                      <span className="form-error">
                        {errors.packagingDescription.toString()}
                      </span>
                    ) : null}
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel
                        style={selectStyle}
                        shrink={true}
                        id="productDescription"
                      >
                        Product Description <sup>*</sup>
                      </InputLabel>
                      <TextField
                        id="productDescription"
                        placeholder="Enter Product Description"
                        variant="outlined"
                        onChange={handleChange}
                        error={!!(errors.productDescription && touched.productDescription)}
                        disabled={formFieldsDisabled}
                        onBlur={handleBlur}
                        name="productDescription"
                        value={values.productDescription}
                        inputProps={{ maxLength: 75 }}
                      />
                    </FormControl>
                    {errors.productDescription && touched.productDescription ? (
                      <span className="form-error">
                        {errors.productDescription.toString()}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel
                        style={selectStyle}
                        shrink={true}
                        id="agentInterline"
                      >
                        Agent/Interline Info And/Or Pro <sup>&nbsp;</sup>
                      </InputLabel>
                      <TextField
                        id="agentInterline"
                        placeholder="Enter Agent/Interline Info And/Or Pro"
                        variant="outlined"
                        onChange={handleChange}
                        disabled={formFieldsDisabled}
                        name="agentInterline"
                        value={values.agentInterline}
                        inputProps={{ maxLength: 75 }}
                      />
                    </FormControl>
                    {errors.agentInterline && touched.agentInterline ? (
                      <span className="form-error">
                        {errors.agentInterline.toString()}
                      </span>
                    ) : null}
                  </Grid>

                </Grid>

                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel
                        style={selectStyle}
                        shrink={true}
                        id="description"
                      >
                        Description <sup>*</sup>
                      </InputLabel>
                      <TextField
                        id="description"
                        placeholder="Enter Additional Description"
                        variant="outlined"
                        onChange={handleChange}
                        error={!!(errors.additionalDescription && touched.additionalDescription)}
                        disabled={formFieldsDisabled}
                        onBlur={handleBlur}
                        name="additionalDescription"
                        value={values.additionalDescription}
                        inputProps={{ maxLength: 425 }}
                      />
                    </FormControl>
                    {errors.additionalDescription && touched.additionalDescription ? (
                      <span className="form-error">
                        {errors.additionalDescription.toString()}
                      </span>
                    ) : null}
                  </Grid>
                </Grid>
              </div>
              <div className="btn-group-submit">
                {!!osdNumber && !formFieldsDisabled && (
                  <>
                    <Button
                      type="button"
                      variant="contained"
                      className="clear-btn mr-0 ml"
                      onClick={() => reset(EFormAction.edit)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      className={
                        editFormValueChanged
                          ? "assign mr-0 ml"
                          : "disable-btn disable assign mr-0 ml"
                      }
                    >
                      Update
                    </Button>
                  </>
                )}
                {!osdNumber && !isSubmitted && (
                  <>
                    <Button
                      type="button"
                      variant="contained"
                      className="clear-btn mr-0 ml"
                      onClick={() => reset(EFormAction.create)}
                    >
                      Clear
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      className={
                        isSubmitted
                          ? "disable-btn disable entry ml"
                          : "entry ml"
                      }
                    >
                      {values.valueRange === "3" ? "Submit & Send APB" : "Submit"}
                    </Button>
                  </>

                )}
                {!!osdNumber && formFieldsDisabled && !(investigationStatus === EShortageInvestigationStatus.complete) && (
                  <Button
                    // type="submit"
                    variant="contained"
                    className="assign mr-0 ml"
                    onClick={enableEditing}
                  >
                    Edit
                  </Button>
                )}
              </div>
            </AccordionDetails>
          </form>
        </Accordion>

        <Accordion
          className="accordion"
          expanded={expanded === "panel2"}
          onChange={handleAccordionChange("panel2")}
        >
          <AccordionSummary
            className="header"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography
              className={
                investigationStatus === EShortageInvestigationStatus.complete
                  ? "queue-check queue-check-gc"
                  : "queue-check"
              }
            >
              2
            </Typography>
            <Typography className="accordion-header">All Short Investigation &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
            <Typography className="header-notice note-scroll">
              <div className="animate">Do not request camera check prior to reaching that stage of the investigation</div>
            </Typography>

          </AccordionSummary>

          <ShortageInvestigation 
            osdNumber={osdNumber}
            expanded={expanded}
            exceptionType={values.type}
            proNumber={values?.proNumber}
            isEditMode={!!params?.osdNumber}
            status={investigationStatus || EShortageInvestigationStatus.exceptionEntry}
            setInvestigationStatus={setInvestigationStatus}
            investigationDetails={osdDetails}
          />
        </Accordion>
        <Accordion
          className="accordion"
          expanded={expanded === "panel3"}
          onChange={handleAccordionChange("panel3")} 
        >
          <AccordionSummary
            className="header"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography 
            className="queue-check"
            >3</Typography>
            <Typography className="accordion-header">
              Upload Documents
            </Typography> 
          </AccordionSummary>
          <UploadDocument   key={expanded || "upload-document"}
            osdNumber={osdNumber}
            uploadKey={EUploadKey.pro}
          />
        </Accordion>
      </div>
      <Loader pageLoader={pageLoading}></Loader>
    </div>
  );
}

export default AllShortageEntry;
