import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useSelector } from "react-redux";
import { AppState } from "../../AppState/appState";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface State extends SnackbarOrigin {
  open: boolean;
}

const Notification = () => {
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: "bottom",
    horizontal: "right",
  });
  const { vertical, open } = state;
  const notificationType = useSelector(
    (state: AppState) => state.notificationType
  );
  const message = useSelector((state: AppState) => state.message);
  const notificationTime = useSelector((state: AppState) => state.notificationTime);
  const notificationChange = useSelector(
    (state: AppState) => state.notificationChange
  );

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, open: false });
  };

  React.useEffect(() => {
    if (message !== "") {
      setState({ ...state, open: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationType, message, notificationChange, notificationTime]);

  return (
    <div>
      {message ? (
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{ vertical, horizontal: 'left' }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={notificationType}
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        </Stack>
      ) : (
        ""
      )}
    </div>
  );
};

export default Notification;
