import axios from "axios";
import * as _ from "lodash";

import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";
import { IAuthUser } from "../models/user";

/**
 * GET API call using Axios
 * @param cancelToken - Token to cancel multiple requests
 * @returns - Logged in User details type of IAuthUser
 */

export const validateOktaToken = async (): Promise<IAuthUser> => {
  try {
    const user = JSON.parse(localStorage.getItem("okta-token-storage")!);
    const response = await axios.post(
      getUrl(ApiType.VALIDATE_OKTA_TOKEN),
      { token: _.get(user, "idToken.idToken"), vendor: "octa" },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    let message = ConstantMessage.validateOktaToken;
    if (_.get(error, "response.status") === 500) {
      message = ConstantMessage.serverError;
    }
    throw message;
  }
};
