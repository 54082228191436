import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
} from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import noOpMiddleware from "./noOpMiddleware";
import { AppState, DEFAULT_APP_STATE } from "./appState";
import rootReducer from "./rootReducer";
import loggingMiddleware from "./loggingMiddleware";

const isDevEnv =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const logger = isDevEnv ? loggingMiddleware : noOpMiddleware;

const persistConfig = {
  key: "root",
  storage: storage,
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const enhancers = compose(
  applyMiddleware(
    logger // Keep first to log all incoming actions unaltered by any subsequent middleware
  )
);

const makeStore = (initialState: AppState) =>
  createStore(persistedReducer, initialState, enhancers);

export const store = makeStore(DEFAULT_APP_STATE);
// Middleware: Redux Persist Persister
export const persistor = persistStore(store);
