import axios from "axios";
import * as _ from "lodash";

import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";

/**
 * GET API call using Axios
 * @param cancelToken - Token to cancel multiple requests
 * @returns - Array of Terminal List of type string[]
 */

export const getMasterProNumberAPI = async (params: {
  search: string,
  page: number,
  limit: number,
  osdType: string,
  proType :string
}): Promise<string[]> => {
  try {
    const response = await axios.get<string[]>(getUrl(ApiType.MASTER_PRO_NUMBER), {
      headers: {
        "Content-Type": "application/json",
      },
      params
    });
    return response.data;
  } catch (error) {
    let message = ConstantMessage.masterOsdNumber;
    if (_.get(error, "response.status") === 500) {
      message = ConstantMessage.serverError;
    }
    throw message;
  }
};
