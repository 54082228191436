import React, { useEffect, useState } from "react";
import "./DynamicGrid.css";

import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import {
  DataGrid,
  GridColDef,
  gridClasses,
  GridSortDirection,
  GridFeatureMode,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import PhotoLibraryOutlinedIcon from "@mui/icons-material/PhotoLibraryOutlined";
import LaunchIcon from "@mui/icons-material/Launch";
import Button from "@mui/material/Button";
import { Stack, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppState from "../../AppState";
import { APP, AppURLs, ETab, TGridSortModel, TPageObject } from "../../constants/app";
import { IProImage } from "../../models/master";
import { PopoutNotification } from "../../AppState/popoutNotification";
import NOTIFICATION_TYPE from "../../AppState/NotificationType";
import { getProImages } from "../../API/getProImages";
import AssignTo from "../assignTo/AssignTo";
import Loader from "../loader/loader";
import DialogImage from "../popupImage/popupImage";
import {
  saveMyAssignmentPageObject,
  saveMyAssignmentSortObject,
  saveOverageListPageObject,
  saveOverageListSortObject,
  saveShortagesPageObject,
  saveShortagesSortObject,
} from "../../AppState/DataTableAction";
import _ from "lodash";
import { IFilterForm, TTerminal } from "../../AppState/overagesFilterFormActions";
import { EManifestType } from "../../models/manifest";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

/// grid props
type Props = {
  rows?: any[];
  columns?: GridColDef[];
  rowCount?: number;
  disableColumnMenu?: boolean;
  checkboxSelection?: boolean;
  hideFooter?: boolean;
  id?: string;
  sortingOrder?: GridSortDirection[];
  sortingMode?: GridFeatureMode;
  paginationMode?: GridFeatureMode;
  rowHeight?: number;
  loading?: boolean;
  noRecordsFound?: string;
  columnVisibilityModel?: any;
  tab?: ETab;
  onSuccessFullyAssigned?: () => void;
};

const Grid: React.FC<Props> = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [proImages, setProImages] = useState<IProImage[]>([]);
  const [pageLoading, setPageLoading] = React.useState<boolean>(false);
  const [exceptionNumber, setExceptionNumber] = React.useState<string>("");
  const [isOpenAssignPop, setIsOpenAssignPop] = React.useState<boolean>(false);
  const [selectedOSDIds, setSelectedOSDIds] = useState<GridRowSelectionModel>(
    []
  );
  const [keepNonExistentRowsSelected, setKeepNonExistentRowsSelected] =
    useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const tabPosition = useSelector((state: AppState) => state.tabPosition);
  const pageObject: GridPaginationModel | undefined = useSelector(
    (state: AppState) => {
    /* tabPosition ETab.overages = 1 is for Overages, ETab.myAssignments = 2 is for My Assignment
     AND ETab.shortages = 3 for Shortages **/
      if (props.tab === ETab.overages) {
        return state.overageListPageObject;
      } else if (props.tab === ETab.myAssignments) {
        return state.myAssignmentPageObject;
      } else {
        return state.shortagesPageObject
      }
    }
  );
  const currentTerminalValue: TTerminal = useSelector(
    (state: AppState) => state.activeTerminal
  );
  const filterParams: IFilterForm = useSelector((state: AppState) => {
    /* tabPosition ETab.overages = 1 is for Overages, ETab.myAssignments = 2 is for My Assignment
     AND ETab.shortages = 3 for Shortages **/
    if (props.tab === ETab.overages) {
      return state.overagesFilterForm;
    } else {
      return state.myAssignmentsFilterForm;
    }
  });
  const heading: { [key: string]: string } = props.tab === ETab.shortages ? APP.shortage.gridHeading
   : APP.overage.gridHeading;
;
  
  
  const rowCols: string[] = Object.keys(heading);
  const rowsPerPageOptions: number[] = APP.overage.rowsPerPageOptions;

  // Configure columns and add manually style and
  const columns: GridColDef[] =
    props?.columns ||
    rowCols.map((ele) => {
      //customized overages grid columns like width, add custom links etc.
      let row: GridColDef = {
        field: ele,
        headerName: heading[ele],
        description: heading[ele],
        type: "string",
        editable: false,
        flex: 1,
        headerAlign: "left",
        align: "left",
        sortable: true,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip title={params.value}>
            <span>{params.value}</span>
          </Tooltip>
        ),
      };
      switch (heading[ele]) {
        case "Assigned to":
        case "Assigned by":
        case "Value Range":
        case "Last Manifest #":
        case "Exception code":
          row["minWidth"] = 120;
        break;
        case "Exception #":
        case "Entry date":
        case "Entry User":
        case "Pro #":
        case "OD400 Pro #":
          row["minWidth"] = 100;
          break;
        case "Origin terminal":
        case "Destination terminal":
        case "Reporting terminal":
          row["minWidth"] = 80;
          break;
        case "Commodity":
        case "Investigation status":
        case "Last confirmed manifest #":
        case "Manifest Short Reported On":
          row["minWidth"] = 155;
          break;
        case "Description":
          row["minWidth"] = 350;
          break;
        case "View":
          row["minWidth"] = 30;
          break;
        case "Value":
        case "Photos":
          row["minWidth"] = 70;
        break;
        case "Trailer #":
          row["minWidth"] = 90;
          break;
        default:
          break;
      }
      
      if (ele === "osdNumber") {
        row["renderCell"] = (params: GridRenderCellParams) => (
          ["BNF", "DS"].indexOf(params.row["osdType"]) === -1 ? (
          <Link to={tabPosition === ETab.shortages ? AppURLs.editASEntry.replace(":osdNumber", params.row["osdNumber"]) : AppURLs.editOverageEntry.replace(":osdNumber", params.row["osdNumber"])}>
            <Tooltip title={params.row[ele]}>
              <span> {params.row[ele]}</span>
            </Tooltip>
          </Link>
        ) : (<span> {params.row[ele]}</span>))
      }
      if (ele === "photos") {
        row["renderCell"] = (params: GridRenderCellParams) => (
          <div>
            <PhotoLibraryOutlinedIcon
              className="blue-color"
              onClick={() => {
                onPhotoOpen(
                  params.row["osdNumber"]
                );
              }}
              to={""}
            />
          </div>
        );
      }
      if ( ["manifest", "lastManifest"].indexOf(ele) > -1) {
        row["renderCell"] = (params: GridRenderCellParams) => 
          params?.row[ele] !== 0 ? (
            <Link to={
              `${AppURLs.manifestDetails.replace(":id", params.row[ele] )}?type=${EManifestType[heading[ele] as keyof typeof EManifestType]}`
              }>
              <Tooltip title={params.row[ele]}>
                <span> {params.row[ele]}</span>
              </Tooltip>
            </Link>
          ) : (
            <span> {params.row[ele]}</span>
          );
      }

      if (ele === "viewDescription") {
        row["renderCell"] = (params: GridRenderCellParams) => (
          ["BNF", "DS"].indexOf(params.row["osdType"]) === -1 ? (
          <Link to={AppURLs.viewDescription.replace(":osdNumber", params.row["osdNumber"])}>
            <LaunchIcon className="blue-color" />
          </Link>
          ) : (null)
        );
      }
      if (APP.overage.sortingDisabledColumns.includes(ele)) {
        row["sortable"] = false;
      }

      return row;
    });
  // const rowSelectable: { key: string; value: string } = APP.overageRowSelectable;

  // set selected row on variables
  const onRowsSelectionHandler = (osdIds: GridRowSelectionModel) => {
    setSelectedOSDIds(osdIds);
  };

  // navigate overage entry page, click on "Entry Overage" button.
  const navigateToOverageEntry = () => {
    if(tabPosition === ETab.shortages) {
      navigate(AppURLs.shortageEntry);
    } else {
      navigate(AppURLs.overageEntry);
    }
  };

  //  open Popup to get and show pro images(view AS400 images for type EL,EP,WP,BP,TK,FX,OD images)
  //  while click on photos action button icon
  const handleOpen = (value?: number | string) => {
    if (!value || value === "0") {
      dispatch(
        PopoutNotification(
          NOTIFICATION_TYPE.WARNING,
          "Selected OSD Number is not valid",
          Date.now().toString()
        )
      );
    } else {
      setPageLoading(true);
      const param = {
        osdNumber: value,
        documentFilter: APP.overage.imageType,
      };
      getProImages(param)
        .then((response) => {
          const sortImages = _.get(response, 'data', []).sort((a: IProImage, b:IProImage) => a.imageFilename!.localeCompare(b.imageFilename!));
          setProImages(sortImages);
          if (response.data.length > 0) {
            setIsOpen(!isOpen);
            setPageLoading(false);
          } else {
            setPageLoading(false);
            dispatch(
              PopoutNotification(
                NOTIFICATION_TYPE.INFO,
                "No Image Available",
                Date.now().toString()
              )
            );
          }
        })
        .catch((error) => {
          setPageLoading(false);
          dispatch(
            PopoutNotification(
              NOTIFICATION_TYPE.ERROR,
              "Image API Error",
              Date.now().toString()
            )
          );
          console.log(error);
        });
    }
  };

  // store the sort object in redux store and update sort object state
  const onColumnSort = (sortObj: TGridSortModel[] | []) => {
    let sObj = JSON.parse(JSON.stringify(APP.overage.sortModel));
    const pageObj: GridPaginationModel = {
      pageSize: _.get(pageObject, "pageSize"),
      page: 0,
    };
    if (sortObj && sortObj.length > 0) {
      sObj = JSON.parse(JSON.stringify(_.first(sortObj)));
    }
    setKeepNonExistentRowsSelected(false);

    /* tabPosition ETab.overages = 1 is for Overages, ETab.myAssignments = 2 is for My Assignment
     AND ETab.shortages = 3 for Shortages **/

    if (tabPosition === ETab.overages) {
      dispatch(saveOverageListSortObject(sObj));
      dispatch(saveOverageListPageObject(pageObj));
    } else if (tabPosition ===  ETab.myAssignments) {
      dispatch(saveMyAssignmentSortObject(sObj));
      dispatch(saveMyAssignmentPageObject(pageObj));
    } else if(tabPosition ===  ETab.shortages) {
      dispatch(saveShortagesSortObject(sObj));
      dispatch(saveShortagesPageObject(pageObj));
    }
  };

  // store the page object in redux store and update page object state
  const onPageChange = (pageObj: TPageObject) => {
    let page: TPageObject = JSON.parse(JSON.stringify(pageObj));
    if (_.get(pageObj, "pageSize") !== _.get(pageObject, "pageSize")) {
      page = { pageSize: _.get(pageObj, "pageSize"), page: 0 };
      setKeepNonExistentRowsSelected(false);
    } else {
      setKeepNonExistentRowsSelected(true);
    }
    // tabPosition ETab.overages = 1 is for Overages, ETab.myAssignments = 2 is for My Assignment
    // AND ETab.shortages = 3 for Shortages
    if (tabPosition === ETab.overages) {
      dispatch(saveOverageListPageObject(page));
    } else if (tabPosition === ETab.myAssignments) {
      dispatch(saveMyAssignmentPageObject(page));
    } else if(tabPosition ===  ETab.shortages) {
      dispatch(saveShortagesPageObject(page));
    }
  };

  // Open modal popup for Assign to other user except to him/her self
  const openAssignTo = () => {
    setIsOpenAssignPop(true);
  };

  //Close Assign to other popup
  const onClosePop = () => {
    setIsOpenAssignPop(false);
  };

  // on click on photo icon from grid set exception number for display on Image popup
  const onPhotoOpen = (osdNumber: string) => {
    setExceptionNumber(osdNumber);
    handleOpen(osdNumber);
  };

  //Close Assign to other popup on successfully assign and deselect row and fetch fresh data and stop loading
  const onSuccessFullyAssigned = () => {
    setSelectedOSDIds([]);
    setIsOpenAssignPop(false);
    setKeepNonExistentRowsSelected(false);
    if(props?.onSuccessFullyAssigned) props.onSuccessFullyAssigned();
  };

  // Deselect existent rows Selected on terminal and filter parameter changed
  useEffect(() => {
    setKeepNonExistentRowsSelected(false);
  }, [currentTerminalValue, filterParams]);

  return (
    <div>
      <Box className="grid-padding">
        <div style={{ height: "100%", width: "100%" }}>
          <StripedDataGrid
            rowHeight={props.rowHeight}
            rows={props.rows!}
            getRowId={(row: any) => row[props.id!]}
            columns={columns || props.columns!}
            disableColumnFilter
            checkboxSelection={props.checkboxSelection}
            hideFooter={props.hideFooter}
            disableColumnMenu={props.disableColumnMenu}
            onSortModelChange={(model: any) => onColumnSort(model)}
            loading={props.loading}
            rowCount={props.rowCount}
            keepNonExistentRowsSelected={keepNonExistentRowsSelected}
            getRowClassName={(params: any) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            paginationModel={pageObject}
            onRowSelectionModelChange={(ids: GridRowSelectionModel) =>
              onRowsSelectionHandler(ids)
            }
            rowSelectionModel={selectedOSDIds}
            onPaginationModelChange={(newPage: any) => onPageChange(newPage)}
            sortingMode={props.sortingMode}
            paginationMode={props.paginationMode}
            pageSizeOptions={rowsPerPageOptions}
            sortingOrder={props.sortingOrder}
            columnVisibilityModel={props?.columnVisibilityModel}
            // isRowSelectable={(params) =>
            //   !(rowSelectable?.key &&
            //   params?.row[rowSelectable.key]
            //     ?.toLowerCase()
            //     ?.includes(rowSelectable?.value))
            // }
            disableRowSelectionOnClick
            slots={{
              noRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  {props.noRecordsFound}
                </Stack>
              ),
            }}
          />
        </div>
      </Box>

      <Box className="button-set-pagination">
        <div className="table-export">
          {[1].includes(tabPosition) ? (
            <Button
              onClick={navigateToOverageEntry}
              variant="contained"
              className="entry"
            >
              Overage Entry
            </Button>
          ) : null}

          {[3].includes(tabPosition) ? (
            <Button
              onClick={navigateToOverageEntry}
              variant="contained"
              className="entry"
            >
              All Short Entry
            </Button>
          ) : null}

          {[1, 2, 3].includes(tabPosition) ? (
            <Button
              variant="contained"
              className={
                selectedOSDIds && selectedOSDIds.length > 0
                  ? "assign"
                  : "disable assign"
              }
              onClick={
                selectedOSDIds && selectedOSDIds.length > 0
                  ? openAssignTo
                  : void 0
              }
            >
              Assign
            </Button>
          ) : null}
        </div>
      </Box>
      <div>
        {isOpenAssignPop ? (
          <AssignTo
            selectedOSDIds={selectedOSDIds}
            onClose={onClosePop}
            isOpen={isOpenAssignPop}
            onSuccessFullyAssigned={onSuccessFullyAssigned}
          />
        ) : null}
        {isOpen ? (
          <DialogImage
            key={isOpen.toString()}
            isDialogOpened={isOpen}
            handleCloseDialog={() => setIsOpen(false)}
            imageData={proImages}
            excNumber={exceptionNumber}
          />
        ) : null}
        {props?.loading || pageLoading ? (
          <Loader
            key={props?.loading?.toString()}
            showProgress={pageLoading}
            pageLoader={props?.loading || pageLoading}
          />
        ) : null}
      </div>
    </div>
  );
};

Grid.defaultProps = {
  checkboxSelection: true,
  hideFooter: false,
  disableColumnMenu: false,
  sortingOrder: ["asc", "desc", null],
  sortingMode: "server",
  paginationMode: "server",
  rowHeight: 35,
  loading: false,
  noRecordsFound: "No Records found",
};

export default Grid;
