import React from 'react';

const Loading: React.FC = () => {
  return (
    <div id="loading-icon">
      <div className="barcode-img"></div>
    </div>
  );
};

export default Loading;