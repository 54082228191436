import React from "react";
import "./KRACard.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FooterTabPosition } from "../../AppState/footerTabPosition";

type Props = {
  data?: {total?: number, open?: number};
  heading?: string,
  navigateTo?: string,
};

const KRACard: React.FC<Props> = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onCardClick = () => {
    navigate(props?.navigateTo!);
    dispatch(FooterTabPosition(1));
  };

  return (

    <div className="padding" onClick={() => onCardClick()}>
      <h4 className="card-header">{props.heading}</h4>

      <div className="content-card">

        <h2 className="count-2">
          <p>Total</p>
          {props?.data?.total ? props?.data?.total : "00"}
        </h2>

        <h2 className="count">
          <p>Open Investigation</p>
          {props?.data?.open ? props?.data?.open : "00"}
        </h2>

      </div>
    </div>

  );
};

export default KRACard;
