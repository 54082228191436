import axios from "axios";
import * as _ from "lodash";

import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";
import { IUploadFile } from "../models/master";


/**
 * Upload Image API call using Axios
 * @returns - Upload Investigation Image api and return same success message
 */

export const FileUploadAPI = async (files: File[] , params: IUploadFile) => {
  try {
    const formData = new FormData();
    // Append the file to the FormData instance
    files.forEach(file => {
      formData.append('files', file);
    });
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    const response = await axios.post(`${getUrl(ApiType.UPLOAD_FILE)}/${params.osdNumber}/${params.docType}/${params.uploadKey}`, formData, config);
    return response.data;
  } catch (error: any) {
    let message = ConstantMessage.InvestigationImageUploadAPIError;
    if (_.get(error, "response.status") === 500) {
      message = ConstantMessage.serverError;
    }else if (_.get(error, "response.status") === 400) {
      message =  _.get(error, "response.data.errors[0].message")
    }
    throw message;
  }
};
