import { ApiType } from "./Types";
import { env } from "../constants/env";

/**
 * A function to use the correct URL for API calls
 * @param apiType - The type of API URL needed
 * @returns - A URL to make an API call, it factors in current ENV
 */
export const getUrl = (apiType: ApiType): string => {
  const defaultUrl = "http://localhost:1337";

  switch (apiType) {
    case ApiType.TERMINAL:
      return `${env.wnrApi.url}${env.wnrApi.path}/master/terminals`;
    case ApiType.VALIDATE_OKTA_TOKEN:
      return `${env.wnrApi.url}${env.wnrApi.path}/auth/tokens/verification`;
    case ApiType.OVERAGE_LIST:
      return `${env.wnrApi.url}${env.wnrApi.path}/overages/list`;
    case ApiType.OVERAGE_CREATE:
      return `${env.wnrApi.url}${env.wnrApi.path}/overages`;
    case ApiType.MASTER_RECORDS:
      return `${env.wnrApi.url}${env.wnrApi.path}/master/records`;
    case ApiType.OVERAGE_FILTER_MASTER_USER:
      return `${env.wnrApi.url}${env.wnrApi.path}/master/users`;
    case ApiType.EXCEPTION_ASSIGN:
      return `${env.wnrApi.url}${env.wnrApi.path}/exception/assignments`;
    case ApiType.DASHBOARD_KRA:
      return `${env.wnrApi.url}${env.wnrApi.path}/dashboard/summaries`;
    case ApiType.EXCEPTION_DETAILS:
      return `${env.wnrApi.url}${env.wnrApi.path}/exception`;
    case ApiType.OVERAGE_ENTRY_DETAILS:
      return `${env.wnrApi.url}${env.wnrApi.path}/overages/edit`;
    case ApiType.PRO_IMAGES:
      return `${env.wnrApi.url}${env.wnrApi.path}/files/get`;
    case ApiType.MANIFEST_DETAILS:
      return `${env.wnrApi.url}${env.wnrApi.path}/manifests`;
    case ApiType.MANIFEST_PRO_DETAILS:
      return `${env.wnrApi.url}${env.wnrApi.path}/manifests-pro`;
    case ApiType.SEND_APB_MAIL:
      return `${env.wnrApi.url}${env.wnrApi.path}/exception/:osdId/apb/send`;
    case ApiType.MASTER_PRO_NUMBER:
      return `${env.wnrApi.url}${env.wnrApi.path}/master/pro-numbers`;
    case ApiType.MASTER_OSD_NUMBER:
      return `${env.wnrApi.url}${env.wnrApi.path}/master/osd-numbers`;
    case ApiType.COMMODITY_TYPE_MASTER:
      return `${env.wnrApi.url}${env.wnrApi.path}/master/commodity-types`;
    case ApiType.EXCEPTION_CODE_MASTER:
      return `${env.wnrApi.url}${env.wnrApi.path}/master/exception-codes`;
    case ApiType.CHECK_PRO:
      return `${env.wnrApi.url}${env.wnrApi.path}/pro`;
    case ApiType.OVERAGE_UPDATE:
      return `${env.wnrApi.url}${env.wnrApi.path}/overages`;
    case ApiType.SAVE_OR_SUBMIT_INVESTIGATION:
      return `${env.wnrApi.url}${env.wnrApi.path}/overages`;
    case ApiType.LOCATION_MASTER:
      return `${env.wnrApi.url}${env.wnrApi.path}/master/locations`;
    case ApiType.UPLOAD_FILE:
      return `${env.wnrApi.url}${env.wnrApi.path}/files/upload`;
    case ApiType.DOC_TYPE:
      return `${env.wnrApi.url}${env.wnrApi.path}/files/docTypes`;
    case ApiType.GET_SHORTAGES:
        return `${env.wnrApi.url}${env.wnrApi.path}/shortages/list`;
    case ApiType.PRINT:
      return `${env.wnrApi.url}${env.wnrApi.path}/overages/:osdNumber/print`;
    case ApiType.SHORTAGE_ENTRY_CREATE:
      return `${env.wnrApi.url}${env.wnrApi.path}/shortages`;
    case ApiType.AS_ENTRY_DETAILS:
      return `${env.wnrApi.url}${env.wnrApi.path}/shortages/edit`;
    case ApiType.SHORTAGE_INVESTIGATION : 
      return `${env.wnrApi.url}${env.wnrApi.path}/shortages/:osdId/investigation`;
    case ApiType.AS_ENTRY_UPDATE : 
      return `${env.wnrApi.url}${env.wnrApi.path}/shortages`;
    default:
      return defaultUrl;
  }
};
