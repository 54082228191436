import * as R from "ramda";
import { Action } from "redux";
import { AppState } from "./appState";
import ACTION_TYPE from "./ActionTypes";
import { IFilterForm } from "./overagesFilterFormActions";


export type typeShortagesFilterForm = {
  type: ACTION_TYPE.SHORTAGES_LIST_FILTER_FORM_STATE;
  shortagesFilterForm: IFilterForm;
};

/**
 * Redux action to update DispatchForm in Redux State
 * @param shortagesForm - the params we use for the dispatch api put request Obtained from DispatchForm component.
 */

export const saveShortagesFilterForm = (
    shortagesFilterForm: IFilterForm
): typeShortagesFilterForm => ({
  type: ACTION_TYPE.SHORTAGES_LIST_FILTER_FORM_STATE,
  shortagesFilterForm: shortagesFilterForm,
});

export const saveShortagesFilterFormReducer = (
  state: AppState,
  action: Action
): AppState => {
  if (action.type !== ACTION_TYPE.SHORTAGES_LIST_FILTER_FORM_STATE) {
    return state;
  }
  return R.mergeRight(state, {
    shortagesFilterForm: (action as typeShortagesFilterForm)
      .shortagesFilterForm,
  });
};
