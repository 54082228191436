import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet } from "react-router-dom";
import Loading from "./Loading";
import { useSelector } from "react-redux";
import { AppState } from "../../AppState/appState";
import _ from "lodash";

export const RequiredAuth: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const isLoggedIn: any = useSelector((state: AppState) => state.isLoggedIn);

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!_.get(authState, "isAuthenticated")) {
      localStorage.clear();
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth, !!authState, authState?.isAuthenticated, isLoggedIn]);

  if (!_.get(authState, "isAuthenticated") || !isLoggedIn) {
    return <Loading />;
  }

  return <Outlet />;
};
