import { Action, Reducer } from "redux";
import { AppState } from "./appState";
import { resetStateReducer } from "./resetStateActions";
import { FooterTabPositionReducer } from "./footerTabPosition";
import { TerminalSelectionReducer } from "./terminalSelection";
import { logUserInReducer, isPrivilegedUserReducer } from "./logUserIn";
import { saveTerminalMasterStateReducer } from "./terminalMaster";
import {
  saveOveragesFilterFormReducer,
  saveFilterValueReducer,
  saveFilterStatusReducer,
  saveFilterDateDataReducer,
  saveFilterCommodityTypeReducer,
  saveFilterExceptionCodeReducer,
  savePackageOptionMasterReducer,
  saveAllExceptionCodeReducer,
  saveShortageExceptionCodeReducer,
  saveShortageStatusReducer,
} from "./overagesFilterFormActions";
import { PopoutNotificationReducer } from "./popoutNotification";
import { saveMyAssignmentPageObjectReducer, saveMyAssignmentSortObjectReducer, saveOverageListPageObjectReducer, saveOverageListSortObjectReducer, saveShortagesPageObjectReducer, saveShortagesSortObjectReducer } from "./DataTableAction";
import { isFormValueChangedReducer } from "./isFormValueChanged";
import { saveMyAssignmentsFilterFormReducer } from "./myAssignmentsFilterFormActions";
import { saveDocTypesStateReducer } from "./docTypes";
import { saveShortagesFilterFormReducer } from "./shortagesFilterFormAction";
/**
 * A list of all app state reducers. These are functions that take actions and your previous app state, and calculate out
 * the next app state. Reducers have to be pure functions. Reducers run in their order in this array (This won't matter all that much).
 */
const reducers: Array<(state: AppState, action: Action) => AppState> = [
  // The reducers below are responsible for state toggles and user location within the app
  FooterTabPositionReducer,
  TerminalSelectionReducer,
  logUserInReducer,
  isPrivilegedUserReducer,
  saveTerminalMasterStateReducer,
  saveOveragesFilterFormReducer,
  saveFilterValueReducer,
  saveFilterStatusReducer,
  saveFilterDateDataReducer,
  saveFilterCommodityTypeReducer,
  saveAllExceptionCodeReducer,
  saveShortageExceptionCodeReducer,
  saveFilterExceptionCodeReducer,
  PopoutNotificationReducer,
  saveOverageListPageObjectReducer,
  saveOverageListSortObjectReducer,
  savePackageOptionMasterReducer,
  isFormValueChangedReducer,
  saveMyAssignmentsFilterFormReducer,
  saveMyAssignmentPageObjectReducer,
  saveMyAssignmentSortObjectReducer,
  saveDocTypesStateReducer,
  saveShortagesSortObjectReducer,
  saveShortagesPageObjectReducer,
  saveShortagesFilterFormReducer,
  saveShortageStatusReducer,
  resetStateReducer,
];

const rootReducer: Reducer<AppState> = (state, action) =>
  reducers.reduce((running, next) => next(running, action), state!);

export default rootReducer;
