import axios from "axios";
import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";
import _ from "lodash";

/**
 * GET API call using Axios
 * @param cancelToken - Token to cancel multiple requests
 * @returns - Array of zip code List of type number[]
 */

export const locationMasterApi = async (params: {search: string | number, page: number, limit: number}) => {
  try {
    const response = await axios.get(`${getUrl(ApiType.LOCATION_MASTER)}?search=${params.search}&page=${params.page}&limit=${params.limit}`, {
      headers: {
        "Content-Type": "application/json",
      }
    });
    return response.data;
  } catch (error: any) {
    let message = ConstantMessage.locationMaster;
    if (_.get(error, "response.status") === 500) {
      message = ConstantMessage.serverError;
    } else if (_.get(error, "response.status") === 400) {
        message = _.get(error, "response.data.errors[0].message");
    }
    throw message;
  }
};
