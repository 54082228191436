import { useNavigate } from "react-router-dom";
import AppRoutes from "./components/shared/AppRoutes";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import * as _ from "lodash";
import axios from "axios";
import { useEffect } from "react";
import { resetState } from "./AppState/resetStateActions";
import { useDispatch } from "react-redux";
import Notification from "./components/shared/Notification";
import { logUserIn } from "./AppState/logUserIn";

const RootApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  axios.interceptors.request.use(
    (request) => {
      const authUser = JSON.parse(localStorage.getItem("authUser")!);
      if (_.get(authUser, "jwtToken", "")) {
        _.set(
          request,
          "headers.jwt_token",
          `${_.get(authUser, "jwtToken", "")}`
        );
      }
      return request;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (_.get(error, "response.status") === 401) {
        dispatch(logUserIn(true));
        navigate(`/401`);
      }
      return Promise.reject(error);
    }
  );

  const resetProperties = () => {
    dispatch(resetState("SOFT_RESET"));
  };

  useEffect(() => {
    resetProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <Header />
      <AppRoutes></AppRoutes>
      <Footer></Footer>
      <Notification></Notification>
    </div>
  );
};
export default RootApp;
