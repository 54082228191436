import axios from "axios";
import * as _ from "lodash";
import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";
import { ICheckDigitPro } from "../models/osd";

/**
 * GET Pro check digit detail API call using Axios
 */

export const checkPro = async (proNumber: string | number): Promise<ICheckDigitPro> => {
  try {
    const response = await axios.get(`${getUrl(ApiType.CHECK_PRO)}/${proNumber}`, {
      headers: {
        "Content-Type": "application/json",
      }
    });
    return response.data;
  } catch (error) {
    let message = ConstantMessage.proImage;
    if (_.get(error, "response.status") === 500) {
      message = ConstantMessage.serverError;
    }
    throw message;
  }
};
