import axios from "axios";
import * as _ from "lodash";

import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";

/**
 * GET API call using Axios
 * @param cancelToken - Token to cancel multiple requests
 * @returns - Array of Terminal List of type string[]
 */

export const exceptionAssign = async (params: any) => {
  try {
    const response = await axios.post(getUrl(ApiType.EXCEPTION_ASSIGN), params, {
      headers: {
        "Content-Type": "application/json",
      }
    });
    return response.data;
  } catch (error: any) {
    let message = ConstantMessage.exceptionAssign;
    if (_.get(error, "response.status") === 500) {
      message = ConstantMessage.serverError;
    }else if (_.get(error, "response.status") === 400) {
      message = _.get(error, "errors[0].message") || _.get(error, "response.data.errors[0].message")
    }
    throw message;
  }
};
