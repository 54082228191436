import axios from "axios";
import * as _ from "lodash";

import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";

/**
 * Upload Image API call using Axios
 * @returns - Get Doc type and return the array of doc type
 */

export const GetDocTypeAPI = async () => {
  try {
    const response = await axios.get(`${getUrl(ApiType.DOC_TYPE)}`,
    {
        headers: {
          "Content-Type": "application/json",
        }
      });
    return response.data;
  } catch (error: any) {
    let message = ConstantMessage.DocTypeAPIError;
    if (_.get(error, "response.status") === 500) {
      message = ConstantMessage.serverError;
    }else if (_.get(error, "response.status") === 400) {
      message =  _.get(error, "response.data.errors[0].message")
    }
    throw message;
  }
};
