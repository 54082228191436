import * as R from "ramda";
import { Action } from "redux";
import { AppState } from "./appState";
import ACTION_TYPE from "./ActionTypes";
import { TGridSortModel } from "../constants/app";
import { GridPaginationModel } from "@mui/x-data-grid";

/** Start Overages data table States */

export type TOverageListPageObject = {
    type: ACTION_TYPE.OVERAGE_DATA_TABLE_PAGE_STATE;
    pageObject: GridPaginationModel;
  };
  
  export const saveOverageListPageObject = (pageObject: GridPaginationModel): TOverageListPageObject => ({
    type: ACTION_TYPE.OVERAGE_DATA_TABLE_PAGE_STATE,
    pageObject: pageObject,
  });
  
  export const saveOverageListPageObjectReducer = (
    state: AppState,
    action: Action,
  ): AppState => {
    if (action.type !== ACTION_TYPE.OVERAGE_DATA_TABLE_PAGE_STATE) {
      return state;
    }
    return R.mergeRight(state, {
      overageListPageObject: (action as TOverageListPageObject).pageObject,
    });
  };
  
  export type actionOverageListSortObject = {
    type: ACTION_TYPE.OVERAGE_DATA_TABLE_SORT_STATE;
    overageSortObject: TGridSortModel;
  };
  
  export const saveOverageListSortObject = (overageSortObject: TGridSortModel): actionOverageListSortObject => ({
    type: ACTION_TYPE.OVERAGE_DATA_TABLE_SORT_STATE,
    overageSortObject: overageSortObject,
  });
  
  export const saveOverageListSortObjectReducer = (
    state: AppState,
    action: Action,
  ): AppState => {
    if (action.type !== ACTION_TYPE.OVERAGE_DATA_TABLE_SORT_STATE) {
      return state;
    }
    return R.mergeRight(state, {
      overageSortObjectData: (action as actionOverageListSortObject).overageSortObject,
    });
  };

/** END Overages data table States */

/** Start My Assignment data table States */

export type TMyAssignmentPageObject = {
  type: ACTION_TYPE.MY_ASSIGNMENT_DATA_TABLE_PAGE_STATE;
  pageObject: GridPaginationModel;
};

export const saveMyAssignmentPageObject = (pageObject: GridPaginationModel): TMyAssignmentPageObject => ({
  type: ACTION_TYPE.MY_ASSIGNMENT_DATA_TABLE_PAGE_STATE,
  pageObject: pageObject,
});

export const saveMyAssignmentPageObjectReducer = (
  state: AppState,
  action: Action,
): AppState => {
  if (action.type !== ACTION_TYPE.MY_ASSIGNMENT_DATA_TABLE_PAGE_STATE) {
    return state;
  }
  return R.mergeRight(state, {
    myAssignmentPageObject: (action as TMyAssignmentPageObject).pageObject,
  });
};

export type actionMyAssignmentSortObject = {
  type: ACTION_TYPE.MY_ASSIGNMENT_DATA_TABLE_SORT_STATE;
  myAssignmentSortObject: TGridSortModel;
};

export const saveMyAssignmentSortObject = (myAssignmentSortObject: TGridSortModel): actionMyAssignmentSortObject => ({
  type: ACTION_TYPE.MY_ASSIGNMENT_DATA_TABLE_SORT_STATE,
  myAssignmentSortObject: myAssignmentSortObject,
});

export const saveMyAssignmentSortObjectReducer = (
  state: AppState,
  action: Action,
): AppState => {
  if (action.type !== ACTION_TYPE.MY_ASSIGNMENT_DATA_TABLE_SORT_STATE) {
    return state;
  }
  return R.mergeRight(state, {
    myAssignmentSortObjectData: (action as actionMyAssignmentSortObject).myAssignmentSortObject,
  });
};

/** END My Assignment data table States */



/** Start Shortages data table States */


export type TShortagesPageObject = {
  type: ACTION_TYPE.SHORTAGES_PAGE_STATE;
  pageObject: GridPaginationModel;
};

export const saveShortagesPageObject = (pageObject: GridPaginationModel): TShortagesPageObject => ({
  type: ACTION_TYPE.SHORTAGES_PAGE_STATE,
  pageObject: pageObject,
});

export const saveShortagesPageObjectReducer = (
  state: AppState,
  action: Action,
): AppState => {
  if (action.type !== ACTION_TYPE.SHORTAGES_PAGE_STATE) {
    return state;
  }
  return R.mergeRight(state, {
    shortagesPageObject: (action as TShortagesPageObject).pageObject,
  });
};

export type actionShortagesSortObject = {
  type: ACTION_TYPE.SHORTAGES_SORT_STATE;
  shortagesSortObject: TGridSortModel;
};

export const saveShortagesSortObject = (shortagesSortObject: TGridSortModel): actionShortagesSortObject => ({
  type: ACTION_TYPE.SHORTAGES_SORT_STATE,
  shortagesSortObject: shortagesSortObject,
});

export const saveShortagesSortObjectReducer = (
  state: AppState,
  action: Action,
): AppState => {
  if (action.type !== ACTION_TYPE.SHORTAGES_SORT_STATE) {
    return state;
  }
  return R.mergeRight(state, {
    shortagesSortObject: (action as actionShortagesSortObject).shortagesSortObject,
  });
};

/** END Shortages data table States */
