import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
type Props = {
    pageLoader?: boolean;
    showProgress?: boolean;
}
const Loader: React.FC<Props> = (props: Props): JSX.Element => {
    return (
    <div>
      <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={props.pageLoader!}
        >
         {props?.showProgress && <CircularProgress color="inherit" /> }
        </Backdrop>
    </div>
  );
};
Loader.defaultProps = {
    pageLoader: false,
    showProgress: true 
}
export default Loader
