import { toArray, toBool } from "../helpers/utils.helper";

export const env: any = {
	env: process.env,
	wnrApi: {
		url: process.env.REACT_APP_OSD_SERVICE_API,
		path: process.env.REACT_APP_OSD_SERVICE_API_PATH,
  	},
	okta: {
		clientId: process.env.REACT_APP_OKTA_CLIENTID,
		issuer: process.env.REACT_APP_OKTA_ISSUER,
		redirectURI: process.env.REACT_APP_OKTA_REDIRECTURI,
		scopes: toArray(process.env.REACT_APP_OKTA_SCOPES!),
		pkcs: toBool(process.env.REACT_APP_OKTA_PKCE!),
		disableHttps: toBool(process.env.REACT_APP_OKTA_DISABLE_HTTPS!),
	},
	xdoc: {
		url: process.env.REACT_APP_XDOC_URL
	},
	wnrImangeManager: {
		url: process.env.REACT_APP_IMAGE_MANGER_URL
	},
	imagesService: {
		getProDocumentUrls: process.env.REACT_APP_VIEW_PRO_IMAGES_API_URL
	}


};
