import _ from "lodash";
import { TTerminal } from "../AppState/overagesFilterFormActions";
import { EAutoSearchFieldName, ETab } from "../constants/app";
import imageCompression from 'browser-image-compression';
import html2canvas from "html2canvas";
import { resolve } from "path";

export const toArray = (str: string, el: string = ",") => {
  if (!str) return str;
  return str.split(el);
};

export const toBool = (str: string) => {
  if (!str) return str;
  return str.toLowerCase().trim() === "true";
};

export const checkNumber = (value: string) => {
  /// number and deciamal check
  let formatString = /^[-+]?\d+\.\d+$/;
  if (isNaN(Number(value)) === false || value.match(formatString)) {
    return true;
  } else {
    return false;
  }
};

export const abbreviateNumber = (value: any) => {
  if (!value) return value;
  if (value < 1000) return value;
  value = Math.floor(value);
  let suffixes = ["", "k", "m", "b", "t"];
  let suffixNum = Math.floor(("" + value).length / 3);
  let shortValue: any = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(2));
  if (shortValue % 1 !== 0) {
    shortValue = shortValue.toFixed(1);
  }
  return shortValue + suffixes[suffixNum];
}


///// Filter Form Validation : compair Min Value with Max Value
export const minMaxValidation = (
  minValue: string | null,
  maxValue: string | null
) => {
  if (
    maxValue !== "" &&
    minValue !== "" &&
    minValue !== null &&
    Number(maxValue) < Number(minValue)
  ) {
    return true;
  } else {
    return false;
  }
};

///// Filter Form Validation : compair Max Value with Min Value
export const maxMinValidation = (
  minValue: string | null,
  maxValue: string | null
) => {
  if (
    minValue !== "" &&
    maxValue !== "" &&
    maxValue !== null &&
    Number(minValue) > Number(maxValue)
  ) {
    return true;
  } else {
    return false;
  }
};

// take string and regex to return part of string using regex method
export const extractSubstringWithRegexDelimiter = (str: string, delimiter: string) => {
  const regex = new RegExp(delimiter + '(.*?)' + delimiter);
  const value = str.match(regex);
  if (value !== null) {
    return value[1].trim()
  } else {
    return "";
  }
}


export const Username = () => {
  const authUser = localStorage.getItem("authUser");
  if (authUser) {
    return JSON.parse(authUser)["username"]!;
  } else {
    return "";
  }
}

export const UserTerminal = () => {
  const authUser = localStorage.getItem("authUser");
  if (authUser) {
    return JSON.parse(authUser)["userTerminal"]!;
  } else {
    return "";
  }
}

// Implement logic for display filter Criteria on top up the grid
export const FilterCriteriaValue = (params: any, tab: number) => {
  const criteria: string[] = [];
  if (params?.entryDateFrom || params?.entryDateTo) {
    criteria.push(`Date`);
  }
  if (params?.origin && params?.origin.length > 0) {
    criteria.push(`Origin TID`);
  }
  if (params?.destination && params?.destination.length > 0) {
    criteria.push(`Destination TID`);
  }
  if (params?.osdType && params?.osdType.length > 0) {
    criteria.push(`Exception Code`);
  }
  if (params?.commodityType && params?.commodityType.length > 0) {
    criteria.push(`Commodity`);
  }
  if (params?.description) {
    criteria.push(`Description`);
  }
  if (params?.proNumber || params?.proNumber === 0) {
    criteria.push(`Pro #`);
  }
  if (params?.od400ProNumber) {
    criteria.push(`OD400 Pro #`);
  }
  if (params?.osdNumber) {
    criteria.push(`Exception #`);
  }
  if (params?.values && params?.values.length > 0) {
    criteria.push(`Value Range`);
  }
  if (params?.status && params?.status.length > 0) {
    criteria.push(`Status`);
  }
  if (params?.entryBy) {
    criteria.push(`Entry By`);
  }
  if ([ETab.overages, ETab.shortages].indexOf(tab) !== -1 && params?.assignedTo) {
    criteria.push(`Assigned To`);
  }
  if ([ETab.myAssignments, ETab.shortages].indexOf(tab) !== -1 && params?.assignedBy) {
    criteria.push(`Assigned By`);
  }
  const val = criteria.join(" / ");
  return val;
};

export const NoOptionsText = (values: string, loading: boolean, fieldName: EAutoSearchFieldName, minSize: number = 2) => {
  if (values.length < minSize) {
    return `Please Enter ${fieldName}`;
  } else if (values.length > 1 && !loading) {
    return "Records not found";
  } else if (loading) {
    return "Loading...";
  } else {
    return `No ${fieldName} found`;
  }
}

export const SortTerminals = (terminals: TTerminal[]) => {
  return terminals.sort((a, b) => {
    let regionA = a.region || a.value;
    let regionB = b.region || b.value;

    if (regionA === regionB) {
      return a.region === null ? -1 : 1;
    }
    if (regionA < regionB) {
      return -1;
    }
    if (regionA > regionB) {
      return 1;
    }
    return 0;
  });
};

export const ConvertBase64ToFile = (imageData: string, filename: string): File => {
  const arr = imageData.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}


export const HtmlToImage = async (elementId: string, name: string = "1.jpeg"): Promise<File> => {
  try {
    const element = document.getElementById(elementId);
    if (!element) {
      return new File([], name, { type: 'image/jpeg' });
    }

    // Capture the HTML content as a canvas
    const canvas = await html2canvas(element, {
      onclone: (newDom) => {
        const clonedElement = newDom.getElementById(element.id);
        if (clonedElement) {
          clonedElement.style.display = 'block';
        }
      },
    });

    canvas.style.width = '100%';

    // Convert the canvas to a Blob and return it as a promise
    return new Promise((resolve, reject) => {
      canvas.toBlob(async (blob) => {
        if (!blob) {
          reject(new Error('Blob is null'));
          return;
        }

        let image = new File([blob], name, { type: 'image/jpeg' });

        // Compress the image if its size exceeds 300KB
        if (image.size > 300000) {
          try {
            const compressedBlob = await CompressImage(image);
            if (compressedBlob) {
              image = new File([compressedBlob], name, { type: 'image/jpeg' });
            }
          } catch (error) {
            console.error('Error compressing image:', error);
          }
        }

        resolve(image);
      }, 'image/jpeg');
    });
  } catch (error) {
    console.error('Error capturing HTML as image:', error);
    throw new Error('Error capturing HTML as image');
  }
};
export async function CompressImage(file: File): Promise<File> {

  // Check if the file is an image
  if (!file.type.startsWith('image/')) {
    console.error('The file is not an image');
    return file;
  }

  // Check if the image is a supported format
  const supportedFormats = ['jpeg', 'png', 'gif', 'bmp', 'webp'];
  const format = file.type.split('/')[1];
  if (!supportedFormats.includes(format)) {
    console.error(`The image ${format} is not supported`);
    return file;
  }

  const options = {
    maxSizeMB: 0.3, // Approx. 300KB
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  try {
    const compressedFile = await imageCompression(file, options);
    return new File([compressedFile], file.name, {
      type: compressedFile.type,
      lastModified: new Date().getTime(),
    });
  } catch (error) {
    console.error('Error occurred while compressing the image', error);
    return file;
  }
}

// handle .,+,-, e, E for number type field
export const HandleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if ([".", "-", "+", "e", "E"].includes(event.key)) {
    event.preventDefault();
  }
};

export const HandleNumberMaxLength = (e: React.ChangeEvent<HTMLInputElement>, maxLength: number) => {
  if (!e.target.value) return
  e.target.value = Math.max(0, parseInt(e.target.value))
    .toString()
    .slice(0, maxLength);
}

export const CombineTowJsonObject = (objValue: any, srcValue: any) => {
  // Take the value from the left object if both values are present
  return _.isUndefined(srcValue) ? objValue : srcValue;
};
