import { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AppState } from "../../AppState/appState";
import { useDispatch, useSelector } from "react-redux";
import { getTerminals } from "../../API/getTerminals";
import { saveTerminalMasterState } from "../../AppState/terminalMaster";
import * as _ from "lodash";
import { TerminalSelection } from "../../AppState/terminalSelection";
import { resetState } from "../../AppState/resetStateActions";
import { TTerminal } from "../../AppState/overagesFilterFormActions";
import { SortTerminals, UserTerminal } from "../../helpers/utils.helper";

type Props = {
  isDateSelectionAvailable?: boolean;
  isTerminalSelectionAvailable?: boolean;
};

const TerminalPicker: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: AppState) => state.isLoggedIn);
  const currentTerminalValue = useSelector(
    (state: AppState) => state.activeTerminal
  );
  const [terminals, setTerminals] = useState<any[]>([]);

  const terminalMasterData = useSelector(
    (state: AppState) => state.terminalMaster
  );

  // Terminal API call
  const getTerminalsAPI = () => {
    if (terminalMasterData.length > 0) {
      if (currentTerminalValue.value === "") {
        dispatch(TerminalSelection(terminalMasterData.find((terminal) => terminal.value === UserTerminal() && terminal.region !== null) ?? { value: "", region: null }));
      }
      setTerminals([{ value: "All", region: "" }, ...terminalMasterData]);
    } else {
      getTerminals().then(async (results: string[]) => {
        const terminals: TTerminal[] = SortTerminals(_.get(results, "data", []));
        dispatch(TerminalSelection(terminals.find((terminal) => terminal.value === UserTerminal() && terminal.region !== null) ?? terminals[0]));
        setTerminals([{ value: "All", region: "" }, ...terminals]);
        dispatch(saveTerminalMasterState(terminals));
      }).catch((error) => {
        console.log(error);
      });
    }
  };

  const onTerminalChange = (event: any, selectedTerminal: any) => {
    dispatch(TerminalSelection(selectedTerminal));
    dispatch(resetState("TERMINAL_RESET"));
  };

  useEffect(() => {
    if (isLoggedIn) getTerminalsAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (currentTerminalValue.value === "" && terminals.length > 0) {
      dispatch(TerminalSelection(terminals.find((terminal) => terminal.value === UserTerminal() && terminal.region !== null) ?? { value: "", region: null }));
    }
    if (terminalMasterData.length < 1 && isLoggedIn && terminals.length > 0) {
      getTerminalsAPI()
    }
  });

  return (<>
    {currentTerminalValue.value && terminals && terminals.length > 0 &&
      (<FormGroup className="filter-grp">
        <FormControl className="filter-control custom-user">
          <Autocomplete
            className="new-user-control terminal"
            id="terminal"
            size="small"
            disableClearable={true}
            key={currentTerminalValue.value}
            options={terminals}
            value={currentTerminalValue}
            onChange={onTerminalChange}
            popupIcon={<KeyboardArrowDownIcon className="icon-style" />}
            getOptionLabel={(option) => `${option.value}${option.region === null ? " (Region)" : ""}`}
            renderOption={(props, option) => (
              <li {...props} key={option.value + "" + option.region} className={option.region !== null ? 'terminal' : 'region'}>
                {`${option.value}${option.region === null ? " (Region)" : ""}`}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Terminal"
                variant="outlined"
                className="dropdown-list"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: "0.9rem" },
                }}
              />
            )}
          />
        </FormControl>{" "}
      </FormGroup>)
    }
  </>
  );
};

export default TerminalPicker;
