import * as R from "ramda";
import { Action } from "redux";
import { AppState } from "./appState";
import ACTION_TYPE from "./ActionTypes";
import { IFilterForm } from "./overagesFilterFormActions";


export type typeMyAssignmentsFilterForm = {
  type: ACTION_TYPE.MY_ASSIGNMENT_LIST_FILTER_FORM_STATE;
  myAssignmentsFilterForm: IFilterForm;
};

/**
 * Redux action to update DispatchForm in Redux State
 * @param manifestReviewForm - the params we use for the dispatch api put request Obtained from DispatchForm component.
 */

export const saveMyAssignmentsFilterForm = (
    myAssignmentsFilterForm: IFilterForm
): typeMyAssignmentsFilterForm => ({
  type: ACTION_TYPE.MY_ASSIGNMENT_LIST_FILTER_FORM_STATE,
  myAssignmentsFilterForm: myAssignmentsFilterForm,
});

export const saveMyAssignmentsFilterFormReducer = (
  state: AppState,
  action: Action
): AppState => {
  if (action.type !== ACTION_TYPE.MY_ASSIGNMENT_LIST_FILTER_FORM_STATE) {
    return state;
  }
  return R.mergeRight(state, {
    myAssignmentsFilterForm: (action as typeMyAssignmentsFilterForm)
      .myAssignmentsFilterForm,
  });
};
