import * as R from "ramda";
import { Action } from "redux";
import { AppState } from "./appState";
import ACTION_TYPE from "../AppState/ActionTypes";
import NOTIFICATION_TYPE from "./NotificationType";

export type PopoutNotificationAction = {
    type: ACTION_TYPE.NOTIFICATION_STATE;
    notificationType: NOTIFICATION_TYPE;
    message: string;
    notificationTime: string;
};

export const PopoutNotification = (
    notificationType: NOTIFICATION_TYPE,
    message: string,
    notificationTime: string
): PopoutNotificationAction => ({
    type: ACTION_TYPE.NOTIFICATION_STATE,
    notificationType: notificationType,
    message: message,
    notificationTime: notificationTime
});

export const PopoutNotificationReducer = (
    state: AppState,
    action: Action
): AppState => {
    if (action.type !== ACTION_TYPE.NOTIFICATION_STATE) {
        return state;
    }
    return R.mergeDeepRight(state, {
        notificationType: (action as PopoutNotificationAction).notificationType,
        message: (action as PopoutNotificationAction).message,
        notificationTime: (action as PopoutNotificationAction).notificationTime
    });
};