import axios from "axios";
import * as _ from "lodash";

import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";
import { IOvInvestigation } from "../models/overage";

/**
 * CRETE API call using Axios
 * @returns - Save and submit Investigation api and return same object
 */

export const SaveOrSubmitInvestigationAPI = async (params: IOvInvestigation, osdNumber: number, action: string) => {
  try {
    const response = await axios.post(`${getUrl(ApiType.SAVE_OR_SUBMIT_INVESTIGATION)}/${osdNumber}/investigation/${action}`, params, {
      headers: {
        "Content-Type": "application/json",
      }
    });
    return response.data;
  } catch (error: any) {
    let message = ConstantMessage.saveOrSubmitAPIError;
    if (_.get(error, "response.status") === 500) {
      message = ConstantMessage.serverError;
    }else if (_.get(error, "response.status") === 400) {
      message = _.get(error, "errors[0].message") || _.get(error, "response.data.errors[0].message")
    }
    throw message;
  }
};
