import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../src/AppState/store";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import RootApp from "./RootApp";

function App() {
  const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER!,
    clientId: process.env.REACT_APP_OKTA_CLIENTID,
    redirectUri:
      window.location.origin + process.env.REACT_APP_OKTA_REDIRECTURI,
    scopes: ["openid", "profile", "email"],
    pkce: true,
  });

  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Fragment>
      {}
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <RootApp />
          </Security>
        </PersistGate>
      </Provider>
    </Fragment>
  );
}

export default App;
