import "./HeaderSearch.css";
import React, { useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import SearchFilterForm from "../filterForm/searchFilterForm";
import { useSelector } from "react-redux";
import AppState from "../../AppState";

type Props = {
  onFilterClick?: React.MouseEventHandler<HTMLButtonElement>;
  onSearchChange?: (value: string) => void;
  value?: string;
  maxlength?: number;
};
const HeaderSearch: React.FC<Props> = (props: Props) => {
  const [isFilterFormVisible, setFilterFormVisible] = useState(false);

  const handleFilterClick = () => {
    setFilterFormVisible(!isFilterFormVisible);
  };

  const tab = useSelector((state: AppState) => state.tabPosition);

  useEffect(() => {
    setFilterFormVisible(false);
  }, [tab]);

  return (
    <div className="filter-Header">
      <FormGroup className="filter-search" test-id="headerSearch">
        <FormControl className="filter-search-control">
          <TextField
            placeholder="Search"
            id="outlined-start-adornment"
            variant="outlined"
            size="small"
            fullWidth
            value={props.value === "" ? "" : props.value}
            onChange={(e) => props.onSearchChange && props.onSearchChange(e.target.value)}
            InputProps={{
              inputProps: {
                maxLength: props?.maxlength,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" size="small"></IconButton>
                  <IconButton
                    id="searchFilter"
                    edge="end"
                    onClick={(e) => {
                      props.onFilterClick?.(e);
                      handleFilterClick();
                    }}
                  >
                    <TuneIcon />
                  </IconButton>
                </InputAdornment>
              ),
              style: { fontSize: 14 },
            }}
          />
        </FormControl>
      </FormGroup>

      {isFilterFormVisible && (
        <SearchFilterForm onFilterSubmit={() => setFilterFormVisible(false)} />
      )}
    </div>
  );
};

HeaderSearch.defaultProps = {
  maxlength: 0,
};
export default HeaderSearch;
